'use strict';

define('vbsw/private/plugins/multiTenantCsrfTokenHandlerPlugin',['vbsw/api/fetchHandlerPlugin', 'vbsw/private/constants'],
  (FetchHandlerPlugin, Constants) => {
    /**
     * Name of the request/response header that contains the CSRF token value.
     * @type {string}
     */
    const CSRF_TOKEN_HEADER_NAME = 'X-CSRF-TOKEN';

    /**
     * Handler plugin for handling CSRF tokens in multi-tenant environment, e.g. Visual Studio.
     */
    class MultiTenantCsrfTokenHandlerPlugin extends FetchHandlerPlugin {
      constructor(fetchHandler, params = {}) {
        super(fetchHandler);

        const { orgId, vbServer, serverRoot = '' } = params;
        if (orgId && vbServer) {
          this.profileUrl = `${vbServer}/${serverRoot}${orgId}/api2/profile`;
          this.vbServerRegEx = new RegExp(vbServer);
        } else {
          // organization id is not available - CSRF is turned off
          this.profileUrl = null;
          this.vbServerRegEx = null;
        }

        // if set to null, it means CSRF token is not available
        this.vbCsrfToken = undefined;
      }

      // used by unit tests
      static get csrfTokenHeader() { return CSRF_TOKEN_HEADER_NAME; }

      /**
       * Get the CSRF token.
       *
       * @returns {Promise}
       */
      getCsrfToken() {
        return Promise.resolve().then(() => {
          // only refersh the token if vbCsrfToken is undefined
          if (this.vbCsrfToken === undefined) {
            return this.refreshCsrfToken().then(() => this.vbCsrfToken);
          }

          return this.vbCsrfToken;
        });
      }

      /**
       * Refresh the CSRF token.
       *
       * @returns {Promise}
       */
      refreshCsrfToken() {
        return Promise.resolve().then(() => {
          if (this.profileUrl) {
            const options = {
              method: 'GET',
              credentials: 'same-origin',
              cache: 'no-cache',
              // add oracle cloud auth type header in case mobile plugin needs to handle this in the future
              headers: {
                [Constants.AUTHENTICATION_TYPE_HEADER]: Constants.AuthenticationType.ORACLE_CLOUD,
              },
            };
            const request = new Request(this.profileUrl, options);

            return this.fetchHandler.handleRequest(request).then((response) => {
              this.vbCsrfToken = response.headers.get(CSRF_TOKEN_HEADER_NAME);

              return this.vbCsrfToken;
            });
          }

          // CSRF token is not available
          return null;
        });
      }

      /**
       * Append the CSRF token to the header.
       *
       * @param request the request to which to append the CSRF token
       * @returns {Promise}
       */
      handleRequestHook(request) {
        return Promise.resolve().then(() => {
          // only append the CSRF token if the request url matches the scope
          if (request.method.toUpperCase() !== 'GET'
            && this.vbServerRegEx && request.url.match(this.vbServerRegEx)) {
            return this.getCsrfToken().then((token) => {
              if (token) {
                request.headers.set(CSRF_TOKEN_HEADER_NAME, token);
              }
            });
          }

          return undefined;
        });
      }

      /**
       * Check the response if we need to refresh the CSRF token and retry the request if we have an invalid token.
       *
       * @param response the response to check for invalid CSRF token and get new token
       * @param request
       * @returns {Promise}
       */
      handleResponseHook(response, request) {
        return Promise.resolve().then(() => {
          // only check for 403 if the original request url matches the vb server url
          if (response.status === 403 && request.method.toUpperCase() !== 'GET'
            && this.vbServerRegEx && request.url.match(this.vbServerRegEx)) {
            // refresh the CSRF token and retry
            return this.refreshCsrfToken().then(() => true);
          }

          return false;
        });
      }
    }

    return MultiTenantCsrfTokenHandlerPlugin;
  });

