/* eslint-disable class-methods-use-this */
/* eslint max-classes-per-file: ["error", 2] */

'use strict';

define('vb/private/types/metadataDescriptor',[
  'vb/private/log',
  'vb/private/constants',
  'vb/private/utils',
  'vb/binding/expression',
  'vb/helpers/mixin',
  'vb/private/types/builtinExtendedTypeMixin',
], (Log, Constants, Utils, Expression, Mixin, BuiltinExtendedTypeMixin) => {

  /**
   * custom variable type for metadata providers
   *
   * note: using "class {}" instead of Object, babel issue: https://github.com/babel/babel/issues/4480
   *
   */
  class MetadataDescriptor extends Mixin().with(BuiltinExtendedTypeMixin) {
    constructor() {
      super();
      this.log = Log.getLogger('/vb/types/metadataDescriptor');
    }

    /**
     * Initializer.
     * @param id
     * @param variableDef the declarative definition of this variable
     * @param value the defaultValue is provided here
     * @param container app, flow, or page. needed to pass context to the provider
     * @return {Promise}
     * @constructor
     */
    init(id, variableDef, value, container) {
      return Promise.resolve()
        .then(() => {
          super.init(id, variableDef, value, container);
          this.container = container;
        });
    }

    /**
     *
     * @returns {*}
     */
    getTypeDefinition() {
      return {
        type: 'object',
      };
    }

    /**
     * Called when the variable is disposed
     * override this method
     */
    // eslint-disable-next-line class-methods-use-this
    dispose() {
      // no-op
    }
  }

  return MetadataDescriptor;
});

