/* eslint no-underscore-dangle: 0 */

'use strict';

define('vb/private/mobile/db',['vb/private/mobile/fileIo'], (FileIo) => {
  const PREFRENCES_KEY = 'vbcs-preferences-1.0.0';

  class Db {
    /**
     * Stores an app preference.
     */
    static setAppPreference(key, value) {
      return Db._readJson(PREFRENCES_KEY).then((preferences) => {
        const appPreferences = preferences;
        appPreferences[key] = value;
        Db._writeJson(PREFRENCES_KEY, appPreferences);
      });
    }

    /**
     * Gets an app preference. If the preference is not found, this will still resolve, but with
     * an undefined value.
     */
    static getAppPreference(key) {
      return Db._readJson(PREFRENCES_KEY).then(preferences => preferences[key]);
    }

    /**
     * Removes an app preference.
     */
    static removeAppPreference(key) {
      return Db._readJson(PREFRENCES_KEY).then((preferences) => {
        const appPreferences = preferences;
        if (appPreferences[key]) {
          delete (appPreferences[key]);
          return Db._writeJson(PREFRENCES_KEY, appPreferences);
        }

        return Promise.resolve();
      });
    }

    static _readJson(key) {
      return FileIo.readFileFromLib('metadata', `${key}.db`)
        .then(results => (results ? JSON.parse(results) : results))
        .catch(() => ({}));
    }

    static _writeJson(key, json) {
      const jsonString = JSON.stringify(json);
      return FileIo.saveFileToLib('metadata', `${key}.db`, jsonString).catch((e) => {
        console.error(e);
      });
    }
  }

  return Db;
});

