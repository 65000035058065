'use strict';

define('vb/extensions/dynamic/private/helpers/dataDescriptionMetadataProviderHelper',[
  'vb/private/constants',
  'vb/private/utils',
  'vb/private/log',
  'vb/private/services/servicesManager',
  'vb/private/services/readers/openApiObjectFactory',
  'vb/private/stateManagement/application',
  'vb/private/stateManagement/router',
  'vb/private/model/modelUtils',
  'vb/private/ui/responsiveUtils',
  'vb/private/configLoader',
  'vb/private/stateManagement/layout',
  'vb/private/stateManagement/context/layoutContext',
  'vb/private/stateManagement/context/layoutBaseContext',
  'vb/extensions/dynamic/private/helpers/configurableMetadataProviderHelper',
],
(Constants, Utils, Log, ServicesManager, OpenApiObjectFactory, Application, Router, ModelUtils,
  ResponsiveUtils, ConfigLoader, Layout, LayoutContext, LayoutBaseContext, ConfigurableMetadataProviderHelper) => {
  //
  const LAYOUT_EXT = '.json';

  const FILE_PREFIX = 'layout';

  // layout.json, layout-x.json
  const DATA_NAME = `${FILE_PREFIX}${LAYOUT_EXT}`;
  const DATA_EXT_NAME = `${FILE_PREFIX}-x${LAYOUT_EXT}`;

  // layout.html, layout-x.html
  const TEMPLATE_NAME = `${FILE_PREFIX}.html`;
  const TEMPLATE_EXT_NAME = `${FILE_PREFIX}-x.html`;

  // layout.js, layout-x.js
  const SOURCE_NAME = `${FILE_PREFIX}.js`;
  const SOURCE_EXT_NAME = `${FILE_PREFIX}-x.js`;

  const METADATA_NAME_ROOT = 'data-description';
  const METADATA_NAME = `${METADATA_NAME_ROOT}.json`;

  // data-description-x.json extends data-description.json
  const METADATA_OVERLAY_EXT_NAME = `${METADATA_NAME_ROOT}-x.json`;

  const BASE_MODEL = 'vb/extensions/dynamic/private/models/dataDescriptionModel';
  const EXTENSION_MODEL = 'vb/extensions/dynamic/private/models/dataDescriptionExtensionModel';

  // a convenience descriptor for the files that may be defined for a given layout
  const RESOURCE_DESCRIPTORS = [{
    property: 'data',
    baseFile: DATA_NAME,
    extensionFile: DATA_EXT_NAME,
    prefix: 'text!',
    modelClass: 'vb/private/stateManagement/layout',
    // Specify the default resource that should be returned if layout.json doesn't exist. This
    // is used to support the use case where an extension needs to extend a layout that doesn't
    // exist in the base extension.
    defaultResource: { layouts: {} },
    // extensionModelClass - when the base model ('modelClass') has an 'extensions' array, we will get it from that
  }, {
    property: 'source',
    baseFile: SOURCE_NAME,
    extensionFile: SOURCE_EXT_NAME,
    isModule: true,
    removeExtRelative: true,
    optional: true,
  }, {
    property: 'template',
    baseFile: TEMPLATE_NAME,
    extensionFile: TEMPLATE_EXT_NAME,
    prefix: 'text!',
    optional: true,
  }, {
    property: 'clientMetadata',
    baseFile: null, // we do NOT allow data-description-overlay.json when using data-description.json
    extensionFile: METADATA_OVERLAY_EXT_NAME,
    prefix: 'text!',
    optional: true,
    modelClass: null,
    extensionModelClass: EXTENSION_MODEL,
  }];

  /**
   * DataDescriptionMetadataProviderHelper can be used as the "vbHelper" interface that is passed to
   * JET dynamic UI metadata provider for interfacing with VB.
   *
   * This extends ConfigurableMetadataProviderHelper, and implements the "data-description.json" version of
   * the helper; the metadata comes from the local JSON file, rather than service openapi3.
   *
   * @see ServiceMetadataProviderHelper for the closely-related openapi3 version.
   *
   * its 'private', but shared with JET; not for use my application developers
   */

  class DataDescriptionMetadataProviderHelper extends ConfigurableMetadataProviderHelper {
    /**
     *
     * @param options
     * @param options.id {string}
     * @param vbContext the context created by VB, and passed as 'options.context' to the components/providers
     * @param container the VB container where the dynamic component with this layout will be used
     * @returns {Promise<ConfigurableMetadataProviderHelper>} this
     */
    static get(options, vbContext, container) {
      const helperOptions = DataDescriptionMetadataProviderHelper.getHelperConfiguration();

      const opts = Object.assign({}, options, { helper: helperOptions });

      return new DataDescriptionMetadataProviderHelper(opts, vbContext, container).init();
    }

    /**
     * @param options
     * @param vbContext
     * @param container
     */
    constructor(options, vbContext, container) {
      super(options, vbContext, container);
      if (options.path) {
        this.setLayoutRoot(options.path);
      }
    }

    /**
     * override to provide a workaround for BUFP-39805;
     * we need to (temporarily) merge layout.js and data-description.js
     *
     * @todo: remove this after 20.10
     *
     * @see ServiceMetadataProviderHelper.getLayoutResources
     *
     * @returns {Promise}
     */
    getMetadata() {
      let metadata;
      return super.getMetadata()
        .then((m) => {
          metadata = m;
          // getLayoutModel is deprecated, but still used by (older dyn ui) JET.
          // We are only calling it here for BUFP-39805, which is a temporary workaround for a pre-release issue.
          // We should be able to remove this entire override after 20.10.
          // Note, we don't need the extensions, because data-description is metadata, and doesn't have extensions,
          // so we just need the base layout.js
          return this.getLayoutModel();
        })
        .then((layoutModel) => {
          // if we have both a data-description.js (metadata.dataModel) and
          // a layout.js (resources.dataModel), merge them
          if (metadata.dataModel && layoutModel && (layoutModel.$layout instanceof LayoutContext)) {
            // eslint-disable-next-line no-param-reassign
            metadata.dataModel = ModelUtils.chooseCorrectFunctionsModel(metadata.dataModel, layoutModel,
              'data-description.js'); // only used for logging
          }

          return metadata;
        });
    }

    /**
     * the configuration for the base class
     * @returns {{resources: {layout: {baseFile: *, extensionFile: *, prefix: string},
     *   metadata: {baseFile: *, prefix: string}, additional: *}}}
     */
    static getHelperConfiguration() {
      return {
        descriptors: {
          // need to clone RESOURCE_DESCRIPTOR so we don't have multiple helpers overwriting it
          files: Utils.cloneObject(RESOURCE_DESCRIPTORS),
          // data-description.json
          metadata: {
            baseFile: METADATA_NAME,
            prefix: 'text!',
            modelClass: BASE_MODEL,
            // extension to this is included as clientMetadata
          },
        },
      };
    }
  }


  // expose for unit tests
  DataDescriptionMetadataProviderHelper.RESOURCE_DESCRIPTORS = RESOURCE_DESCRIPTORS;

  // expose this for ServiceMetadataProviderHelper
  DataDescriptionMetadataProviderHelper.METADATA_NAME_ROOT = METADATA_NAME_ROOT;
  DataDescriptionMetadataProviderHelper.METADATA_OVERLAY_EXT_NAME = METADATA_OVERLAY_EXT_NAME;
  DataDescriptionMetadataProviderHelper.BASE_MODEL = BASE_MODEL;
  DataDescriptionMetadataProviderHelper.EXTENSION_MODEL = EXTENSION_MODEL;

  return DataDescriptionMetadataProviderHelper;
});

