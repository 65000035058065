/* global cordova:false */

'use strict';

define('vb/private/stateManagement/router',[
  'vb/private/stateManagement/router/vbRouter',
  'vb/private/stateManagement/routerUtils',
  'ojs/ojcontext',
  'vb/private/log', 'vb/private/utils', 'vb/private/constants', 'vb/private/configuration',
  'vb/private/history', 'vb/private/stateManagement/navigationContext', 'urijs/URI',
], (VbRouter, RouterUtils, ojContext, Log, Utils, Constants, Configuration, History, NavigationContext, URI) => {
  const SPECIAL_URLS_REGEX = /^(tel:|sms:|mailto:|geo:)/;

  const HTTP_FILE_REGEX = new RegExp('^(http|https):', 'i');

  const SUPPORTED_MOBILE_FILE_TYPES = new Map([
    ['txt', 'text/plain'],
    ['text', 'text/plain'],
    ['rtf', 'text/rtf'],
    ['csv', 'text/csv'],
    ['pdf', 'application/pdf'],
    ['doc', 'application/msword'],
    ['xls', 'application/vnd.ms-excel'],
    ['ppt', 'application/vnd.ms-powerpoint'],
    ['mp4', 'video/mp4'],
    ['mp3', 'audio/mpeg']]);

  const targetError = () => new Error('The target option is only valid with flow');

  /**
   * A singleton object used to manage tasks related to routing like navigation.
   * It is using a VbRouter object to control the current navigationb state of an application
   */
  class Router {
    constructor() {
      this.log = Log.getLogger('/vb/stateManagement/router');

      // Initialize history with a listener for the history popstate event
      History.init(false, this.popStateEventListener.bind(this));

      // remove the url fragment without triggering a refresh, e.g., clear the access token fragment
      this.removeAccessTokenFromUrlFragment();

      // A place to store simultaneous navigation during the navigation process
      this.pendingNavigations = {};

      // This property will be initialized in application.load()
      this.application = null;

      // Create the baseUrl property using on the already calculated baseUrl from require config
      this._baseUrl = requirejs.toUrl('');

      this.log.info('Loading application resources from', this.baseUrl);

      // Make sync available on Router
      this.sync = VbRouter.sync;

      this.rootRouter = null; // Initialized in init()
    }

    /**
     * @type {String}
     */
    get baseUrl() {
      return this._baseUrl;
    }

    /**
     * Initialize the router URL adapter and baseUrl value based on a combination
     * of URL setting and the "useParamAdapter" entry in the app flow definition.
     *
     * @param  {VbRouter} router the JET router object to be the root for the application
     * @param  {Boolean}   routerStrategy the value of the entry in the app
     * flow definition
     */
    init(rootRouter, routerStrategy) {
      this.rootRouter = rootRouter;
      this.rootRouter.history = History;

      // Initialize the router baseUrl with the application URL
      // What the JET Router calls the "baseUrl" is the URL of the application in browser
      // eslint-disable-next-line no-param-reassign
      VbRouter.defaults.baseUrl = Configuration.applicationUrl;
      this.log.info('Application URL is', Configuration.applicationUrl);

      if (routerStrategy === Constants.RouterStrategy.QUERY) {
        const routerDefaults = VbRouter.defaults;
        // Name of the request attribute to be used for the page
        routerDefaults.rootInstanceName = 'page';
        routerDefaults.urlAdapter = new VbRouter.UrlParamAdapter();

        /**
         * Save the URL parameter on the URL and the input parameter in the browser history
         * @param {String} pagePath the path to the current page
         */
        this.rootRouter.updateState = (pagePath, replace) => Promise.resolve().then(() => {
          const params = RouterUtils.buildSearch(this.rootRouter);
          params.forEach((param) => {
            History.addUrlParameter(param.name, param.id);
          });

          // Push the new state to the browser history
          if (!replace) {
            History.pushState();
          }

          History.setPagePath(pagePath);
          return History.sync();
        });
      } else {
        this.rootRouter.updateState = (pagePath, replace) => Promise.resolve().then(() => {
          // Push the new state to the browser history
          if (!replace) {
            History.pushState();
          }

          History.setPagePath(pagePath);
          return History.sync();
        });

        if (!Configuration.appName) {
          // When the router strategy is PATH and app name is not defined then
          // the go function needs to add the URL marker 'vp' to the URL
          this.log.info('Using navigation with URL marker:', Configuration.urlMarker);

          // Wrap the go method when the URL marker 'vp' is needed.
          const originalGo = rootRouter.go.bind(rootRouter);
          this.rootRouter.go = (...args) => {
            const argsCopy = [...args];

            // When using the 'path strategy', prefixes the path with /vp
            argsCopy[0] = `/${Configuration.urlMarker}/${argsCopy[0]}`;

            return originalGo(...argsCopy);
          };

          this.rootRouter.setCurrentNavPath = (navPath) => {
            this.rootRouter.parent.setCurrentNavPath(navPath);
          };

          this.rootRouter.getCurrentNavPath = () => this.rootRouter.parent.getCurrentNavPath();
        }
      }

      // Busy state will be released in the run of the leaf page
      this.setBusyState();
    }

    /**
     * A listener of the popstate event used to know when to refresh the input parameters
     * when the navigating back to the same page.
     */
    popStateEventListener() {
      // The rootInstance can be null when traversing history of a deleted switcher element
      // In that case just ignore the entry
      const { rootInstance } = VbRouter.VbRootRouterClass;
      if (!rootInstance) {
        return;
      }
      // Set the router busy state on navigation from the browser, the state will be cleared
      // when the page is rendered in page.run()
      this.setBusyState();

      const page = this.getCurrentPage(true);

      if (page && page.fullPath === History.getPagePath()) {
        Promise.resolve().then(() => {
          page.refreshInputParameters();

          return page.invokeAfterNavigateEvent();
        }).finally(() => {
          // Navigation to the same page does not call page.run(), so need to clear the state here
          this.clearBusyState();
        });
      }
    }

    /**
     * Pushes a new path into the internal history stack
     * @param fullPath
     */
    pushState(fullPath) {
      if (!this.historyStack) {
        Object.defineProperty(this, 'historyStack', {
          // start the history with the current path
          value: [this.application.getCurrentPagePath()],
          enumerable: true,
          configurable: true,
        });
      }

      this.historyStack.push(fullPath);
    }

    /**
     * Pops the internal history stack and returns the last item in it
     * @returns {string}
     */
    popState() {
      if (this.historyStack && this.historyStack.length > 0) {
        return this.historyStack.pop();
      }

      return null;
    }

    /**
     * Peeks the last item in the history stack
     * @returns {string}
     */
    peekState() {
      if (this.historyStack && this.historyStack.length > 0) {
        return this.historyStack[this.historyStack.length - 1];
      }

      return null;
    }

    /**
     * Clean up and validate the navigateAction and navigateToPageAction arguments
     *
     * @param  {Object} options
     * @param  {String} options.operation - the navigation operation to perform
     * @param  {String} options.page - the path to the destination page. The path is absolute starting
     * at the application or relative to the current page. When use in combination with flow or appPackage,
     * the path cannot be absolute and it navigates to the page relative to the flow or appPackage.
     * @param  {String} options.flow - the id of the destination flow. Change the content of the flow displayed
     * in the current page. When used in combination with a page property, navigates to the page in that flow.
     * @param  {String} options.application - the id of the destination App UI. Change which App UI
     * is displayed in the host application. When used in combination with a page property, navigates
     * to the page in that App UI.
     * @param {String} options.target - an option destination when using the flow property
     * @param  {Object<String, String>} options.params - A map of URL parameters. Same as in the navigateToPage
     * action (optional)
     * @param  {String} options.history - the type of operation on the browser history. Allowed value are 'replace',
     * 'skip' or 'push'. If the value is 'replace', the current browser history entry is replaced, meaning that back
     * button will not go back to it. If the value is 'skip', the URL is left untouched.
     * Same as in the navigateToPage action(optional and default is push)
     * @return {Object} the cleaned up options
     */
    validateNavigationOptions(container, options) {
      let {
        page, flow, application, target, operation,
      } = options;

      // Note that an empty string means to go to default page
      if (typeof page === 'string') {
        page = page.trim();
      }

      // Note that an empty string means to go to default flow
      if (typeof flow === 'string') {
        flow = flow.trim();

        if (flow.indexOf('/') > 0) {
          throw new Error(`The flow parameter "${flow}" cannot be a path, it should be a flow id.`);
        }
      }

      if (typeof target === 'string') {
        target = target.trim();
      }

      if (operation === Constants.NavigateOperation.PAGE_OLD) {
        if (!page) {
          throw new Error('Cannot navigate to an undefined path.');
        }
      } else if (typeof application === 'string') {
        if (target) {
          throw targetError();
        }

        application = application.trim();
        if (application.length > 0 && !this.application.doesAppUiExist(application)) {
          throw new Error(`The App UI ${application} does not exist.`);
        }

        operation = Constants.NavigateOperation.APP_UI;
      } else if (typeof flow === 'string') {
        operation = Constants.NavigateOperation.FLOW;
      } else if (typeof page === 'string') {
        if (target) {
          throw targetError();
        }
        operation = Constants.NavigateOperation.PAGE;
      } else {
        throw new Error('Cannot navigate to an undefined location.');
      }

      const validOptions = {
        page,
        flow,
        application,
        target,
        operation,
        params: options.params,
        history: options.history,
      };

      // process additional validation specific to container; throws error if validation fails
      container.validateNavigation(validOptions);

      // if we are here all validations passed.
      return validOptions;
    }

    /**
     * Manage page navigation such that when an attemp to navigate is made while navigating,
     * the current navigation is cancelled and the new one is executed. the goal being that
     * when multiple navigation are queued, only the last navigation should complete.
     * @param  {Container} container the container on which the navigation is performed
     * @param  {Object} opts      the set of option for the navigation
     * @param  {String} opts.operation - the navigation operation to perform
     * @param  {String} opts.page - the path to the destination page. The path is absolute starting
     * at the application or relative to the current page. When use in combination with flow or appPackage,
     * the path cannot be absolute and it navigates to the page relative to the flow or appPackage.
     * @param  {String} opts.flow - the id of the destination flow. Change the content of the flow displayed
     * in the current page. When used in combination with a page property, navigates to the page in that flow.
     * @param  {String} opts.application - the id of the destination App UI. Change which App UI
     * is displayed in the host application. When used in combination with a page property, navigates
     * to the page in that App UI.
     * @param {String} opts.target - an option destination when using the flow property
     * @param  {Object<String, String>} opts.params - A map of URL parameters. Same as in the navigateToPage
     * action (optional)
     * @param  {String} opts.history - the type of operation on the browser history. Allowed value are 'replace',
     * 'skip' or 'push'. If the value is 'replace', the current browser history entry is replaced, meaning that back
     * button will not go back to it. If the value is 'skip', the URL is left untouched.
     * Same as in the navigateToPage action(optional and default is push)
     * @return {Promise} a Promise that resolves to an object with a property named
     * 'navigated' which value is true if a navigation occurred.
     */
    queueNavigate(container, opts) {
      return Promise.resolve()
        .then(() => this.validateNavigationOptions(container, opts))
        .then((options) => container.validateNavigationInDt(options)
          .then((canNavigate) => {
            if (!canNavigate) {
              return { navigated: false };
            }

            // If a navigation to the same page already exist, return the existing promise
            // instead of creating a new one.
            let navContext = this.getPendingNavigation(container, options);
            if (navContext) {
              return navContext.navigatePromise;
            }

            // We're about to start a new navigation, so start cancelling all existing navigation
            this.cancelPendingNavigations();

            // Create a new navigation context and add it to the list of pending navigation.
            navContext = this.addPendingNavigation(container, options);

            // add a busy state just to be safe and consistent with navigateBack which will be cleared after
            // page enter
            this.setBusyState();

            return navContext.navigate()
              .then((result) => {
                // When navigated is true, the busy state should not be cleared until the page is done
                // (after vbEnter), but if navigated is false, clearBusyState right away
                if (result && result.navigated === false) {
                  this.clearBusyState();
                }

                return result;
              })
              .catch((error) => {
                this.clearBusyState();
                throw error;
              })
              .finally(() => {
                this.deletePendingNavigation(navContext);
              });
          }))
        .catch((error) => {
          // Log the error because the action just return a failure outcome without errors
          this.log.error(error);
          throw error;
        });
    }

    /**
     * Create a new navigate context and add it to the pending navigations
     * @param {Container} container  the container driving the navigation
     * @param {Object} options       a set of navigation options
     * @returns {NavigationContext}  the newly created navigation context
     */
    addPendingNavigation(container, options) {
      const navContext = new NavigationContext(container, options);
      this.pendingNavigations[navContext.id] = navContext;
      return navContext;
    }

    /**
     * Delete a navigation context from the pending navigations
     * @param  {NavigationContext} navContext [description]
     */
    deletePendingNavigation(navContext) {
      if (navContext.isCancelled()) {
        this.log.info('Ending cancelled navigation to:', navContext.options);
      } else {
        this.log.info('Ending executed navigation to:', navContext.options);
      }

      delete this.pendingNavigations[navContext.id];
    }

    /**
     * Return a navigationContext from the pending navigation that matches
     * the navigation requirement (container + options)
     * @param {Container} container  the container driving the navigation
     * @paraM {Object} options       the navigation options
     * @retuns {NavigationContext}   the navigation context in the pending navigations
     * matching container and options
     */
    getPendingNavigation(container, options) {
      const values = Object.values(this.pendingNavigations);

      for (let index = 0; index < values.length; index += 1) {
        const item = values[index];
        // Check if the navigation is the same
        if (item.equals(container, options)) {
          return item;
        }
      }

      return null;
    }

    /**
     * Cancels all active pending navigation
     */
    cancelPendingNavigations() {
      Object.values(this.pendingNavigations).forEach((item) => {
        // if we are in the middle of a navigation, cancel it
        if (item.isActive()) {
          item.cancel();
        }
      });
    }

    /**
     * Navigate to an external site with given a set of input parameters.
     *
     * @param {Object} options
     * @param {String} options.url        The url to navigate to (required)
     * @param {Array} options.params      A map of URL parameters (optional)
     * @param {String} options.hash       The hash entry (optional)
     * @param {String} options.history    Effect on the browser history. Allowed value are 'replace' or 'push'.
     *                                    If the value is 'replace', the current browser history entry is replace,
     *                                    meaning that back button will not go back to it.
     *                                    (optional and default is push)
     * @return {Promise}
     */
    navigateToExternal(options) {
      const href = Utils.resolveIfObservable(options.url);

      if (!href) {
        throw new Error('The property url is a required parameter for navigateToExternal action.');
      }
      // For DT environment, retrieve current and destination page then invoke the
      // canNavigatePage to give a chance to DT to cancel navigation.
      const currentPath = this.application.getCurrentPagePath();
      return this.application.runtimeEnvironment.canNavigateToUrl(currentPath, href).then((canNavigate) => {
        if (canNavigate) {
          const uri = new URI(href);

          if (options.params) {
            uri.addSearch(options.params);
          }

          if (options.hash) {
            uri.hash(options.hash);
          }

          if (options.history === Constants.HistoryMode.REPLACE) {
            window.location.replace(uri.href());
          } else {
            window.location.assign(uri.href());
          }
        }
      });
    }

    /**
     * Implementation of the openUrl action.
     * Open the specified resource in the current window or in a new window using the window.open() API
     * as defined at {@link https://developer.mozilla.org/en-US/docs/Web/API/Window/open|Window.open()}
     * In the mobile case, the cordova's inAppBrowser plugin will be used to open the specified resource.
     * For more information about the plugin, please refer to
     * {@link https://github.com/apache/cordova-plugin-inappbrowser}
     *
     * @param {Object} options
     * @param {String} options.url         The url to navigate to (required)
     * @param {Object} options.params      An object with each property is a URL parameters (optional)
     * @param {String} options.hash        The hash entry (optional)
     * @param {String} options.history     Effect on the browser history (optional)
     *                                     This is only used when opening the resource in the same window.
     *                                     Allowed values are 'replace' or 'push'. Default is 'push'.
     *                                     If the value is 'replace', the current browser history entry is
     *                                     replaced instead of pushed, meaning that the browser back button
     *                                     will not go back to it.
     * @param {String} options.windowName  A name identifying the window as defined in window.open API (optional)
     *                                     If not defined, the URL will be open in the current window otherwise
     *                                     refer to the window.open API.
     *                                     In the mobile case, there are 3 possible values: '&#95;self', '&#95;blank',
     *                                     or '&#95;system'. Default is '&#95;self'
     */
    openUrl(options) {
      const url = Utils.resolveIfObservable(options.url);

      // Empty string is valid but not undefined or null
      if (url === undefined || url === null) {
        throw new Error('The property "url" is required for openUrl.');
      }

      let windowName = options.windowName || '_self';
      if (windowName.indexOf(' ') >= 0) {
        throw new Error('The property "windowName" should not contain whitespace.');
      }

      // For DT environment, retrieve current path then invoke the canOpenUrl to give a chance
      // to DT to cancel the operation.
      return this.application.runtimeEnvironment.canOpenUrl(this.application.getCurrentPagePath(),
        url, options.windowName)
        .then((result) => {
          if (result) {
            const uri = new URI(url);

            if (options.params) {
              uri.addSearch(options.params);
            }

            if (options.hash) {
              uri.hash(options.hash);
            }

            const href = uri.href();

            if (Utils.isMobile()) {
              // For native mobile, we now support displaying the following local file attachments using the
              // cordova-file-opener2 plugin:
              // .pdf, .txt/.text, .mp3, .mp4, .rtf, .doc, .xls, .ppt, .csv
              // Using links and the inappbrowser plugin is not helpful in this case because on Android
              // the WebView doesn't support these extensions and on iOS there is no back button to get
              // back to the app once the file is opened.

              // determine whether it's a supported file extension:
              if (this.canOpenMobileFile(href)) {
                // the given file extension is supported, so we should open it using the fileOpener2 cordova plugin
                // note that the windowName is ignored in this case
                return this.openMobileFile(href);
              }

              // The file extension is either not recognized or the file is not local, so go through the
              // inappbrowser cordova plugin
              // on Android tel:, sms:, mailto:, geo: only work if the windowName is _system, so as the workaround
              // re-set the windowName to _system only if it hasn't been specifically set by the user
              if (href.match(SPECIAL_URLS_REGEX) && !options.windowName) {
                windowName = '_system';
              }

              window.open(href, windowName);
            } else if (windowName === '_self') {
              // When operating on the same window in a web app, use replace or assign depending on the history setting
              if (options.history === Constants.HistoryMode.REPLACE) {
                window.location.replace(href);
              } else {
                window.location.assign(href);
              }
            } else {
              window.open(href, windowName);
            }
          }

          return Promise.resolve();
        });
    }

    /**
     * Navigate back to the previous page in browser history with input parameters.
     *
     * @param {Page} page - the current page instance
     * @param {Object} options - The set of options for the navigateBack action
     * @param {Array<string, string>} options.params - The input parameters for the page
     * the action is going back to
     */
    navigateBack(page, options) {
      // Since runtimeEnvironment.canNavigateBack and history.back are both async, there is a window
      // where the application could be in a non-busy state during page transition. We close that
      // window here by setting a busy state which will be cleared after page enter.
      this.setBusyState();

      // For DT environment, invoke the canNavigatePage with the current page to give a
      // chance to DT to cancel navigation.
      this.application.runtimeEnvironment.canNavigateBack(page.fullPath).then((canNavigate) => {
        if (canNavigate) {
          History.setNavNavBackParams(options.params);

          this.doNavigateBack();
        } else {
          this.clearBusyState();
        }
      });
    }

    /**
     * Perform the actual navigation back
     * @see navigateBack
     */
    // eslint-disable-next-line class-methods-use-this
    doNavigateBack() {
      History.back();
    }

    /**
     * Traverse the router hierarchy to retrieve the current page.
     * The content of the application is made of nested flows and the leaf flow is
     * displaying a page. This routine traverse the hierarchy of flows using the
     * router hierarchy and return the page on the leaf flow.
     *
     * When the full argument is thruthy, the function retrieve the current page of
     * the switcher.
     *
     * @param  {Router} rt router
     * @param  {Boolean} full when true, traverse the full container hierarchy to the switcher current page
     * @return {Page}   the current page
     */
    getCurrentPage(full) {
      return RouterUtils.getCurrentPage(this.rootRouter, full);
    }

    /**
     * Return the container instance for a path. Instance can either be a flow or a page.
     * @param  {Container} container the container where the path will be searched
     * @param  {String} path         the path to search
     * @return {Container}           Either a flow or a page
     */
    getContainer(container, path) {
      const index = path.indexOf('/');

      if (index > 0) {
        const id = path.substring(0, index);
        return this.getContainer(container.getContainer(id), path.substr(index + 1));
      }

      return container.getContainer(path);
    }

    /**
     * Calculate the URL of a page or flow given its id path like /pageId/flowId/pageId
     * This is mainly needed to build the return path when calling a the login service.
     * It does not prepend the base URL. When this method is called while loading the
     * application, all existing input parameters are copied to the returned path, this
     * is to preserve the bookmarked data when calling the login service.
     *
     * @param  {string} path the path of a page or flow
     * @return {string}      the URL to this page or flow
     */
    getUrlFromPath(path = '') {
      // Paths are always absolute. In case the path starts with '/', remove it
      if (path[0] === '/') {
        path = path.substring(1);
      }

      let uri;
      if (this.application.started) {
        uri = new URI(''); // Create a blank uri
      } else {
        // When loading the the application for the first time, we need to copy the
        // existing request parameters to the return URL
        uri = new URI();
      }

      if (this.application.isQueryStrategy()) {
        if (path) {
          const segments = path.split('/');
          let key = VbRouter.defaults.rootInstanceName;

          segments.forEach((segment) => {
            uri.addSearch(key, segment);
            key = segment;
          });
        }
        return uri.search();
      }

      if (Configuration.appName) {
        return `${path}${uri.search()}`;
      }

      return `${Configuration.urlMarker}/${path}${uri.search()}`;
    }

    /**
     * Parse the given # into a map of keys and values.
     *
     * @param hash the hash to parse
     * @returns {Object}
     */
    // eslint-disable-next-line class-methods-use-this
    parseFragment(hash) {
      const uri = new URI(hash);
      return URI.parseQuery(uri.fragment());
    }

    /**
     * Remove fragment from the current URL is the fragment is an access token.
     * This is done without triggering a refresh.
     */
    // eslint-disable-next-line class-methods-use-this
    removeAccessTokenFromUrlFragment() {
      const { location } = window;
      const fragment = location.hash;

      // Only remove the hash when the fragment is an access token
      if (fragment && !Utils.isMobile()
        && (fragment.indexOf('token_type') > 0 || fragment.indexOf('access_token') > 0)) {
        window.history.replaceState(window.history.state, '', location.pathname + location.search);
      }
    }

    /**
     * Gets the url segment after the application name.
     *
     * @return  {string}  The url segment after the application name.
     */
    // eslint-disable-next-line class-methods-use-this
    getUrlSegmentAfterAppName() {
      let urlId;

      if (Configuration.appName) {
        const path = window.location.pathname;

        // Extract the segment immediately following the appName
        urlId = path.substring(path.indexOf(Configuration.appName) + Configuration.appName.length + 1);
        const slash = urlId.indexOf('/');
        if (slash >= 0) {
          urlId = urlId.substring(0, slash);
        }
      }

      return urlId;
    }

    /**
     * Add a busy state to the page's busy context during navigation.
     *
     * Note: For now, to keep it simple, we will assume there can be only one busy state outstanding meaning
     * no navigation can happen in the middle of another navigation. We will address that use case in the
     * future.
     */
    setBusyState() {
      if (!this.busyStateResolver) {
        const busyContext = ojContext.getPageContext().getBusyContext();
        this.busyStateResolver = busyContext.addBusyState({ description: 'VB Router' });
      }
    }

    /**
     * Clear the outstanding busy state.
     */
    clearBusyState() {
      if (this.busyStateResolver) {
        this.busyStateResolver();
        delete this.busyStateResolver;
      }
    }

    /**
     * Determines whether the given file is local and can be opened.
     * @param filePath path to the file to open
     * @returns {boolean} true if the file can be opened; false otherwise
     */
    // eslint-disable-next-line class-methods-use-this
    canOpenMobileFile(filePath) {
      if (!HTTP_FILE_REGEX.test(filePath)) {
        const ext = filePath.substr(filePath.lastIndexOf('.') + 1);
        return !!(ext && SUPPORTED_MOBILE_FILE_TYPES.get(ext));
      }

      return false;
    }

    /**
     * Retrieves the mime type for the given file.  If the file extension is not supported, an empty string
     * is returned back.
     * @param filePath path to the file to open
     * @returns {string} supported mime type for the given file or an empty string otherwise
     */
    // eslint-disable-next-line class-methods-use-this
    getMobileFileExtensionMimeType(filePath) {
      const ext = filePath.substr(filePath.lastIndexOf('.') + 1);
      return ext && SUPPORTED_MOBILE_FILE_TYPES.get(ext) ? SUPPORTED_MOBILE_FILE_TYPES.get(ext) : '';
    }

    /**
     * Opens the given file by using the cordova fileOpener2 plugin.
     * @param filePath path to the file to open
     * @returns {Promise} a promise which resolves if the file was successfully opened or rejects otherwise
     */
    openMobileFile(filePath) {
      // get the mime type for the file to open:
      const mimeType = this.getMobileFileExtensionMimeType(filePath);

      if (mimeType) {
        return new Promise((resolve, reject) => {
          cordova.plugins.fileOpener2
            .open(filePath, mimeType, {
              error(msg) {
                reject(msg);
              },
              success() {
                resolve();
              },
            });
        });
      }

      // un-supported mime type:
      return Promise.reject(new Error('Cannot open unsupported file.'));
    }
  }

  // Return a singleton object representing the router for this application.
  return new Router();
});

