'use strict';

define('vbsw/private/pwa/pwaUtils',['vbsw/private/constants', 'vbc/private/pwa/pwaUtils'], (Constants, CommonPwaUtils) => {
  class PwaUtils extends CommonPwaUtils {
    /**
     * For PWA support, service worker needs to pre cache application shell. The application resource url's
     * are relative to service's worker scope, app path and asset version. This function computes the app path portion
     * of a resource url (ignoring asset versions since it does not apply to start url)
     *
     * For example, for the following preview vbInitConfig:
     *
     * window.vbInitConfig = {
     * JET_CDN_PATH: 'https://static.oracle.com/cdn/jet/',
     * JET_CDN_VERSION: '9.0.1',
     * DEBUG: true,
     * CONTEXT_ROOT: '',
     * APP_ID: '0821',
     * APP_VERSION: '1.0',
     * APP_URL_PREFIX: 'design',
     * IS_DT_MODE: true,
     * IS_MOBILE: false,
     * APP_PATH: 'webApps/test',
     * BASE_URL_TOKEN: 'version_123',
     * }
     *
     * the app path would be:
     *
     * "preview/webApps/test/
     *
     * and for the following stage vbInitConfig:
     *
     * window.vbInitConfig = {
     * JET_CDN_PATH: 'https://static.oracle.com/cdn/jet/',
     * JET_CDN_VERSION: 'v5.2.0',
     * DEBUG: false,
     * CONTEXT_ROOT: '',
     * APP_ID: '0821',
     * APP_VERSION: '1.0',
     * APP_URL_PREFIX: 'rt',
     * IS_DT_MODE: false,
     * IS_MOBILE: false,
     * APP_PATH: 'webApps/test',
     * }
     *
     * the app path would be:
     * "webApps/test/"
     *
     * @param config vbInitConfig
     * @return {String} the application path based on vbInitConfig parameters, or undefined
     */
    static computeAppPath(config) {
      if (!config) {
        return undefined;
      }
      let appPath = '';
      if (config.IS_DT_MODE) {
        appPath += 'preview/';
      }
      if (config.APP_PATH) {
        appPath += config.APP_PATH.endsWith(Constants.PATH_SEPARATOR) ? config.APP_PATH
          :`${config.APP_PATH}${Constants.PATH_SEPARATOR}`;
      }
      return (appPath.length > 0) ? appPath : undefined;
    }

    /**
     * @returns {string} a locale supported by PWA
     */
    static getLocale() {
      // TODO: Get locale from VB runtime - oj.Config.getLocale(); does not work
      // when called too early before requirejs.config is set up
      // https://jira.oraclecorp.com/jira/browse/BUFP-25113
      return 'en-US';
    }

    /**
     * Creates an array of all locales that should be supported based on a single language tag.
     * @param languageTag language tag, for example, <i>en-US</i>
     * @returns {Array<string>} list of individual locales, for example, <i>['en', 'en-US']</i>
     */
    static parseLocale(languageTag) {
      const locales = [];
      if (typeof languageTag === 'string') {
        const languageTags = languageTag.split('-');
        let previousLocale;
        let newLocale;
        locales.push(...languageTags.map((l) => {
          newLocale = previousLocale ? `${previousLocale}-${l}` : l;
          previousLocale = newLocale;
          return newLocale;
        }));
      }
      return locales;
    }

    /**
     * @param url request url
     * @param config configuration object for the service worker
     * @returns {boolean} true, if request matches JET path specified in the config, for example:
     * 'https://static.oracle.com/cdn/jet/v6.0.0/'
     */
    static isJetRequest(url, config) {
      if (config && config.jetPath) {
        return url.startsWith(config.jetPath);
      }
      return false;
    }

    /**
     * Resource path is constructed as a url relative to service worker scope by combining appPath and
     * baseUrlToken.
     * @param appPath relative to scope, location of application assets. For example: "mobileApps/FixitFast/"
     * @param baseUrlToken used for versioning of application artifacts. For example: "version_1537547325000"
     * @returns {string} application asset path.
     */
    static constructScopeRelativeResourcePath(appPath, baseUrlToken) {
      return `.${PwaUtils.constructRelativeResourcePath(appPath, baseUrlToken)}`;
    }

    static constructRelativeResourcePath(appPath, baseUrlToken) {
      const path = `${PwaUtils.postSlash(appPath)}${PwaUtils.postSlash(baseUrlToken)}`;
      if (path) {
        return path.startsWith(Constants.PATH_SEPARATOR) ? `${path}` : `${Constants.PATH_SEPARATOR}${path}`;
      }
      return Constants.PATH_SEPARATOR;
    }
  }
  return PwaUtils;
});

