'use strict';

define('vb/private/stateManagement/pageExtension',[
  'vb/private/stateManagement/containerExtension',
  'vb/private/stateManagement/context/pageExtensionContext',
  'vb/private/constants',
  'vb/private/utils',
  'vb/helpers/mixin',
  'vb/private/stateManagement/fragmentHolderExtensionMixin',
  'vb/private/translations/bundleUtils',
], (ContainerExtension, PageExtensionContext, Constants, Utils, Mixin, FragmentHolderExtensionMixin, BundleUtils) => {
  /**
   * PageExtension class
   */
  class PageExtension extends Mixin(ContainerExtension).with(FragmentHolderExtensionMixin) {
    constructor(extension, path, base, className = 'PageExtension') {
      // parent of the pageExtension is the potential extension of the base page parent flow
      super(extension, base.getParentFlow().extensions[extension.id], path, base, className);
    }

    static get extensionClass() {
      return PageExtension;
    }

    /**
     *
     * @type {String}
     */
    static get resourceSuffix() {
      return '-page-x.json';
    }

    /**
     * The default event prefix is the lowercase class name (see container.js) but for
     * page extension we want to use the same event prefix as page
     *
     * @type {String}
     */
    // eslint-disable-next-line class-methods-use-this
    get eventPrefix() {
      return 'page';
    }

    // oj-module lifecycle callback
    disconnected() {
      // // record a page deactivated state change
      // StateMonitor.recordStateChange(StateMonitor.RuntimeState.PAGE_DEACTIVATED);
      // StateMonitor.recordStateChange(StateMonitor.RuntimeState.CONTAINER_DEACTIVATED, this);
      // this.deactivated = true;
      this.dispose();
    }

    dispose() {
      // dispose fragments that the extension page might have created
      this.disposeFragments();

      delete this.definition;

      this.initializePromise = null;
      this.enterPromise = null;

      super.dispose();
    }

    /**
     * The name of the runtime environment function to be used to load the descriptor.
     * @type {string}
     */
    get fullName() {
      return `${this.id}-page`;
    }

    /**
     * The name of the runtime environment function to be used to load the descriptor
     *
     * @type {String} the descriptor loader function name
     */
    static get descriptorLoaderName() {
      return 'getPageExtensionDescriptor';
    }

    /**
     * The name of the runtime environment function to be used to load the module functions.
     *
     * @type {String} the module loader function name
     */
    static get functionsLoaderName() {
      return 'getPageExtensionFunctions';
    }

    /**
     * The name of the chain folder is the page name with '-page-x-chains' appended.
     * @type {string}
     */
    get chainsFolderName() {
      return `${this.id}-page-x-chains`;
    }

    /**
     * Returns the ExtensionContext constructor used to create the '$' expression context.
     * @type {PageExtensionContext}
     */
    static get ContextType() {
      return PageExtensionContext;
    }

    /**
     * This is called by ConfigurableMetadataProviderHelper._createExtensionModels when there is
     * dynamic container in the PageExtension. The expectation not to return a page viewModel object
     * but the availableContext.
     * Also see getViewModel on layoutExtension which is called in similar situation but for a
     * dynamic layout extension.
     *
     * @returns {Promise<Object>}
     */
    getViewModel() {
      return Promise.resolve()
        .then(() => Promise.all([Utils.getResource('vb/private/stateManagement/pageModuleViewModel'), BundleUtils.whenBundlesReady()]))
        .then(([PageModuleViewModel]) => new PageModuleViewModel(this));
    }

    /**
     * Returns a scope resolver map where keys are scope name ("page", "flow" or "application")
     * and value the matching objects. This is used to build the scopeResolver object.
     *
     * @private
     * @return {Object} an object which properties are scope
     */
    getScopeResolverMap() {
      const map = {
        [Constants.PAGE_PREFIX]: this,
      };

      // if the parent of the pageExtension is defined we need to merge scope resolvers
      // so that the pageExtension has access to its parent's scopes
      if (this.parent) {
        return Object.assign(map, this.parent.getScopeResolverMap());
      }

      return map;
    }
  }

  return PageExtension;
});

