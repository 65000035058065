'use strict';

define('vb/private/stateManagement/containerExtension',[
  'vb/private/stateManagement/container',
  'vb/private/log',
  'vb/private/constants',
  'vb/private/monitoring/loadMonitorOptions',
  'vb/helpers/mixin',
  'vb/private/stateManagement/packageAndExtensionContainerMixin',
], (Container, Log, Constants, LoadMonitorOptions, Mixin, PackageAndExtensionContainerMixin) => {
  const logger = Log.getLogger('/vb/stateManagement/containerExtension', [
    // Register a custom logger
    {
      name: 'greenInfo',
      severity: 'info',
      style: 'green',
    },
  ]);
  /**
   * ContainerExtension class
   *
   * @param {Object} extension
   * @param {Container} parent
   * @param {String} resourceLoc
   * @param {Container} base
   * @param {String} className
   */
  class ContainerExtension extends Mixin(Container).with(PackageAndExtensionContainerMixin) {
    constructor(extension, parent, resourceLoc, base, className = 'ContainerExtension') {
      super(base.name, parent, className);

      // Override value defined in Container
      this._extension = extension;

      // resourceLoc is the fully computed resource path relative to the base url
      this._resourceLoc = resourceLoc;

      // Readonly properties for safety
      Object.defineProperties(this, {
        // a reference to the base object
        base: {
          value: base,
          enumerable: true,
        },
        // from this point on the path value cannot be modified.
        path: {
          value: base.path,
          enumerable: true,
        },
      });

      this.fullPath = this.base.fullPath;

      this.view = {};
      this.log = logger;
    }

    /**
     * @type {String}
     */
    get resourceLoc() {
      return this._resourceLoc;
    }

    /**
     * The folder where the dynamic layouts are defined
     * For pages in an App UI, it's "dynamicLayouts/self/"
     * @type {String}
     */
    get layoutRoot() {
      return this.base ? `${Constants.DefaultPaths.LAYOUTS}${Constants.ExtensionFolders.SELF}/`
        : Constants.DefaultPaths.LAYOUTS;
    }

    /**
     * Returns the name of the function used to load the chain file.
     *
     * @type {string}
     */
    // eslint-disable-next-line class-methods-use-this
    get chainLoaderName() {
      return 'getExtensionTextResource';
    }

    /**
     * Used by events.
     * @returns {boolean}
     */
    // eslint-disable-next-line class-methods-use-this
    isExtension() {
      return true;
    }

    /**
     * The router path is the same for base and extension.
     * @return {String} the path
     */
    getContainerPath() {
      return this.base && this.base.fullPath;
    }

    /**
     * Add the extension id as part of the name to make it unique between
     * multiple extensions.
     * @return {String} a new scope name
     */
    getNewScopeName() {
      return `${this.className}/${this.extensionId}/${this.fullPath}`;
    }

    initDefault(definition) {
      const def = definition;

      def.extensions = def.extensions || {};

      super.initDefault(definition);
    }

    load() {
      const mo = new LoadMonitorOptions(this.loadSpanName, `${this.className} load ${this.fullPath}`, this);
      // eslint-disable-next-line arrow-body-style
      return this.log.monitor(mo, (extensionLoadTimer) => {
        return this.loadDescriptor()
          .then(() => {
            // This will show something like "vx/opty/ui/base/app loaded."
            // This concisely describe the type of container and its exact location
            this.log.greenInfo(this.getResourcePath(), 'loaded.', extensionLoadTimer());
          })
          .catch((error) => {
            extensionLoadTimer(error);
            throw error;
          });
      });
    }

    // eslint-disable-next-line class-methods-use-this
    checkAccess() {
      return Promise.resolve();
    }

    /**
     * Use by extension container and App UI container to adjust the path of a local resource.
     * This is used when 2 extensions use the same name import. When we process requirejs
     * configuration we use a map feature to separate entries by extension. That way same
     * entry for multiple extensions will be resolved correctly. So, when we request path
     * resolution for a component we prepend the extension prefix to get the path correctly
     * resolved.
     *
     * For a component defined in the extension, this will transform the component path as follow:
     * "democard/loader" => "vx/<ext-id>/democard/loader"
     *
     * @param  {String} path the import path
     * @return {String}      the adjusted path
     */
    adjustImportPath(path) {
      const baseUrl = this.baseUrl;

      // need to convert local path to the app based path
      // Skip the JET component because we know they're not local to the extension
      if (!Container.isJetComponent(path) && baseUrl) {
        const localPath = `${baseUrl}${path}`;
        const localUrl = requirejs.toUrl(localPath);
        const baseUrlPath = requirejs.toUrl(baseUrl);
        // check if local path resolves into location within the extension
        // if localPath resolves into the same URL as resolved base + path it means no custom path mapping was found
        if (localUrl !== `${baseUrlPath}${path}`) {
          return localPath;
        }
      }

      return path;
    }

    /**
     * Checks whether the current container has any dirty data variables.
     * For extension containers, it will look up the base container first
     * and then check the base container's current scope, then child
     * containers and any possible extensions.
     * @return {boolean} true if there is at least one dirty data variable found;
     *                   false otherwise
     */
    isDirtyDataPresent() {
      // get the base container and then check for dirty data:
      const baseContainer = this.base;

      if (baseContainer) {
        return baseContainer.isDirtyDataPresent();
      }

      return false;
    }

    /**
     * Re-sets the dirty data state of variables.
     * For extension containers, it will look up the base container first
     * and then check the base container's current scope as well as all
     * child containers and their extensions.
     */
    resetAllDirtyData() {
      // get the base container and then reset dirty data:
      const baseContainer = this.base;

      if (baseContainer) {
        baseContainer.resetAllDirtyData();
      }
    }
  }

  return ContainerExtension;
});

