'use strict';

define('vb/private/stateManagement/context/fragmentBaseContext',[
  'vb/private/constants',
  'vb/private/stateManagement/context/baseContext'],
(Constants, BaseContext) => {
  /** @type Object */
  const symbol = Symbol('fragment-base-accessor');

  /**
   * set of properties to expose in $base for fragment extension
   */
  class FragmentBaseContext extends BaseContext {
    constructor(fragment) {
      super(fragment);

      Object.defineProperty(this, symbol, {
        value: {
          get fragmentContext() {
            return fragment.expressionContext;
          },
        },
      });
    }

    /**
     * $base.fragment.info
     * @returns {*}
     */
    get [Constants.INFO_CONTEXT]() {
      return this[symbol].fragmentContext[Constants.INFO_CONTEXT];
    }

    /**
     * $base.fragment
     * @type {FragmentBaseContext}
     */
    get fragment() {
      return this;
    }
  }

  return FragmentBaseContext;
});

