'use strict';

define('vbsw/private/emulatedServiceWorkerWrapper',['vbsw/private/serviceWorkerWrapper',
  'vbsw/private/fetchHandler'], (ServiceWorkerWrapper, FetchHandler) => {
  /**
   * Wrapper class for a emulated service worker instance.
   */
  class EmulatedServiceWorkerWrapper extends ServiceWorkerWrapper {
    constructor(scope, registration, config, persistenceManager) {
      super(scope);
      this.isEmulated = true;
      this.registration = registration;
      this.fetchHandler = new FetchHandler(scope, config, persistenceManager);

      this.activateUrlMapping(); // called after fetchHandler assigned
    }

    /**
     * Load and install the given plugins. The plugins should be an array of urls.
     *
     * @param plugins an array of urls for the plugins to install
     * @param reload if true, force reload all the plugins, otherwise, only reload plugins whose metadata has changed
     * @returns {Promise}
     */
    installPlugins(plugins, reload = true) {
      return this.fetchHandler.installPlugins(plugins, reload);
    }

    /**
     * Uninstall currently installed plugins. This is used for testing purpose.
     *
     * @returns {*}
     */
    uninstallPlugins() {
      return this.fetchHandler.uninstallPlugins();
    }

    /**
     * Load and install the offline handler.
     *
     * @param handlerUrl the url from which to load the offline handler
     * @returns {Promise}
     */
    installOfflineHandler(handlerUrl) {
      return this.fetchHandler.installOfflineHandler({ offlineHandler: handlerUrl });
    }

    /**
     * Uninstall offline handler.
     *
     * @returns {Promise}
     */
    uninstallOfflineHandler() {
      return this.fetchHandler.uninstallOfflineHandler();
    }

    /**
     * Get the FetchHandler to perform a sync of cached offline data.
     *
     * @param options any options to be passed to the persistence sync manager
     * @returns {Promise}
     */
    syncOfflineData(options) {
      return this.fetchHandler.syncOfflineData(options);
    }

    /**
     * Get the FetchHandler to return the offline sync log.
     *
     * @returns {*}
     */
    getOfflineSyncLog() {
      return this.fetchHandler.getOfflineSyncLog();
    }

    /**
     * Get the FetchHandler to insert a new request into the offline sync log.
     *
     * @param request request to insert
     * @param options
     * @returns {*}
     */
    insertOfflineSyncRequest(request, options) {
      return this.fetchHandler.insertOfflineSyncRequest(request, options);
    }

    /**
     * Get the FetchHandler to delete a request from the offline sync log.
     *
     * @param requestId the id for the request to delete
     * @returns {*}
     */
    removeOfflineSyncRequest(requestId) {
      return this.fetchHandler.removeOfflineSyncRequest(requestId);
    }

    /**
     * Get the FetchHandler to update a request in the offline sync log.
     *
     * @param requestId the id for the request to update
     * @param request updated request
     * @returns {*}
     */
    updateOfflineSyncRequest(requestId, request) {
      return this.fetchHandler.updateOfflineSyncRequest(requestId, request);
    }

    /**
     * If the flag is true, inform the FetchHandler to force the PersistenceManager offline and vice versa.
     *
     * @param flag if true, force the PersistenceManager offline and vice versa
     * @returns {Promise}
     */
    forceOffline(flag) {
      return this.fetchHandler.forceOffline(flag);
    }

    /**
     * Set options on the offline handler.
     *
     * @param options options to be set on the offline handler
     * @returns {Promise}
     */
    setOfflineHandlerOptions(options) {
      return this.fetchHandler.setOfflineHandlerOptions(options);
    }

    /**
     * Ask the FetchHandler for its online status.
     *
     * @returns {Promise.<Boolean>}
     */
    isOnline() {
      return this.fetchHandler.isOnline();
    }

    /**
     * activate URL Mapping
     * @returns {Promise}
     */
    activateUrlMapping() {
      return Promise.resolve(this.fetchHandler.activateUrlMapping());
    }

    /**
     * This method is called by the PersistenceManager to handle intercepted requests.
     *
     * @param request the intercepted request
     * @returns {Promise}
     */
    handleRequest(request) {
      return this.fetchHandler.handleRequest(request);
    }
  }
  return EmulatedServiceWorkerWrapper;
});

