'use strict';

define('vb/private/debug/applicationDebugStream',['vb/private/debug/debugStream', 'vb/private/utils', 'vb/private/debug/constants', 'vb/private/configuration'],
  (DebugStream, Utils, DebugConstants, Configuration) => {
    /**
     * Debug stream for the application.
     */
    class ApplicationDebugStream extends DebugStream {
      constructor() {
        super(DebugConstants.DebuggeeType.APPLICATION);

        // a map of all the loaded descriptors
        this.loadedDescriptorMap = {};

        this.installDebuggeeMessageListener();
      }

      /**
       * Perform hand shake with the debugger. It performs the following:
       * 1. determine if the debugger is actually installed, if so, set vbInitConfig.debuggerInstalled to true
       * 2. register all the currently loaded JSON descriptors with the debugger.
       * @returns {Promise<any>}
       */
      handShake() {
        return this.invokeDebuggerMethod('handShake').then((debuggerInstalled) => {
          if (debuggerInstalled) {
            console.log('Debugger hand shake successful', debuggerInstalled);
            window.vbInitConfig.debuggerInstalled = true;

            // register with the debugger and send over the loaded descriptors without waiting
            this.registerDebuggee()
              .then(() => this.descriptorsLoaded(this.loadedDescriptorMap));
          }

          return debuggerInstalled;
        }).catch((err) => {
          // ignore error
        });
      }

      /**
       * Register with the debugger.
       *
       * @returns {Promise}
       */
      registerDebuggee() {
        // clone and sanitize window.vb before sending it over to the debugger
        const vb = DebugStream.cloneObject(window.vb);
        const vbInitConfig = DebugStream.cloneObject(window.vbInitConfig);

        const context = {
          vb,
          vbInitConfig,
          debuggerState: this.loadDebuggerState(),
        };

        return super.registerDebuggee(context);
      }

      /**
       * Getter for the debugger state storage id.
       *
       * @returns {string}
       */
      get debuggerStateStorageId() {
        // use the application url to create an unique storage id since the local storage is shared for a
        // given domain
        if (!this.storageId) {
          this.storageId = `vb-debugger-state-${Configuration.applicationUrl}`;
        }

        return this.storageId;
      }

      /**
       * Load the debugger state from local storage.
       *
       * @returns {Object}
       */
      loadDebuggerState() {
        const debuggerStateJsonStr = localStorage.getItem(this.debuggerStateStorageId);
        const debuggerState = debuggerStateJsonStr ? JSON.parse(debuggerStateJsonStr) : {};

        return debuggerState;
      }

      /**
       * Persist the given debugger state to local storage.
       *
       * @param debuggerState the debugger state to persist
       */
      storeDebuggerState(debuggerState) {
        localStorage.setItem(this.debuggerStateStorageId, JSON.stringify(debuggerState));
      }

      /**
       * Update the debugger state for the given locator which results in a write to local storage.
       *
       * @param locator locator for the debugger state
       * @param state new state
       */
      updateDebuggerState(locator, state) {
        const debuggerState = this.loadDebuggerState();

        debuggerState[locator] = state;

        this.storeDebuggerState(debuggerState);
      }

      /**
       * Register a single loaded descriptor.
       *
       * @param url url for the descriptor
       * @param descriptor loaded descriptor
       * @param container container that loaded the descriptor
       */
      descriptorLoaded(url, descriptor, container) {
        let descUrl;
        if (container.className === 'Page') {
          descUrl = `${url}-page.json`;
        } else {
          descUrl = `${url}-flow.json`;
        }

        this.loadedDescriptorMap[descUrl] = descriptor;

        // Because the container descriptor can be dynamically updated when a file-based chain is loaded,
        // we need to update the debugger with the updated descriptor.
        if (this.isDebuggerInstalled) {
          return this.descriptorsLoaded({
            [descUrl]: descriptor,
          });
        }

        return Promise.resolve();
      }

      /**
       * Register the loaded descriptors with the debugger.
       *
       * @param descriptors a map of descriptors indexed by their urls
       * @returns {Promise<any>}
       */
      descriptorsLoaded(descriptors) {
        return this.fireStateChanged(DebugConstants.DebugState.DESCRIPTORS_LOADED, descriptors);
      }
    }

    // return a singleton so it's visible application wide
    return new ApplicationDebugStream();
  });

