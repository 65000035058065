'use strict';

define('vb/private/types/multiServiceDataProvider',['vb/private/log',
  'vb/private/constants',
  'vb/helpers/mixin',
  'vb/private/types/dataProviders/multiServiceDataProviderUtils',
  'vb/private/types/builtinExtendedTypeMixin',
  'vb/types/eventTargetMixin',
  'vb/private/types/dataProviderConstants'],
(Log, Constants, Mixin, MultiSDPUtils, BuiltinExtendedTypeMixin, EventTargetMixin, DPConstants) => {
  const LOGGER = Log.getLogger('/vb/types/MultiServiceDataProvider');

  /* eslint class-methods-use-this: ["error", { "exceptMethods": ["getTypeDefinition"] }] */
  class MultiServiceDataProvider extends Mixin().with(EventTargetMixin, BuiltinExtendedTypeMixin) {
    constructor() {
      super();
      this.initialized = false;
      this.log = LOGGER;
      this.sdpRefsByType = undefined;
    }

    /**
     * Initializer.
     * @param id
     * @param variableDef the declarative definition of this variable
     * @param value the defaultValue is provided here. At this point the initialValue has not
     * been fully determined.
     * @constructor
     */
    init(id, variableDef, value) {
      super.init(id, variableDef, value);
      /**
       * the variable definition
       * @private
       */
      this.definition = variableDef;
    }

    activate() {
      const { dataProviders } = this.getValue();

      // based on the data providers defined, set up the fetch methods correctly.
      if (dataProviders && MultiSDPUtils.isConfigurationValid(dataProviders)) {
        // now that we have some dataProviders set, setup fetchXYZ() methods based on the mapping.
        // always setup a fetchFirst data provider.
        if (dataProviders.fetchFirst && this.fetchFirst === undefined) {
          MultiSDPUtils.initFetchFirstMethods(this);
        }

        // when dataProviders.fetchByKeys is configured the fetchByKeys and containsByKeys are
        // wired up on the MultiServiceDataProvider.
        if (dataProviders.fetchByKeys && this.fetchByKeys === undefined) {
          MultiSDPUtils.initFetchByKeysMethods(this);
        }

        if (dataProviders.fetchByOffset && this.fetchByOffset === undefined) {
          MultiSDPUtils.initFetchByOffsetMethods(this);
        }
      } else {
        this.log.error('MultiServiceDataProvider', this.id, 'has no dataProviders set!', this.state);
      }
    }

    dispose() {
      // remove all event listeners registered with underlying SDPs that is used for DataProvider events
      this.removeAllEventListeners();
      // remove variable lifecycle changed event listeners
      let sdp = this.getDataProviderByType(DPConstants.DataProviderFetchType.FETCH_FIRST);
      if (sdp) {
        sdp.getLifecycleStageChangedSignal().removeAll();
      }
      sdp = this.getDataProviderByType(DPConstants.DataProviderFetchType.FETCH_BY_KEYS);
      if (sdp) {
        sdp.getLifecycleStageChangedSignal().removeAll();
      }
      sdp = this.getDataProviderByType(DPConstants.DataProviderFetchType.FETCH_BY_OFFSET);
      if (sdp) {
        sdp.getLifecycleStageChangedSignal().removeAll();
      }
    }

    getDataProviderByType(fetchType) {
      return this.sdpRefsByType ? this.sdpRefsByType[fetchType] : MultiSDPUtils.getDataProviderByType(this, fetchType);
    }

    getVariableStageChangeCallback(sdpType) {
      return (lifecycleStage, sdpWrapper) => this
        .handleVariableLifecycleStageChange(lifecycleStage, sdpType, sdpWrapper);
    }

    /**
     * Returns the configured value in the variable definition.
     */
    getDefinitionValue() {
      return this.definition.defaultValue;
    }

    /**
     * returns a Set of SDP references currently used by this MDP.
     * @return {Set}
     * @private
     */
    getUniqueSDPReferences() {
      const value = this.getValue();
      // see BUFP-32300: when removeListener is called by component, MDP value is already gone!!
      if (!this.sdpRefsByType && value) {
        this.sdpRefsByType = MultiSDPUtils.getSDPReferencesByType(this);
      }

      return new Set(Object.values(this.sdpRefsByType || {}));
    }

    /**
     * handle lifecycle stage change by using the provided SDP instance.
     * @param stage
     * @param sdpType
     * @param sdp the sdp instance to use for the specific variable stage we are in
     */
    handleVariableLifecycleStageChange(stage, sdpType, sdp) {
      if (stage === Constants.VariableLifecycleStage.DISPOSE) {
        this.sdpRefsByType = this.sdpRefsByType || {};
        // when a referenced SDP is disposed all listeners attached to it would have been removed
        this.sdpRefsByType[sdpType] = sdp;
      }
    }

    /**
     * When subscribers register a listener on MDP this needs to be directly registered with the
     * underlying SDP for every supported capability. This allows components to be notified when
     * the underlying SDP fires a DP event
     * @param eventType
     * @param listener
     */
    addEventListener(eventType, listener) {
      const sdpRefs = this.getUniqueSDPReferences();

      // for each unique SDP reference register the listener for the DataProviderEvent
      // on the referenced SDP
      sdpRefs.forEach((dp) => {
        dp.addEventListener(eventType, listener);
      });
    }

    /**
     * When subscribers remove a listener on MDP this needs to be directly removed on the
     * underlying SDP for every supported capability.
     * @param eventType
     * @param listener
     */
    removeEventListener(eventType, listener) {
      const sdpRefs = this.getUniqueSDPReferences();
      if (sdpRefs.size === 0) {
        this.log.warn('MultiServiceDataProvider', this.id, 'unable to remove listeners'
          + ' registered with dataProvider because the value appears to have been cleared');
      }
      sdpRefs.forEach((dp) => {
        dp.removeEventListener(eventType, listener);
      });
    }

    /**
     * removes all listeners this MDP sets up on the underlying SDP for every supported capability. Usually this is
     * called when the variable is disposed.
     */
    removeAllEventListeners() {
      const sdpRefs = this.getUniqueSDPReferences();

      sdpRefs.forEach((dp) => {
        dp.removeAllEventListeners();
      });
    }

    /**
     * Simple pass-through for handling events raised by referenced SDP. This method dispatches
     * the same event to listeners registered with the MDP
     * @param event
     * @return {boolean}
     */
    handleDataProviderEvent(event) {
      try {
        this.dispatchEvent(event);
      } catch (e) {
        const err = `unable to process event ${event.type} for data provider due to error ${e}`;
        this.log.error(err);
        throw (err);
      }
      return false;
    }

    /**
     *
     * @returns {*}
     */
    getTypeDefinition() {
      return {
        type: {
          dataProviders: {
            fetchFirst: 'vb/ServiceDataProvider',
            fetchByKeys: 'vb/ServiceDataProvider',
            fetchByOffset: 'vb/ServiceDataProvider',
          },
        },
        resolved: true,
      };
    }
  }

  return MultiServiceDataProvider;
});

