/* global cordova:false */

'use strict';

/**
 * Routines for basic auth security protocols and for securely storing sensitive information.
 */
define('vb/private/mobile/security/strategyBasicAuthIdm',[
  'vb/private/mobile/security/constants',
  'vb/private/mobile/db',
  'vb/private/mobile/security/strategy',
  'vb/private/log'],
(SecurityConstants, Db, Strategy, Log) => {
  class StrategyBasicAuthIdm extends Strategy {
    constructor(securityMetadata) {
      super(securityMetadata);

      this.log = Log.getLogger('vb/private/mobile/security/strategyBasicAuthIdm');

      this.loginUrl = securityMetadata.login;
      this.logoutUrl = securityMetadata.logout;
      this.headers = securityMetadata.headers || {};
      this.loginWindow = null;
      this.shouldRememberUsername = null;
    }

    /**
     * Initializes this security strategy.
     *
     * @param props An object containing configuration for authentication
     * @return promise Resolves when complete
     */
    init(props = null) {
      const challengeCallback = (fields, proceedHandler) => {
        const challengeFields = fields;
        const challengeProceedHandler = proceedHandler;
        let loadstartUrl = null;
        const eventHandlerCallback = (event) => {
          const vbcsLogin = 'vbcs-login:';
          const indexLoc = event.url.indexOf(vbcsLogin);

          if (indexLoc > 0) {
            if (event.type === 'loadstart') {
              loadstartUrl = event.url;
            } else if (event.type === 'loadstop' && event.url === loadstartUrl) {
              loadstartUrl = null;
              return;
            }

            // event.url looks something like this:
            // loginUrl#vbcs-login:action,username,encodedPassword,shouldUsernameBeRemembered
            // the first param specifies what the action is, i.e., either login or cancel
            // the second param is username
            // the third param is encoded user provided password
            // the fourth param indicates whether the username should be remembered
            const commands = event.url.substring(indexLoc + vbcsLogin.length);
            const parts = commands.split(',');

            // attempting to login
            if (parts[0] === 'login') {
              Db.setAppPreference(`vbcs:app:${this.appName}:rememberUsername`, parts[3] === 'true')
                .catch((e) => {
                  this.log.error(e);
                })
                .then(() => {
                  challengeFields.username_key = parts[1];
                  challengeFields.password_key = window.atob(parts[2]);

                  // un-register the listeners as they will be registered again if login fails
                  // as the challengeCallback will be invoked again by the IDM plugin in this case
                  if (this.loginWindow) {
                    this.loginWindow.removeEventListener('loadstart', eventHandlerCallback);
                    this.loginWindow.removeEventListener('loadstop', eventHandlerCallback);
                  }

                  challengeProceedHandler(challengeFields);
                });
            } else if (parts[0] === 'cancel') {
              if (this.loginWindow) {
                this.loginWindow.close();
              }
            }
          }
        };

        if (challengeFields.error && this.loginWindow) {
          let errorMessage = SecurityConstants.lookupErrorCode(challengeFields.error);
          if (!errorMessage) {
            errorMessage = 'Login was not successful. Please try again.';
          }

          const invokeErrorFunction = `error('${errorMessage}')`;
          this.loginWindow.executeScript({ code: invokeErrorFunction });
          // re-add the listeners back, since we get here in case of errors and
          // the event listeners have alrady been removed in the eventHandlerCallback
          this.loginWindow.addEventListener('loadstart', eventHandlerCallback);
          this.loginWindow.addEventListener('loadstop', eventHandlerCallback);
          return;
        }

        let savedUsername = '';
        if (this.shouldRememberUsername) {
          savedUsername = challengeFields.username_key;

          if (!savedUsername) {
            savedUsername = '';
          }
        } else {
          this.shouldRememberUsername = false;
        }

        const defaultLoginHtmlPath =
          `${cordova.file.applicationDirectory}www/vb/package/mobile/basicAuth/login/default.login.html`;
        const loginUrl =
          `${defaultLoginHtmlPath}?savedUsername=${savedUsername}&rememberUsername=${this.shouldRememberUsername}`;
        this.loginWindow = cordova.InAppBrowser.open(loginUrl, '_blank', 'toolbar=no,location=no');

        // capture the submit
        this.loginWindow.addEventListener('loadstart', eventHandlerCallback);
        this.loginWindow.addEventListener('loadstop', eventHandlerCallback);
      };

      const authProps = props || new this.idmAuthFlows.HttpBasicAuthPropertiesBuilder()
        .appName(this.appName)
        .loginUrl(this.loginUrl)
        .logoutUrl(this.logoutUrl)
        .challengeCallback(challengeCallback)
        .maxLoginAttempts(3)
        .offlineAuthAllowed(true)
        .rememberUsernameAllowed(true)
        .rememberCredentialsAllowed(true)
        .rememberCredentialDefault(true)
        .autoLoginDefault(true)
        .autoLoginAllowed(true)
        .customAuthHeaders(this.headers)
        .put('AuthKey', this.authKey)
        .build();

      return super.init(authProps);
    }

    /**
     * Shows the login screen for the given application. After a successful login, the user will
     * be taken to the app.
     *
     * @return promise resolves if the login was successful, rejects otherwise
     */
    loginToApplication() {
      // The whole login flow is as follows and (challengeCallback is set during init):
      // 1. login is invoked
      // 2. challengeCallback gets invoked and user login page is displayed
      // 3. User enters credentials
      // 4. challengeProceedHandler is called with user input
      // 5. If user credentials are wrong, then challengeCallback is called again if max attempts haven't been reached
      // 6. go back to #2 and follow through from there, so each time challengeCallback is called, app should hold on
      //    to the new challengeProceedHandler and use that
      // 7. If max attempts is reached, login promise is rejected
      // 8. In this case, app will start a fresh login from #1

      if (!this.authenticationFlow) {
        return Promise.reject(new Error('init must be invoked prior to calling loginToApplication'));
      }

      return Db.getAppPreference(`vbcs:app:${this.appName}:rememberUsername`).then((rememberUsername) => {
        this.loginWindow = null;
        this.shouldRememberUsername = rememberUsername;

        return this.authenticationFlow.login()
          .then((loginResponse) => {
            if (!loginResponse) {
              throw new Error('Failed to login into application');
            }
          })
          .catch((err) => {
            // until we can use the finally block, unfortunately have to ensure the login window is closed
            // in case of errors:
            if (this.loginWindow) {
              this.loginWindow.close();
            }

            const message = SecurityConstants.lookupErrorCode(err);
            throw (message ? new Error(message) : err);
          })
          .then(() => {
            if (this.loginWindow) {
              this.loginWindow.close();
            }
          });
      });
    }

    /**
     * Returns headers required to make a secure call with this protocol.
     *
     * @return promise a map of required headers
     */
    getHeaders() {
      if (!this.authenticationFlow) {
        return Promise.reject(new Error('init must be invoked prior to calling getHeaders'));
      }

      return this.authenticationFlow.getHeaders()
        .then((headers) => headers || {})
        .catch((err) => {
          throw new Error(SecurityConstants.lookupErrorCode(err));
        });
    }
  }

  return StrategyBasicAuthIdm;
});

