/* eslint-disable no-underscore-dangle */

'use strict';

/**
 * optCacheStrategies is overriding the default JET's cache strategy for a very specific case only, so
 * that in the native mobile case the current user information can be stored and cached.  Currently,
 * the IDCS API used for retrieving the user information, and Digest API used for extension registry,
 * has the no store / no cache headers and the default JET's cache strategy doesn't cache the needed info as a result.
 */
define('vbsw/private/optCacheStrategies',[
  'vbsw/private/utils',
  'vbc/private/log',
], (Utils, Log) => {
  const logger = Log.getLogger('/vbsw/private/mobileCacheStrategies');

  function _handleExpires(request, response, PersistenceUtils) {
    return Promise.resolve().then(() => {
      // expires header - Contains a UTC Datetime value
      // which can be used to directly populate x-oracle-jscpt-cache-expiration-date
      // if x-oracle-jscpt-cache-expiration-date is already populated then that wins
      const expiresDate = response.headers.get('Expires');
      const cacheExpirationDate = response.headers.get('x-oracle-jscpt-cache-expiration-date');

      if (expiresDate
        && PersistenceUtils.isCachedResponse(response)
        && (!cacheExpirationDate || cacheExpirationDate.length === 0)) {
        response.headers.set('x-oracle-jscpt-cache-expiration-date', expiresDate);
        logger.info('Set x-oracle-jscpt-cache-expiration-date header based on HTTP Expires header');
      }

      return response;
    });
  }

  function _getCacheControlDirective(headers, directive) {
    // Retrieve the Cache-Control headers and parse
    const cacheControl = headers.get('Cache-Control');

    if (cacheControl) {
      const cacheControlValues = cacheControl.split(',');

      let i;
      let cacheControlVal;
      let splitVal;
      for (i = 0; i < cacheControlValues.length; i += 1) {
        cacheControlVal = cacheControlValues[i].trim();
        // we only care about cache-control values which start with the directive
        if (cacheControlVal.indexOf(directive) === 0) {
          splitVal = cacheControlVal.split('=');
          return (splitVal.length > 1) ? splitVal[1].trim() : true;
        }
      }
    }

    return null;
  }

  function _handleMaxAge(request, response, PersistenceUtils) {
    return Promise.resolve().then(() => {
      // max-age cache header - Use it to calculate and populate cacheExpirationDate.
      // Takes precedence over Expires so should be called after processing Expires.
      // Also, unlike  Expires it's relative to the Date of the request
      const cacheControlMaxAge = _getCacheControlDirective(response.headers, 'max-age');

      if (cacheControlMaxAge != null) {
        if (PersistenceUtils.isCachedResponse(response)) {
          let requestDate = request.headers.get('Date');
          if (!requestDate) {
            requestDate = (new Date()).toUTCString();
          }
          const requestTime = (new Date(requestDate)).getTime();
          const expirationTime = requestTime + (1000 * cacheControlMaxAge);
          const expirationDate = new Date(expirationTime);
          response.headers.set('x-oracle-jscpt-cache-expiration-date', expirationDate.toUTCString());
          logger.info('Set x-oracle-jscpt-cache-expiration-date header based on HTTP max-age header');
        }
      }
      return response;
    });
  }

  function _handleRevalidate(request, response, mustRevalidate, PersistenceUtils, PersistenceManager) {
    return Promise.resolve().then(() => {
      // If we are offline, we can't revalidate so just return the cached response
      // unless mustRevalidate is true, in which case reject with error.
      // If we are online then if the response is a cached Response, we need to
      // revalidate. If the revalidation returns 304 then we can just return the cached version
      // handleRevalidate can be called multiple times due to different cache
      // headers, requiring it however a server call, if needed, only be made
      // once because after that we will have a server response and any subsequent
      // handleRevalidate calls will just resolve.
      if (PersistenceUtils.isCachedResponse(response)) {
        if (!PersistenceManager.isOnline()) {
          // If we must revalidate then we MUST return a 504 when offline
          // https://www.w3.org/Protocols/rfc2616/rfc2616-sec14.html#sec14.9.4
          // must-revalidate is the only case under which we CANNOT return a stale
          // response. If must-revalidate is not set then according to spec it's ok
          // to return a stale response.
          if (mustRevalidate) {
            return PersistenceUtils.responseToJSON(response).then((responseData) => {
              // eslint-disable-next-line no-param-reassign
              responseData.status = 504;
              // eslint-disable-next-line no-param-reassign
              responseData.statusText = 'cache-control: must-revalidate failed due to application being offline';
              logger.info('Returning Response status 504 based HTTP revalidation');
              return PersistenceUtils.responseFromJSON(responseData);
            });
          }

          return response;
        }

        return PersistenceManager.browserFetch(request).then((serverResponse) => {
          if (serverResponse.status === 304) {
            return response;
          }

          // revalidation succeeded so we should remove the old entry from the cache
          return PersistenceManager.getCache().delete(request).then(() => {
            logger.info('Removing old entry based on HTTP revalidation');
            return serverResponse;
          });
        });
      }

      // If it's not a cached Response then it's already from the server so just resolve the response
      return response;
    });
  }

  function _handleIfCondMatch(request, response, PersistenceUtils, PersistenceManager) {
    return Promise.resolve().then(() => {
      // If-Match or If-None-Match headers
      const ifMatch = request.headers.get('If-Match');
      const ifNoneMatch = request.headers.get('If-None-Match');

      if (ifMatch || ifNoneMatch) {
        if (!PersistenceManager.isOnline()) {
          const etag = response.headers.get('ETag');

          if (ifMatch && etag.indexOf(ifMatch) < 0) {
            // If we are offline then we MUST return 412 if no match as per spec
            return PersistenceUtils.responseToJSON(response).then((responseData) => {
              // eslint-disable-next-line no-param-reassign
              responseData.status = 412;
              // eslint-disable-next-line no-param-reassign
              responseData.statusText = 'If-Match failed due to no matching ETag while offline';
              logger.info('Returning Response status 412 based on ETag and HTTP If-Match header');
              return PersistenceUtils.responseFromJSON(responseData);
            });
          }
          if (ifNoneMatch && etag.indexOf(ifNoneMatch) >= 0) {
            // If we are offline then we MUST return 412 if match as per spec
            return PersistenceUtils.responseToJSON(response).then((responseData) => {
              // eslint-disable-next-line no-param-reassign
              responseData.status = 412;
              // eslint-disable-next-line no-param-reassign
              responseData.statusText = 'If-None-Match failed due to matching ETag while offline';
              logger.info('Returning Response status 412 based on ETag and HTTP If-None-Match header');
              return PersistenceUtils.responseFromJSON(responseData);
            });
          }
        } else {
          // If we are online then we have to revalidate
          return _handleRevalidate(request, response, false, PersistenceUtils, PersistenceManager);
        }
      }
      return response;
    });
  }

  function _handleMustRevalidate(request, response, PersistenceUtils, PersistenceManager) {
    return Promise.resolve().then(() => {
      // must-revalidate MUST revalidate stale info. If we're offline or
      // server cannot be reached then client MUST return a 504 error:
      // https://www.w3.org/Protocols/rfc2616/rfc2616-sec14.html
      const mustRevalidate = _getCacheControlDirective(response.headers, 'must-revalidate');
      if (mustRevalidate) {
        const cacheExpirationDate = response.headers.get('x-oracle-jscpt-cache-expiration-date');
        if (cacheExpirationDate) {
          const cacheExpirationTime = (new Date(cacheExpirationDate)).getTime();
          const currentTime = (new Date()).getTime();

          if (currentTime > cacheExpirationTime) {
            logger.info('Handling revalidation HTTP must-revalidate header');
            return _handleRevalidate(request, response, true, PersistenceUtils, PersistenceManager);
          }
        }
      }
      return response;
    });
  }

  function _cacheResponse(request, response, PersistenceUtils, PersistenceManager) {
    return Promise.resolve().then(() => {
      // persist the Request/Response in our cache
      if (response != null
        && !PersistenceUtils.isCachedResponse(response)
        && (request.method === 'GET' || request.method === 'HEAD')) {
        const responseClone = response.clone();
        return PersistenceManager.getCache().put(request, response).then(() => {
          logger.info('Cached Request/Response');
          return responseClone;
        });
      }
      return response;
    });
  }

  /**
   * Returns the HTTP Cache Header strategy.  The cache strategy is applied to GET/HEAD requests by the
   * defaultResponseProxy right after the fetch strategy is applied and the response is obtained. The cache
   * strategy should be a function which takes the request and response as parameter and returns a Promise
   * which resolves to the response. In the case the fetch strategy returns a server response.  The cache
   * strategy should determine whether the request/response should be cached and if so, persist the
   * request/response by calling persistenceManager.getCache().put(). The cache strategy should also handle
   * cached responses returned by the fetch strategy.
   * @method
   * @name getHttpCacheHeaderStrategy
   * @memberof cacheStrategies
   * @static
   * @return {Function} Returns the HTTP Cache Header strategy, which conforms to the Cache Strategy API.
   */
  function getHttpCacheHeaderStrategy() {
    return (request, response) => Utils.getResources(['persist/persistenceManager', 'persist/persistenceUtils'])
      .then((modules) => {
        const [PersistenceManager, PersistenceUtils] = modules;
        // process the headers in order. Order matters, you want to
        // do things like re-validation before you bother persist to
        // the cache. Also, max-age takes precedence over Expires.
        return _handleExpires(request, response, PersistenceUtils)
          .then((r) => _handleMaxAge(request, r, PersistenceUtils))
          .then((r) => _handleIfCondMatch(request, r, PersistenceUtils, PersistenceManager))
          .then((r) => _handleMustRevalidate(request, r, PersistenceUtils, PersistenceManager))
          .then((r) => _cacheResponse(request, r, PersistenceUtils, PersistenceManager));
      });
  }

  return { getHttpCacheHeaderStrategy };
});

