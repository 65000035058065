/* eslint-disable max-classes-per-file */

'use strict';

define('vb/private/services/readers/openApi3Object',[
  'vb/private/log',
  './openApiObjectCommon',
  './openApiUtils',
  'vb/private/services/serviceUtils',
  'vb/private/services/swaggerUtils',
  'vb/private/services/uriTemplate',
], (Log, OpenApiObjectCommon,
  OpenApiUtils, ServiceUtils, SwaggerUtils, UriTemplate) => {
  const logger = Log.getLogger('/vb/private/services/readers/openApi3Object');

  // https://swagger.io/specification/#openapi-object
  // If the servers property is not provided, or is an empty array,
  // the default value would be a Server with a url value of '/'.
  const DEFAULT_SERVER_URL = '/';

  /**
   * local utility, find the very first entry name/value pair.
   * Used as fallback for finding a response.
   * @param obj
   * @returns {*}
   */
  function anyProperty(obj) {
    const entries = Object.entries(obj);
    return (entries.length) ? { name: entries[0][0], value: entries[0][1] } : { name: null, value: {} };
  }
  /**
   *
   */
  class OpenApi3OperationObject extends OpenApiObjectCommon.OperationObject {
    /**
     * abstract and simplify Request Body Object (or $ref) 'content'
     * @returns {Array}
     * @override
     */
    getResponseContentTypes() {
      // we don't use anything in the definition, we just want a list of media types (for now)
      const responses = this.responses || {};
      let response = responses.default || responses['200'] || anyProperty(responses).value;
      response = SwaggerUtils.resolveReferences(this.openApi.definition, null, response);

      return Object.keys(response.content || {});
    }

    /**
     * abstract and simplify Response Object (or $ref) 'content'
     * https://github.com/OAI/OpenAPI-Specification/blob/master/versions/3.0.0.md#responseObject
     * @returns {Array}
     * @override
     */
    getRequestContentTypes() {
      let requestBody = this.requestBody || this.openApi.requestBody || {};

      requestBody = SwaggerUtils.resolveReferences(this.openApi.definition, null, requestBody);
      // we don't use anything in the definition, we just want a list of media types (for now)
      return Object.keys(requestBody.content || {});
    }
  }

  /**
   *
   */
  class OpenApi3Object extends OpenApiObjectCommon {
    constructor(definition, context = {}) {
      super(definition);
      this.initParams = context.initParams || {};
      this.definitionUrl = context.definitionUrl;
    }

    /**
     * abstract OpenAPI 3.0 Server Objects
     * https://github.com/OAI/OpenAPI-Specification/blob/master/versions/3.0.0.md#serverObject
     * @returns {Array|*}
     */
    getServers() {
      if (!this._servers) {
        const servers = this.definition.servers || [];
        this._servers = servers.map((server) => {
          const newServer = Object.assign({}, server);
          delete newServer.url;

          // Optimization: check if there is a server variable
          if (server.url && server.url.includes('{')) {
            newServer.getUrl = (serverVariables) => {
              const serverUrlInfo = this._replaceServerVariables(server, serverVariables);
              if (serverVariables && serverVariables[Symbol.for('RestHelper.addServerUrlInfo')]) {
                serverVariables[Symbol.for('RestHelper.addServerUrlInfo')](serverUrlInfo);
              }
              return serverUrlInfo.url;
            };
          } else {
            const url = this.definitionUrl
              ? ServiceUtils.toAbsoluteServerUrl(this.definitionUrl, server.url)
              : server.url;
            newServer.getUrl = () => url;
          }

          return newServer;
        });
      }
      return this._servers;
    }

    /**
     * return Server Object for the chosen profile
     * @param profile
     * @return {Object} Server Object
     */
    getServerForProfile(profile) {
      // VBS-18849: handle the case of missing servers and properly default it to  "/"
      const servers = this.getServers();
      return (servers && servers.length)
        ? OpenApiUtils.findServerForProfile(servers, profile)
        : this.createDefaultServer();
    }

    /**
     * Provides default value for the server.
     * If the servers property is not provided, or is an empty array,
     * the default value would be a Server with a url value of '/'.
     */
    createDefaultServer() {
      const url = this.definitionUrl
        ? ServiceUtils.toAbsoluteServerUrl(this.definitionUrl, DEFAULT_SERVER_URL)
        : DEFAULT_SERVER_URL;
      return {
        getUrl: () => url,
      };
    }

    /**
     * factory method for v3 Operation Object
     * @param name
     * @param pathObject
     * @param operationObjectKey
     * @returns {OpenApi3OperationObject}
     * @override
     */
    createOperationObjectObject(name, pathObject, operationObjectKey) {
      const operationObjectDefinition = pathObject[operationObjectKey];
      return new OpenApi3OperationObject(name, pathObject, operationObjectDefinition, this);
    }

    /**
     * for a Server Object, replace path templates with Server Variable Object 'default's
     * https://github.com/OAI/OpenAPI-Specification/blob/master/versions/3.0.0.md#serverVariableObject
     * and returns the replaced server URL.
     *
     * @param server
     * @param serverVariables optional, server variables declared when invoking a service endpoint.
     * {{url: string, template: string, variables: object}}
     */
    _replaceServerVariables(server, serverVariables) {
      const variables = {};

      // first gather the default values as defined on the OpenAPI definition
      const vars = server.variables;
      if (vars) {
        Object.keys(vars).forEach((name) => {
          variables[name] = vars[name].default;
        });
      }

      // now replace the default values by "defined, not-null" values that may have been passed via initParams
      const initParams = this.initParams;
      if (initParams) {
        Object.keys(initParams).forEach((name) => {
          const value = initParams[name];
          if (value !== undefined && value !== null) {
            variables[name] = value;
          }
        });
      }

      // now replace the collected values by valid values that may have been passed via fetchParams
      if (serverVariables) {
        let message = '';
        Object.keys(serverVariables).forEach((variable) => {
          const value = serverVariables[variable];
          if (SwaggerUtils.isValidServerVariableValue(value, server, variable)) {
            message += `\n  '${variable}': '${value}'`;
            variables[variable] = value;
          }
        });

        if (message) {
          logger.info(
            'The server url of the service located at',
            this.definitionUrl || '<unknown>',
            'has been modified by the following request-specific variables:',
            message,
          );
        }
      }

      const template = server.url || '';
      const uriTemplate = new UriTemplate(template, {}, true); // don't add extras
      let url = uriTemplate.replace(variables);
      if (this.definitionUrl) {
        url = ServiceUtils.toAbsoluteServerUrl(this.definitionUrl, url);
      }
      return { url, template, variables };
    }
  }

  OpenApi3Object.OpenApi3OperationObject = OpenApi3OperationObject;

  return OpenApi3Object;
});

