'use strict';

define('vb/private/stateManagement/context/applicationContext',['vb/private/constants',
  'vb/private/stateManagement/context/flowContext',
  'vb/private/stateManagement/context/applicationBaseContext',
  'vb/private/ui/responsiveUtils'],
(Constants, FlowContext, ApplicationBaseContext, ResponsiveUtils) => {
  /** @type Object */
  const symbol = Symbol('application-accessor');

  // lazy-initialization of JET responsive query observables;
  // only create the observables if someone uses $application.responsive
  let responsiveUtilsInterface = null;

  class ApplicationContext extends FlowContext {
    /**
     * @param application
     */
    constructor(application) {
      super(application);

      Object.defineProperty(this, symbol,
        {
          value: {
            get initParams() {
              return application.initParams;
            },
            get builtinUtils() {
              return application.builtinUtils;
            },
            /**
             * Shortcut to retrieve the builtins variable map
             */
            get builtins() {
              return application.scope.variableNamespaces[Constants.VariableNamespace.BUILTIN];
            },
          },
        // translations: null,
        });
    }

    static get BaseContextType() {
      return ApplicationBaseContext;
    }

    /**
     * $application.user
     * @returns {*}
     */
    get [Constants.APPLICATION_USER_VARIABLE]() {
      return this[symbol].builtins[Constants.APPLICATION_USER_VARIABLE];
    }

    /**
     * $global.currentAppUi
     */
    get [Constants.GLOBAL_CURRENT_APPUI_VARIABLE]() {
      return this[symbol].builtins[Constants.GLOBAL_CURRENT_APPUI_VARIABLE];
    }

    /**
     * Accessor for the application builtin utils
     * @return {Object}
     */
    get builtinUtils() {
      return this[symbol].builtinUtils;
    }

    /**
     * $application.responsive.XXXX, eg. responsive.SM_UP
     * @returns {*}
     */
    // eslint-disable-next-line class-methods-use-this
    get [Constants.RESPONSIVE_CONTEXT]() {
      if (!responsiveUtilsInterface) {
        responsiveUtilsInterface = ResponsiveUtils.getResponsiveObservablesInterface();
      }
      return responsiveUtilsInterface;
    }

    /**
     * $application.profile, the active app profile
     * @returns {*}
     */
    get [Constants.PROFILE_CONSTANT]() {
      return this[symbol].builtins[Constants.PROFILE_CONSTANT];
    }

    /**
     * $application.deployment
     * @returns {*}
     */
    get [Constants.DEPLOYMENT_CONSTANT]() {
      return this[symbol].builtins[Constants.DEPLOYMENT_CONSTANT];
    }

    /**
     * $application.initParams.XXXX. comes from window.vbInitParams or "configuration" declaration.
     * @todo: remove support for window.vbInitParams
     *
     * @returns {*}
     */
    get [Constants.INIT_PARAM_CONTEXT]() {
      return this[symbol].initParams;
    }

    /**
     * get all dollar-vars available to expression binding
     * @param application
     * @returns {*}
     */
    static getAvailableContexts(application) {
      const availableContexts = super.getAvailableContexts(application);

      // Delete the $flow not needed for $application. Also this is not to avoid a conflict
      // between $application.currentPage and @flow.currentPage which are of different type.
      delete availableContexts.$flow;

      // defines additional properties, $builtinUtils and $initParams
      Object.defineProperties(availableContexts, {
        $builtinUtils: {
          enumerable: true,
          configurable: true,
          get: () => application.expressionContext.builtinUtils,
        },
        [Constants.ContextName.INIT_PARAMS]: {
          enumerable: true,
          configurable: true,
          get() {
            return application.expressionContext.initParams;
          },
        },
      });

      return availableContexts;
    }
  }

  return ApplicationContext;
});

