'use strict';

define('vb/private/services/definition/openApiServiceDefFactory',[
  'urijs/URI',
  'vb/private/configLoader',
  'vb/private/log',
  'vb/private/utils',
  'vb/private/services/servicesLoader',
  'vb/private/services/serviceUtils',
  'vb/private/services/definition/serviceDefinition',
  'vb/private/services/definition/serviceDefFactory',
  'vb/private/services/readers/openApiObjectFactory',
], (
  URI,
  ConfigLoader,
  Log,
  Utils,
  ServicesLoader,
  ServiceUtils,
  ServiceDefinition,
  ServiceDefFactory,
  OpenApiObjectFactory,
) => {
  const logger = Log.getLogger('/vb/private/services/definition/OpenApiServiceDefFactory');

  /**
   * Abstract class providing implementation layer for ServiceDefinition factories to load
   * the definitions from OpenApi resoirces.
   *
   * @abstract
  */
  class OpenApiServiceDefFactory extends ServiceDefFactory {
    constructor(services, options) {
      super(services);
      const {
        relativePath,
        isUnrestrictedRelative,
        protocolRegistry,
      } = options;

      this._protocolRegistry = protocolRegistry;
      this._isUnrestrictedRelative = !!isUnrestrictedRelative;
      this._relativePath = relativePath || '';
    }

    /**
     * @returns {boolean}
     * @override
     */
    // eslint-disable-next-line class-methods-use-this
    supports(endpointReference) {
      return !endpointReference.isProgrammatic;
    }

    /**
     *
     * @param {EndpointReference} endpointReference
     * @param {Object} declaration Service declaration
     * @param {Object} [serverVariables]
     * @returns {Promise<ServiceDefinition>}
     * @override
     */
    loadDefinition(endpointReference, declaration, serverVariables) {
      return Promise.resolve()
        .then(() => {
          if (typeof declaration === 'string') {
            // eslint-disable-next-line no-param-reassign
            declaration = { path: declaration };
          }
          const serviceName = endpointReference.serviceName;

          // declaration will be { path: '...', <headers: {...}> }

          // path might be an expression, evaluate it
          const fileName = this.services.evaluatePathDeclaration(declaration.path);

          // allow for an alternate 'proxyName' as an internal workaround for when the declaration name
          // does not match the name of the service in the proxy url.

          if (this.services.isPathAllowed(fileName)) {
            return this.loadServiceFromPath(serviceName, fileName, declaration.headers, serverVariables);
          }
          return null;
        });
    }

    //------------------------------------------------------------------------------

    /**
     * Clients should not invoke this method directly.
     *
     * @param {string} serviceName
     * @param {string} path
     * @param {object} [declaredHeaders] will be merged with the catalog headers, if any (catalog takes precedence)
     * @param {object} [serverVariables] the serverVariables, if any.
     * @protected
     */
    loadServiceFromPath(serviceName, path, declaredHeaders, serverVariables) {
      // offset by the location of the container
      let catalogInfo;
      let requestInit;
      let fileName;

      return Promise.resolve()
        .then(() => {
          fileName = this.services.getDefinitionPath(path);

          return ServicesLoader
            .getCatalogExtensions(
              this._protocolRegistry,
              fileName,
              serviceName,
              this.services.namespace,
              serverVariables,
              declaredHeaders,
            );
        })
        .then((catInfo) => {
          catalogInfo = catInfo;

          // used by Services, and merges with "backends" extensions in the Endpoint
          // services.extensions only contains headers currently, so we can use in Request construction directly
          requestInit = (catalogInfo.services && catalogInfo.services.extensions);

          const metadata = (catalogInfo.services && catalogInfo.services.metadata);

          // TODO: if metadata.extensiosn has serviceType check if we have programatic endpoint handler,
          // and avoid loading/fetching OpenApi object
          if (metadata) {
            // if the catalog has a "paths" to declare how to fetch the openapi3, use that
            return OpenApiServiceDefFactory
              .getOpenApiObjectUsingMetadata(serviceName, catalogInfo.url, metadata, requestInit);
          }
          return OpenApiServiceDefFactory.getOpenApiObject(serviceName, catalogInfo.url, requestInit);
        })
        .then((openApi) => this.createServiceDefinition(
          serviceName,
          fileName,
          catalogInfo,
          openApi,
          requestInit,
          serverVariables,
        ))
        .catch((e) => {
          logger.error('service load error: ', e);
          // throw e;
          return null; // allow the rest of the loads to pass
        });
    }

    /**
     * construct a ServiceDefinition
     * @param {string} serviceName name of the service; the property name from the app-flow.json declaration
     * @param {string} fileName service metadata (openapi/swagger) path
     * @param catalogInfo services/backends information from any vb-catalog references
     * @param openApi service metadata object (OpenApiCommonObject)
     * @param {Object} requestInit additional config for a Request object (headers, etc).
     * @param {object} [serverVariables] - the serverVariables, if any.
     * @returns {ServiceDefinition}
     * @private
     */
    createServiceDefinition(serviceName, fileName, catalogInfo, openApi, requestInit, serverVariables) {
      const service = new ServiceDefinition(serviceName, fileName, this._protocolRegistry, catalogInfo, openApi,
        this._relativePath, requestInit, this.services.namespace, this._isUnrestrictedRelative);

      // notify listeners
      try {
        ServicesLoader.notify(service, serverVariables);
      } catch (e) {
        logger.error(e);
      }

      return service;
    }

    /**
     * if we're using a "paths" object from the catalog,
     * we need to merge the 'x-vb' from the "servers" object with the 'x-vb' from both the
     * "info" object for the openapi3 "services" fragment, and its operation (ex. "get") object.
     *
     * for example, use the proxy when fetching the /describe below, use the "accepts" for the fetch,
     * and apply "some/transforms" to the result.
     *
     * this is analogous to what we do today in just swagger/openapi3 with no catalog.json involved; we
     * look at "info", "services", and "paths", when we construct a merged "x-vb" (listed least-to-most precedence).
     *
     *
     * "services": {
     * "demo": {
     *   "openapi": "3.0",
     *   "info": {
     *     "title": "uses new inner-service-openapi3-metadata syntax",
     *     "x-vb": {
     *       "transforms": {
     *         "path": "some/transforms"
     *       }
     *     }
     *   },
     *   "servers": [
     *     {
     *       "url": "vb-catalog://services/demolevel2",
     *       "x-vb": {
     *           "authentication": {
     *               "forceProxy": "cors"
     *           }
     *       }
     *     }
     *   ],
     *   "paths": {
     *     "somepath/describe": {
     *       "get": {
     *         "x-vb": {
     *           "headers": {
     *            "Accepts":  "application/vnd.oracle.adf.openapi3+json"
     *           }
     *         }
     *       }
     *     }
     *   }
     * },
     *
     * @param serviceName
     * @param serverUrl typically, from the resolved "servers" object
     * @param metadata  the 'paths.get" path (and query) will be appended to the url, and the extensions merged.
     * @param requestInit
     * @returns {Promise}
     *
     * @private
     */
    static getOpenApiObjectUsingMetadata(serviceName, serverUrl, metadata, requestInit) {
      return Promise.resolve()
        .then(() => {
          const mergedExtensions = ServiceUtils.getExtensionsFromMetadata(serverUrl, metadata, requestInit);

          const { url } = mergedExtensions;
          delete mergedExtensions.url;

          return OpenApiServiceDefFactory.getOpenApiObject(serviceName, url, mergedExtensions);
        });
    }

    /**
     * get the service definition, and treat as JSON (no swagger parsing)
     * Will timeout (reject) after Constants.Services.definitionTimeout (30) seconds
     *
     * @param serviceName
     * @param url
     * @param {{ headers: Object, transforms: { path: string }, resolvedUrl: string }} additionalExtensions
     * @returns {Promise} resolved with service definition object
     *
     * @private
     *
     * @todo: Services should have its own protocolRegistry, and a parent-fallback similar to flow/app service defs.
     */
    // eslint-disable-next-line class-methods-use-this
    static getOpenApiObject(serviceName, url, additionalExtensions) {
      return Utils.getRuntimeEnvironment()
        .then((env) => {
          // remove 'dots' in the middle of the path
          const path = url.startsWith('text!') ? url : URI(url).normalizePath().toString();
          // 'additionalExtensions' has VB properties that we define for extensions, which includes 'headers';
          // Because it has a 'headers' property, it resembles an 'init param for a fetch() call, so we can use it as
          // an 'init param, and fetch/Request will ignore what it does not care about.
          const initParam = Object.assign({}, additionalExtensions);
          return env.getServiceDefinition(path, initParam)
            .then((def) => {
              const context = initParam.resolvedUrl && { definitionUrl: initParam.resolvedUrl };
              return OpenApiServiceDefFactory.getOpenApi(def, context);
            });
        });
    }

    /**
     * creates the appropriate model for swagger (2) or openapi3 (3+)
     * @param def
     * @param {object} [context] abstraction of Application context. optional, used for variables substitution.
     * @returns {Promise<OpenApiObjectCommon>}
     * @private
     */
    static getOpenApi(def, context) {
      return Promise.resolve()
        .then(() => {
          const config = context
            ? Object.assign({}, context, { initParams: ConfigLoader.initParams })
            : { initParams: ConfigLoader.initParams };
          return OpenApiObjectFactory.get(def, config);
        })
        .catch((ex) => {
          logger.error('unable to resolve service references:', ex);
          throw ex;
        });
    }
  }

  return OpenApiServiceDefFactory;
});

