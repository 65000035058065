'use strict';

define('vb/private/stateManagement/context/containerContext',['vb/private/constants', 'vb/private/translations/translationContext',
], (Constants, TranslationContext) => {
  /** @type Object */
  const symbol = Symbol('container-accessor');
  /**
   * a 'base' class for defining a set of properties available for binding, expression, and action contexts.
   * Intended to be subclassed for each of the various container types.
   *
   *
   *                                      ContainerContext
   *                                            |
   *                 +--------------------------+----------------------------+
   *                 |                          |                            |
   *             FlowContext               PageContext                ExtensionContext
   *                 |                                                       |
   *      +----------+-----------+                     +---------------------+--------------------+
   *      |                      |                     |                     |                    |
   * ApplicationContext    AppPackageContext    FlowExtensionContext  PageExtensionContext  LayoutExtensionContext
   *
   */
  class ContainerContext {
    /**
     * base class for subset of scope properties,
     * plus possibly some extra (like 'responsive', 'translations', etc) that doesn't belong in the scope.
     *
     * this is meant to create the bindable properties that are visible to the author.
     *
     * this class is intended to be subclassed.
     *
     * All namesakes like $flow, $application, $page, etc, will be subclasses of ContainerContexts.
     *
     * One 'weird' addition is getVariable(), used by AssignVariableAction.  When creating a top-level
     * namesake ContainerContext, this method is added to allow access to the Scope variables. (This could
     * go in ActionHelpers, but I wasn't sure if we should expose this to application authors creating custom Actions).
     *
     * @param {Container} container
     */
    constructor(container) {
      // A closure to access 'this' in the lazy getter for the base property
      const self = this;

      Object.defineProperty(this, symbol, {
        value: {
          get listeners() {
            return container.eventListeners || {};
          },
          get functions() {
            return container.functions;
          },
          get chains() {
            return container.chains || {};
          },
          get scope() {
            return container.scope;
          },
          get enums() {
            return container.enums;
          },
          get bundles() {
            return container.bundles;
          },
          bundleContext: null,
          get baseContext() {
            // Lazily create the BaseContext because it's only needed when
            // an extension is using an expression with $base
            // We use self to point to the containerContext object, because `this`
            // here is the object returned by the symbol property.
            const context = new (self.constructor.BaseContextType)(container);
            // and redefine the property as a value for immediate access on the next get
            Object.defineProperty(this, 'baseContext', {
              value: context,
              enumerable: true,
            });

            return context;
          },
          get application() {
            let app;
            // Depending if the container is in a App UI or not, the content of the application
            // object is different. When in an App UI, the application object is only what is
            // defined in the interface.
            const appPackage = container.package;
            if (appPackage) {
              const extensionApplication = appPackage.application.extensions[appPackage.extensionId];
              app = extensionApplication.expressionContext.base;
            } else {
              app = container.application.expressionContext;
            }

            // and redefine the property as a value for immediate access on the next get
            Object.defineProperty(this, 'application', {
              value: app,
              enumerable: true,
            });

            return app;
          },
        },
      });
    }

    /**
     * baseContext is available on the expressionContext of any container but it
     * is only exposed by extensions of this container.
     * baseContext is the $base scope on extensions of this container
     * @type {BaseContext}
     */
    get baseContext() {
      return this[symbol].baseContext;
    }

    /**
     * Accessor for the variables namespace
     * @type {Object}
     */
    get [Constants.VariableNamespace.VARIABLES]() {
      const { scope } = this[symbol];
      // protect again variables not be being initialized
      return scope && scope.variableNamespaces[Constants.VariableNamespace.VARIABLES];
    }

    /**
     * Accessor for the metadata namespace
     * @type {Object}
     */
    get [Constants.VariableNamespace.METADATA]() {
      const { scope } = this[symbol];
      // protect again variables not be being initialized
      return scope && scope.variableNamespaces[Constants.VariableNamespace.METADATA];
    }

    /**
     * Accessor for the constants namespace
     * @type {Object}
     */
    get [Constants.VariableNamespace.CONSTANTS]() {
      const { scope } = this[symbol];
      return scope && scope.variableNamespaces[Constants.VariableNamespace.CONSTANTS];
    }

    /**
     * Accessor for the enums namespace
     * @type {Object}
     */
    get enums() {
      return this[symbol].enums;
    }

    /**
     * Accessor for the event listeners
     * @type {Object}
     */
    get listeners() {
      return this[symbol].listeners;
    }

    /**
     * Accessor for the module functions
     * @type {Object}
     */
    get functions() {
      return this[symbol].functions;
    }

    /**
     * Accessor for the chains definition?
     * @type {Object}
     */
    get chains() {
      return this[symbol].chains;
    }

    /**
     * Accessor for the translations
     * The container context .translations exposes the V1 Strings from the BundlesModel.
     * We expose V2 Strings only in the $translations EL context (as well as V1 Strings)
     * @type {Object}
     */
    get [Constants.TRANSLATIONS_CONTEXT]() {
      if (!this[symbol].bundleContext) {
        this[symbol].bundleContext = TranslationContext.createV1Context(this[symbol].bundles);
      }
      return this[symbol].bundleContext;
    }

    /**
     * Setter used by action chain tester to mock translation bundles.
     *
     * @param {Object} bundleContext mock bundle
     */
    set [Constants.TRANSLATIONS_CONTEXT](bundleContext) {
      const vbConfig = window.vbInitConfig || {};

      // only override if we are in action chain test mode
      if (vbConfig.TEST_MODE === Constants.TestMode.ACTION_CHAIN) {
        this[symbol].bundleContext = bundleContext;
      }
    }

    /**
     * Accessor for the application
     * @type {Object}
     */
    get application() {
      return this[symbol].application;
    }

    // special case, add the getVariable method from Scope
    getVariable(...args) {
      return this[symbol].scope.getVariable(...args);
    }

    /**
     * @param {Object} container
     *
     * this method is static because we need to be able to create the available contexts BEFORE
     * the individual XXXContext objects are creates, because we base the viewModel on the availableContexts object.
     *
     * these static methods still use class hierarchy for inheriting property accessors.
     *
     * @returns {Object}
     */
    static getAvailableContexts(container) {
      const availableContexts = {};

      // make this function non-enumerable
      Object.defineProperty(availableContexts, 'clone', {
        enumerable: false,
        value: ContainerContext.cloneContext.bind(availableContexts),
      });

      Object.defineProperty(availableContexts, 'addAccessor', {
        enumerable: false,
        value: ContainerContext.addAccessor.bind(availableContexts),
      });

      // using defineProperties, and accessors for multiple reasons:
      // - make these un-settable
      // - allow us to create the ViewModel from this object, before calling run() (for Page in particular)
      // - for Page, something needs $flow and $application before Page.run(), so we don't actually
      // have an expressionContext the first time getAvailableContexts() is called - we don't want to create the
      // Scope before then. So, rather than create a $flow/$application only version of the available contexts,
      // using accessors allows the 'value' for $page (and its properties) be created after this
      // $todo: see if we can re-work the calls to getAvailableContexts() before run()
      Object.defineProperties(availableContexts, {
        $global: {
          enumerable: true,
          configurable: true,
          get() {
            return container.expressionContext.application;
          },
        },
        $application: {
          enumerable: true,
          configurable: true,
          get() {
            return container.expressionContext.application;
          },
        },
        $variables: {
          enumerable: true,
          configurable: true,
          get: () => container.expressionContext.variables,
        },
        $enums: {
          enumerable: true,
          configurable: true,
          get: () => container.expressionContext.enums,
        },
        $metadata: {
          enumerable: true,
          configurable: true,
          get: () => container.expressionContext.metadata,
        },
        $constants: {
          enumerable: true,
          configurable: true,
          get() {
            return container.expressionContext.constants;
          },
        },
        $chains: {
          enumerable: true,
          configurable: true,
          get: () => container.expressionContext.chains,
        },
        $functions: {
          enumerable: true,
          configurable: true,
          get: () => container.expressionContext.functions,
        },
        $listeners: {
          enumerable: true,
          configurable: true,
          get: () => container.expressionContext.listeners,
        },
        $imports: {
          enumerable: true,
          configurable: true,
          // imports are not exposed on the container context, instead only export '$imports' to limit scope to current
          // container. This will prevent '$flow.imports', '$application.imports' explicitly
          get: () => container.imports,
        },
        [`$${Constants.TRANSLATIONS_CONTEXT}`]: {
          enumerable: true,
          configurable: true,
          get: () => {
            // Get the full translations context (V1 and V2 strings) for exposure in $translations
            const bundleContext = TranslationContext.createContext(container.bundles);

            // and redefine the property as a value for immediate access on the next get
            Object.defineProperty(availableContexts, `$${Constants.TRANSLATIONS_CONTEXT}`, {
              value: bundleContext,
              enumerable: true,
            });

            return bundleContext;
          },
        },
      });

      if (container.package) {
        Object.defineProperties(availableContexts, {
          // Redefine $application to point to the App UI context
          $application: {
            enumerable: true,
            configurable: true,
            get() {
              return container.package.expressionContext;
            },
          },
          $base: {
            enumerable: true,
            configurable: true,
            get() {
              return container.expressionContext.baseContext;
            },
          },
          $extension: {
            enumerable: true,
            configurable: true,
            value: {
              get base() {
                return container.expressionContext.baseContext;
              },
              [Constants.PATH_VARIABLE]: container.absoluteUrl,
            },
          },
        });

        // $extension.<extId>.translations
        // $base.translations (if 'base' extension in bundles)
        if (container.bundles) {
          TranslationContext.addExtensionsContexts(container.bundles, availableContexts.$extension);

          const baseStringMap = container.bundles.getExtensionV2StringMap(Constants.ExtensionFolders.BASE);
          if (baseStringMap) {
            Object.defineProperty(availableContexts.$base, Constants.TRANSLATIONS_CONTEXT, {
              enumerable: true,
              configurable: true,
              value: baseStringMap,
            });
          }
        }
      }

      return availableContexts;
    }

    /**
     * this is a regular function, so that 'this' is bindable; shared with ActionChainContexts
     *
     * a simple shallow-ish clone, to handle accessors via property descriptors
     * also creates new arrays at ONLY the top level.
     *
     * good enough for contexts, for now, but requirements could change is contexts need more functionality
     */
    static cloneContext() {
      const cloneObj = {};
      Object.keys(this).forEach((propertyName) => {
        const def = Object.getOwnPropertyDescriptor(this, propertyName);
        if (Array.isArray(def.value)) {
          def.value = def.value.slice();
        }
        Object.defineProperty(cloneObj, propertyName, def);
      });

      Object.defineProperty(cloneObj, 'clone', {
        enumerable: false,
        value: ContainerContext.cloneContext.bind(cloneObj),
      });

      Object.defineProperty(cloneObj, 'addAccessor', {
        enumerable: false,
        value: ContainerContext.addAccessor.bind(cloneObj),
      });

      return cloneObj;
    }

    /**
     * when the container needs to add something that does not already exist, instead of just defining a property
     * on the object, this is how it should be added.  Makes it discoverable, and debuggable.
     * For example, ForEach action adds a $current
     */
    static addAccessor(contextName, accessor) {
      if (contextName && typeof accessor === 'function') {
        Object.defineProperty(this, contextName, {
          enumerable: true,
          configurable: true,
          get: accessor,
        });
      }
    }
  }

  return ContainerContext;
});

