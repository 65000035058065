'use strict';

define('vb/private/mobile/customUrlScheme',['vb/private/configLoader', 'vb/private/stateManagement/router', 'urijs/URI'], (ConfigLoader, Router, URI) => {
  class CustomUrlScheme {
    /**
     * Handles the launch of a native mobile app from a deep link url.
     * The deep link url could be in one of the following formats:
     * mycoolapp://
     * mycoolapp://shell/main/main-start
     * mycoolapp://shell/main/main-start?param1=value1
     * mycoolapp://?param1=value1
     * mycoolapp is used as an example of the custom url scheme here.  The custom url scheme is set in the DT
     * automatically and can be changed by the user to whatever they wish as long as it's unique per installed
     * application.
     * For more information about handleOpenUrl please refer to the following cordova plugin:
     * https://github.com/EddyVerbruggen/Custom-URL-scheme
     */
    handleDeepLinkUrl(url) {
      if (!url) {
        return;
      }

      const userConfig = ConfigLoader.userConfig;
      if (userConfig
          && userConfig.configuration
          && userConfig.configuration.idcsInfo
          && userConfig.configuration.idcsInfo.oAuthRedirectEndpoint
          && url.startsWith(userConfig.configuration.idcsInfo.oAuthRedirectEndpoint)) {
        // handleOpenURL is invoked by IDCS after a successful login while trying to
        // redirect back to the native mobile app
        // in this case handleOpenURL should be ignored
        return;
      }

      // cache the url so that it can be handled once the application is initialized
      this.deepLinkUrl = url;

      if (Router.application) {
        // the application has already been initialized so handle the deep link url right away:
        this.performNavigationIfNeeded();
      }
    }

    /**
     * Navigates to the deep link url. Prior to navigation, convert the pathname from
     * the deep link url to the path parameters, i.e.
     * in the following deep link url 'mycoolapp://shell/main/main-start?param1=value1'
     * the pathname would be '//shell/main/main-start' and it will be transformed to:
     * '?page=shell&shell=main&main=main-start'
     */
    performNavigationIfNeeded() {
      if (this.deepLinkUrl) {
        // unfortunately URL and URI pathname doesn't consistently return the same pathname
        // on both Android and iOS, on Android it returns the origin as 'mycoolapp://'
        // but on iOS it's 'mycoolapp://shell' as a result the pathname becomes incorrect
        // so have to workaround it by removing the protocol and the search if present to end up
        // only with the real pathname
        const deepLinkUrl = new URL(this.deepLinkUrl);
        const { protocol } = deepLinkUrl;
        const { search } = deepLinkUrl;
        const { hash } = deepLinkUrl;
        let pathname = this.deepLinkUrl.replace(protocol, '');
        pathname = pathname.replace(search, '');
        pathname = pathname.replace(hash, '');
        if (pathname && pathname.startsWith('//')) {
          pathname = pathname.substring(2);
        }
        const pathParamsUri = new URI(Router.getUrlFromPath(pathname));

        const deepLinkUri = new URI(this.deepLinkUrl);
        deepLinkUri.addSearch(pathParamsUri.search(true));
        const queryParams = deepLinkUri.search();

        window.location.href = `index.html${queryParams}`;
      }
    }
  }

  return new CustomUrlScheme(); // singleton
});

