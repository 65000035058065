'use strict';

define('vb/private/types/disconnectedServiceDataProvider',[
  'vb/private/types/capabilities/fetchContext',
  'vb/private/types/capabilities/noOpFetchByKeys',
  'vb/private/types/capabilities/noOpFetchFirst',
  'vb/private/types/capabilities/noOpFetchByOffset',
  'vb/private/types/capabilities/fetchByOffsetIteration'],
(FetchContext, NoOpFetchByKeys, NoOpFetchFirst, NoOpFetchByOffset) => {
  /**
   * A simple SDP implementation that is a facade over another SDP instance, whose state is being disposed.
   * The disconnected SDP maintains no state or the bare minimum state locally, and ignores writes. It uses the
   * wrapped SDP for access to basic configuration info outside of its state.
   *
   * When the SDP state is disposed this facade makes it easy for 'callers' in the middle of async processing to
   * finish their tasks without worrying about the underlying state having been disposed.
   * Callers that have a reference to an SDP instance are often modifying its state after it has been disposed, causing
   * NPEs. So the idea here is to place a facade over the actual instance so that reads and writes are no-ops.
   * Example: when sdp.fetchFirst/next is called it often launches one or more REST calls that might be outstanding
   * at the time sdp state is being disposed (A). When the Rest call returns a lot of callback code is written
   * assuming state to be valid and perform reads/writes. All this code needs to finish with the least disruption.
   *
   * Note: (A) state can be disposed because user is navigating away from page. This unwinds all variables causing
   * outstanding async requests to fail when they return.
   *
   */
  /* eslint class-methods-use-this: ["error", { "exceptMethods":
   ["getValue","fetchFirst","fetchByKeys","containsKeys","fetchByOffset","getCapability","getTotalSize","isEmpty",
   "isDisconnected"]
    }] */
  class DisconnectedServiceDataProvider {
    constructor(sdp) {
      this.sdp = sdp;
      this.id = sdp.id;
      this.log = sdp.log;
    }

    callActionChain(id, params = {}, isJsChain) {
      this.log.info('Unable to call action chain', id, 'with params', params, 'because the'
        + ' ServiceDataProvider instance', this.id, 'has been disposed');
    }

    /**
     * true if the instance has been disconnected from the variable
     * @return {boolean}
     */
    isDisconnected() {
      return true;
    }

    /**
     * returns the signal that callers can use to register their listeners, which will be notified of variable lifecycle
     * stage changes
     * @returns {signals.Signal}
     */
    getLifecycleStageChangedSignal() {
      return this.sdp.getLifecycleStageChangedSignal();
    }

    // eslint-disable-next-line no-unused-vars
    addEventListener(eventType, listener) {
      this.log.info('Unable to add event listener', eventType, 'because the ServiceDataProvider variable', this.id,
        'has been disposed');
    }

    // eslint-disable-next-line no-unused-vars
    removeEventListener(eventType, listener) {
      this.log.info('Unable to remove event listener', eventType, 'because the ServiceDataProvider variable', this.id,
        'has been disposed');
    }

    removeAllEventListeners() {
      this.log.info('Unable to remove all event listeners because the ServiceDataProvider variable', this.id,
        'has been disposed');
    }

    fetchFirst(params) {
      return (new NoOpFetchFirst(params)).fetchFirst();
    }

    fetchByKeys(params) {
      return (new NoOpFetchByKeys(params)).fetchByKeys();
    }

    containsKeys(params) {
      return (new NoOpFetchByKeys(params).containsKeys());
    }

    fetchByOffset(params) {
      return (new NoOpFetchByOffset(params)).fetchByOffset();
    }

    // eslint-disable-next-line no-unused-vars
    getCapability(feature) {
      return null;
    }

    /**
     * Gets internal state for this instance.
     *
     * @final
     * @param name The name of the state to fetch
     * @returns {*} the state of the named property or the entire state
     */
    getInternalState(name) {
      this.log.info('Unable to get internalState', name, 'because the ServiceDataProvider variable', this.id,
        'has been disposed');
    }

    /**
     * safe to pass-through to wrapped SDP
     * @return {string}
     */
    getIdAttributeProperty() {
      return this.sdp.getIdAttributeProperty();
    }

    /**
     * Return the total size of -1 always.
     *
     * @returns {Promise.<number>} total size of data
     * @instance
     */
    getTotalSize() {
      return Promise.resolve(FetchContext.DEFAULT_TOTAL_SIZE);
    }

    /**
     * No value, i.e., state is available for disconnected SDP
     * @return {{}}
     */
    getValue() {
      return undefined;
    }

    /**
     * No op where a info is logged that because the variable has been disposed.
     * @param name of the event
     * @param payload payload for the event
     * @param withBubbling whether event needs to bubble
     * @returns {*|Promise}
     */
    // eslint-disable-next-line no-unused-vars
    invokeEvent(name, payload, withBubbling = true) {
      this.log.info('Unable to fire event', name, 'with payload', payload, 'because the ServiceDataProvider instance',
        this.id, 'has been disposed!');
    }

    /**
     * Always unknown size
     * @return {string}
     */
    isEmpty() {
      return 'unknown';
    }

    /**
     * Sets the total size on the SDP instance.
     *
     * @param {number} ts total size of data
     * @instance
     * @private
     */
    setTotalSize(ts) {
      this.totalSize = ts;
    }

    /**
     * Sets internal state for this instance.
     *
     * @final
     * @param name The name of the state
     * @param value The value
     */
    setInternalState(name, value) {
      this.log.info('Unable to set internalState', name, 'to value', value, 'because the ServiceDataProvider'
        + ' instance', this.id, 'has been disposed');
    }
  }

  return DisconnectedServiceDataProvider;
});

