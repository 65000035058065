'use strict';

define('vbsw/private/optCacheStrategiesFactory',[
  'vbc/private/utils',
  'vbsw/private/optCacheStrategies',
], (Utils, OptCacheStrategies) => {
  /**
   * A factory to retrieve which http cache header strategy to use.
   */
  class OptCacheStrategiesFactory {
    /**
     *
     * @param {*} config fetchHandler configuration
     * @param {*} request the original request object
     * @returns cache strategy instance
     */
    static getCacheStrategy(config, request) {
      if (Utils.isMobile()
        || (config.isWebPwa && config.registryUrl && request.url.startsWith(config.registryUrl))) {
        // special case for handling the caching of user information for native mobile and extension registry url for
        // web PWA's
        return { cacheStrategy: OptCacheStrategies.getHttpCacheHeaderStrategy() };
      }

      // for everything else return null to use the default cache header strategy provided by OPT
      return { cacheStrategy: null };
    }
  }

  return OptCacheStrategiesFactory;
});

