/* eslint-disable class-methods-use-this */
/* eslint max-classes-per-file: ["error", 2] */

'use strict';

define('vb/private/types/builtinExtendedTypeMixin',['vb/types/extendedType', 'vb/private/stateManagement/stateUtils'], (ExtendedType, StateUtils) => {
  /**
   * Mixes in prototype methods (including constructor) from src classes onto cls, without overwriting prototype
   * methods that may already be declared in cls.
   * @param cls
   * @param src
   */
  const mixinBehaviors = (cls, ...src) => {
    const clz = cls;
    src.forEach((srcCl) => {
      Object.getOwnPropertyNames(srcCl.prototype).forEach((key) => {
        if (!clz.prototype[key]) {
          clz.prototype[key] = srcCl.prototype[key];
        }
      });
    });
  };

  /**
   * A mixin that provides specific behavior for VB builtin types.
   */
  class BuiltinTypeBehavior {
    /**
     * Invokes an action chain with the given ID. The ID may include qualifiers to invoke
     * an action chain in different scopes.
     *
     * The parameters will be passed directly into the chain and may be expressions.
     *
     * A promise will be returned containing the name of the outcome and result from calling
     * that chain. Errors occurring during the chain will result in this promise being rejected.
     *
     * @final
     * @param id The name of the action chain to call
     * @param params Parameters sent to the chain, or an empty map
     * @param isJsChain true if this is a JS action chain
     */
    callActionChain(id, params = {}, isJsChain) {
      return this.handler.callActionChain(id, params, isJsChain);
    }

    /**
     * all VB builtin types will extend from this mixin and in addition to being an extended type it's also a buitin
     * type that VB ships. A customer creating their own type will extend from vb/types/ExtendedType class.
     * @returns {boolean}
     */
    isBuiltinType() {
      return true;
    }

    /**
     * Sets up object accessors for all properties that the builtin variable supports. By
     * default they read from and write to the underlying 'value' variable.
     * @param defaultValue
     * @param currentScope
     * @param namespace
     * @param variable
     */
    setupVariableProperties(defaultValue, currentScope, namespace, variable) {
      Object.keys(defaultValue).forEach((k) => {
        Object.defineProperty(this, k, this.getVariablePropertyDefinition(k, currentScope, namespace, variable));
      });
    }

    /**
     * Called to setup property accessors for the value of a property with name (k). Subclasses can override
     * this method to have control over accessors for specific properties.
     * @param k
     * @param currScope
     * @param namespace
     * @param variable
     * @returns {{get: (function(): *), set: (function(*)), enumerable: boolean, configurable: boolean}}
     */
    getVariablePropertyDefinition(k, currScope, namespace, variable) {
      return StateUtils.getVariablePropertyDefinition(this, k, currScope, namespace, variable);
    }
  }

  /**
   * Mixin class that mixes in proto methods from BuiltinTypeBehavior and vb/types/ExtendedType
   */
  const BuiltinExtendedTypeMixin = (superclass) => {
    class BuiltinType extends superclass {}
    mixinBehaviors(BuiltinType, BuiltinTypeBehavior, ExtendedType);
    return BuiltinType;
  };

  return BuiltinExtendedTypeMixin;
});

