'use strict';

define('vbsw/private/plugins/mobileAuthPreprocessorHandlerPlugin',['vbsw/private/plugins/authPreprocessorHandlerPlugin'],
  (AuthPreprocessorHandlerPlugin) => {
    class MobileAuthProprocessHandlerPlugin extends AuthPreprocessorHandlerPlugin {
      constructor(context, params = {}) {
        super(context, params);
      }

      enforceCORS() {
        return false;
      }

      /**
       * check if we need to use the proxy for http
       * @param request
       * @param infoExtension
       * @returns {boolean|*}
       * @override
       */
      useProxyForProtocol(request, infoExtension) {
        // normally for web, we have to switch 'http' to 'https' so it can make it through to a service worker,
        // and we indicate the switch with a special header.
        //
        // for mobile, the override isn't necessary, so check for a normal 'http:' request.
        return request.url.startsWith('http:') || super.useProxyForProtocol(request, infoExtension);
      }

    }

    return MobileAuthProprocessHandlerPlugin;
  });

