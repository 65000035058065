'use strict';

define('vb/private/stateManagement/fragmentHolderBaseMixin',[
  'vb/private/constants',
  'vb/components/oj-vb-fragment/loader', // load fragment CCA so it's automatically available
], (Constants) => {
  /**
   * Base mixin that has common functions that all fragment holder containers share. Whether the container is an
   * extension or the base.
   */
  const FragmentHolderBaseMixin = (superclass) => class extends superclass {
    constructor(...args) {
      super(...args);

      /**
       * instances of loaded fragments used by this container.
       * @type {Object}
       */
      this.fragments = {};

      /**
       * fragmentBridge that connects this container to the component. Usually set by the fragmentBridge itself
       * @type {null|FragmentBridge}
       */
      this.fragmentBridge = null;

      this.parentOJModuleNode = undefined;

      const parent = this.getParentOrBaseOfExtension();

      const parentAllowsSlots = (parent && typeof parent.getCapability === 'function')
        ? parent.getCapability(Constants.FragmentCapability.ALLOWS_SLOTS) : true;
      /**
       * by default fragment allows slots to be used as long as its not turned off by its parent (e.g., in layouts)
       * @type {{allowSlots: boolean}}
       */
      this.capabilities = {
        allowSlots: parentAllowsSlots === undefined ? true : parentAllowsSlots,
      };
    }

    getParentOrBaseOfExtension() {
      return this.parent;
    }

    /**
     * Returns a value that is appropriate to the option that is passed in.
     * @param {String} option
     * @returns {*}
     */
    // eslint-disable-next-line class-methods-use-this
    getCapability(option) {
      if (option === Constants.FragmentCapability.ALLOWS_SLOTS) {
        return this.capabilities.allowSlots;
      }
      return null;
    }

    /**
     * Retrieves a fragment instance for the fragment id. If a Fragment instance cannot be located this method
     * creates the Fragment instance first and then returns the fragment context. This is only called from the
     * FragmentBridge.
     * @param {string} id of the fragment
     * @param {string} name of the fragment (artifacts)
     * @param {object} params input from caller
     * @param {object} templates defined on fragment
     */
    createFragment(id, name, params, templates) {
      let fragment = this.fragments[id];
      // if name and/or params are provided then we definitely construct a fragment, otherwise return the fragment
      // that was previously created or undefined.
      if (!fragment && name) {
        return this.getFragmentClass().then((FragmentClazz) => {
          fragment = new (FragmentClazz)(id, this, undefined, name, params, templates);
          this.fragments[id] = fragment;
          return fragment;
        });
      }

      return Promise.resolve(fragment);
    }

    getFragment(id) {
      return this.fragments[id];
    }

    // eslint-disable-next-line class-methods-use-this
    getFragmentClass() {
      return Promise.resolve(this.constructor.FragmentClass);
    }

    disposeFragments() {
      Object.keys(this.fragments).forEach((fragmentId) => {
        const frag = this.fragments[fragmentId];
        frag.dispose();
      });
    }

    /**
     * deletes a fragment from the container.
     * @param id
     */
    deleteFragment(id) {
      delete this.fragments[id];
    }

    /**
     * Checks whether the current container has any dirty data variables.
     * It will check its current scope, any embedded fragments, layouts
     * and their extensions if present.
     * @return {boolean} true if there is at least one dirty data variable found;
     *                   false otherwise
     */
    checkForDirtyData() {
      // verify if any contained fragments have dirty data
      const hasDirtyData = Object.values(this.fragments).find((fragment) => fragment.checkForDirtyData());
      return hasDirtyData ? true : super.checkForDirtyData();
    }

    /**
     * Re-sets the dirty data state of variables in the current container's scope
     * as well as fragments and layouts and their extensions.
     */
    resetDirtyData() {
      // reset all fragment scopes
      Object.values(this.fragments).forEach((fragment) => {
        fragment.resetDirtyData();
      });

      super.resetDirtyData();
    }

    /**
     * Sets the ParentOJModuleNode for the container
     */
    setParentOJModuleNode(parentNode) {
      this.parentOJModuleNode = parentNode;
    }
  };

  return FragmentHolderBaseMixin;
});

