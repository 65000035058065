'use strict';

/**
 * Routines for openId security protocols and for securely storing sensitive information.
 */
define('vb/private/mobile/security/strategyOpenidOAuthIdm',[
  'vb/private/mobile/security/strategy',
  'vb/private/mobile/security/constants',
], (Strategy, SecurityConstants) => {
  class StrategyOpenidOAuthIdm extends Strategy {
    constructor(securityMetadata) {
      super(securityMetadata);

      this.clientId = securityMetadata.clientId;
      this.oAuthRedirectEndpoint = securityMetadata.oAuthRedirectEndpoint;
      this.allowedScopes = securityMetadata.allowedScopes;
      this.allowedScopes.push('openid');
    }

    /**
     * Initializes this security strategy.
     *
     * @param props An object containing configuration for authentication
     * @return promise Resolves when complete
     */
    init(props = null) {
      const authCode = this.idmAuthFlows.OAuthPropertiesBuilder.OAuthAuthorizationGrantType.OAuthAuthorizationCode;

      const authProps = props || new this.idmAuthFlows.OpenIDConnectPropertiesBuilder()
        .appName(this.appName)
        .oAuthAuthorizationGrantType(authCode)
        .discoveryEndpoint(`${this.idcsHostName}/.well-known/idcs-configuration`)
        .oAuthClientID(this.clientId)
        .oAuthRedirectEndpoint(this.oAuthRedirectEndpoint)
        .oAuthScope(this.allowedScopes)
        .logoutURL(`${this.idcsHostName}${SecurityConstants.OAUTH2_V1_PATH}userlogout`)
        .browserMode(this.idmAuthFlows.OAuthPropertiesBuilder.BrowserMode.External)
        .enablePKCE(true)
        .build();

      return super.init(authProps);
    }

    /**
     * Shows the login screen for the given application. After a successful login, the user will
     * be taken to the app.
     *
     * @return promise resolves if the login was successful, rejects otherwise
     */
    loginToApplication() {
      if (!this.authenticationFlow) {
        return Promise.reject(new Error('init must be invoked prior to calling loginToApplication'));
      }

      // cache the login promise as the IDM plugin doesn't support multi-threaded environment
      // where multiple login calls are being made at the same time
      if (!this.loginPromise) {
        this.loginPromise = this.authenticationFlow.login()
          .then((loginResponse) => {
            if (!loginResponse) {
              throw new Error('Failed to login to application because there was no login response provided!');
            }
          })
          .finally(() => {
            this.loginPromise = null;
          });
      }

      return this.loginPromise;
    }
  }

  return StrategyOpenidOAuthIdm;
});

