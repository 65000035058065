'use strict';

define('vb/private/stateManagement/context/pageBaseContext',[
  'vb/private/stateManagement/context/baseContext'],
(BaseContext) => {
  /** @type {Object} */
  const symbol = Symbol('page-base-accessor');
  /**
   * set of properties to expose in $base for page extension
   */
  class PageBaseContext extends BaseContext {
    /**
     * @param extension
     */
    constructor(extension) {
      super(extension);

      Object.defineProperty(this, symbol,
        {
          value: {
            get flowBaseContext() {
              return extension.parent.expressionContext.baseContext;
            },
          },
        });
    }

    /**
     * $base.flow
     */
    get flow() {
      return this[symbol].flowBaseContext;
    }

    /**
     * $base.page
     */
    get page() {
      return this;
    }
  }

  return PageBaseContext;
});

