'use strict';

define('vb/action/builtin/takePhotoAction',[
  'knockout',
  'vb/private/utils',
  'vb/action/action',
  'vb/private/log',
  'vb/private/mobile/fileIo',
  'ojs/ojhtmlutils',
  'ojs/ojmenu',
  'ojs/ojoption',
],
(ko, Utils, Action, Log, FileIo, HtmlUtils) => {

  const logger = Log.getLogger('/vb/action/builtin/takePhotoAction');

  const MEDIA_TYPES = ['image', 'video', 'audio'];

  const LOW_RESOLUTION = 0;
  const MEDIUM_RESOLUTION = 50;
  const HIGH_RESOLUTION = 100;
  const LIBRARY = 'PHOTOLIBRARY';
  const ALBUM = 'SAVEDPHOTOALBUM';
  const FRONT = 'FRONT';

  const ANDROID_MENU_CONTAINER_ID = 'vbInternalAndroidPhotoOptionsContainer';
  const ANDROID_MENU_ID = 'vbInternalAndroidPhotoOptionsMenu';
  const ANDROID_MENU_OPTION_CAMERA = 'androidCamera';
  const ANDROID_MENU_OPTION_BROWSE = 'androidBrowse';
  const ANDROID_MENU_OPTION_CANCEL = 'androidCancel';

  const ANDROID_PHOTO_SOURCE_MENU_HTML = `<div><div id="${ANDROID_MENU_CONTAINER_ID}">
      <a id="vbInternalAndroidPhotoOptionsLauncher" style="display:none"/>
      <oj-menu id="${ANDROID_MENU_ID}"
               style="display:none"
               on-oj-action="[[vbInternalAndroidPhotoOptionsAction]]"
               open-options.launcher="vbInternalAndroidPhotoOptionsLauncher">
          <oj-option id="${ANDROID_MENU_OPTION_CAMERA}" value="${ANDROID_MENU_OPTION_CAMERA}">
               <oj-bind-text value="[[ getString('camera') || 'Camera' ]]"></oj-bind-text>
          </oj-option>
          <oj-option id="${ANDROID_MENU_OPTION_BROWSE}" value="${ANDROID_MENU_OPTION_BROWSE}">
               <oj-bind-text value="[[ getString('browse') || 'Browse' ]]"></oj-bind-text>
          </oj-option>
          <oj-option id="divider"></oj-option>
          <oj-option id="${ANDROID_MENU_OPTION_CANCEL}" value="${ANDROID_MENU_OPTION_CANCEL}">
               <oj-bind-text value="[[ getString('cancel') || 'Cancel' ]]"></oj-bind-text>
          </oj-option>
      </oj-menu>
    </div></div>`;

  /**
   * Invokes the cordova-plugin-camera or HtmlMediaCapture API to take pictures and/or choose images from the system's
   * image library. The mediaType parameter controls whether to use cordova-pluin-camera or
   * {@link https://www.w3.org/TR/html-media-capture/ Html Media Cpature}
   *
   * The parameters to this action are as follows:
   *  mediaType - Controls the type of media. Valid values are &lt;empty&gt; and &quot;image&quot;. For backward
   *              compatibility, not setting the media type or setting it to &lt;empty&gt; will use
   *              cordova-plugin-camera API.
   *              If this value is set to &quot;image&quot; then the MediaCapture APi is used.
   *  quality - Quality of the saved image, expressed as a range of 0-100, where 100 is typically full resolution with
   *            no loss from file compression. Default is 50. Optional.
   *            Ignored if mediaType is not empty.
   *  sourceType - Set the source of the picture. Default is CAMERA. Optional.
   *               Ignored if mediaType is not empty.
   *  targetWidth - Width in pixels to scale image. Must be used with targetHeight.
   *                Aspect ratio remains constant. Optional.
   *                Ignored if mediaType is not empty.
   *  targetHeight - Height in pixels to scale image. Must be used with targetWidth.
   *                 Aspect ratio remains constant. Optional.
   *                 Ignored if mediaType is not empty.
   *  cameraDirection - Choose the camera to use (front- or back-facing).  Default is BACK. Optional.
   *                    Ignored if mediaType is not empty.
   *
   * Outcome from this action is filePath if mediaType is empty. Otherwise file.
   *  filePath - A string giving file URI of the image taken.
   *  file - A Blob that contains the &quot;image&quot;.
   */
  class TakePhotoAction extends Action {
    constructor(id, label, { registrar }) {
      super(id, label);

      // register the cleanup callback
      registrar.setFinallyCallback('TakePhotoAction cleanup', () => {
        TakePhotoAction.cleanupCallback();
        // does nothing but register a cleanup method
        return Promise.resolve();
      });
    }

    /**
     * called by actionChain
     * @param availableContexts
     */
    setAvailableContexts(availableContexts) {
      if (Utils.isAndroid()) {
        this.availableContexts = availableContexts;

        // setup the menu and bindings
        this.setupAndroidPhotoOptionsMenu();
      }
    }

    /**
     * Utility method to setup oj-menu for android photo source options.
     * @private
     */
    setupAndroidPhotoOptionsMenu() {
      if (!document.getElementById(ANDROID_MENU_CONTAINER_ID)) {

        // Create menu element from HTML string and append to body
        const nodes = HtmlUtils.stringToNodeArray(ANDROID_PHOTO_SOURCE_MENU_HTML);
        document.body.appendChild(nodes[0]);

        ko.applyBindings({
          vbInternalAndroidPhotoOptionsAction: (e) => {
            // Retrieve the reference and resolve the Promise with selected option.
            const resolve = e.target.parentElement.getProperty('resolver');
            resolve(e.target.value);
          },
          getString: (property) => {
            const app = this.availableContexts.$application;
            return app.translations && app.translations.app && app.translations.app.take_photo &&
                   app.translations.app.take_photo[property];
          },
        }, document.getElementById(ANDROID_MENU_CONTAINER_ID));
      }
    }

    /**
     * returned Promise is resolved with Outcome (success or failure), or rejected with a message.
     *
     * @param parameters
     * @returns {Promise}
     */
    perform(parameters) {
      if ((Utils.isIos() || Utils.isAndroid()) && (!parameters.mediaType)) {
        // if it is empty or not defined, execute legacy cordova code for mobile
        return TakePhotoAction.performLegacy(parameters);
      }

      // Use Html media Capture
      // FYI: https://www.w3.org/TR/html-media-capture/
      return TakePhotoAction.performHtmlMediaCapture(parameters);
    }

    /**
     * Uses cordova-plugin-camera to take pictures.
     *
     * @private
     * @deprecated This action uses HtmlMediaCapture implementation by default
     * @param parameters
     * @returns {Promise}
     */
    static performLegacy(parameters) {
      const camera = navigator.camera;
      const cameraOptions = {};

      if (parameters.quality && parameters.quality >= LOW_RESOLUTION && parameters.quality <= HIGH_RESOLUTION) {
        cameraOptions.quality = parameters.quality;
      } else {
        cameraOptions.quality = MEDIUM_RESOLUTION;
      }

      const sourceType = parameters.sourceType && parameters.sourceType.toUpperCase();
      if (sourceType === LIBRARY) {
        cameraOptions.sourceType = camera.PictureSourceType.PHOTOLIBRARY;
      } else if (sourceType === ALBUM) {
        cameraOptions.sourceType = camera.PictureSourceType.SAVEDPHOTOALBUM;
      } else {
        cameraOptions.sourceType = camera.PictureSourceType.CAMERA;
      }

      if (parameters.targetWidth && parameters.targetHeight &&
          parameters.targetWidth >= 0 && parameters.targetHeight >= 0) {
        // only set to passed parameters if both targetWidth and targetHeight have been provided
        cameraOptions.targetWidth = parameters.targetWidth;
        cameraOptions.targetHeight = parameters.targetHeight;
      }

      cameraOptions.cameraDirection =
        (parameters.cameraDirection && parameters.cameraDirection.toUpperCase() === FRONT) ?
          camera.Direction.FRONT : camera.Direction.BACK;

      // for now, don't expose these parameters and set these internally:
      cameraOptions.encodingType = camera.EncodingType.JPEG; // always choose jpg encoding
      cameraOptions.saveToPhotoAlbum = false; // default to false
      cameraOptions.correctOrientation = true; // rotate image to correct for orientation of the device during capture

      return new Promise((resolve) => {
        // take the photo
        camera.getPicture(
          // success
          (result) => {
            // get the real path:
            const questionMark = result.lastIndexOf('?');
            let photoPath = result;
            if (questionMark > -1) {
              photoPath = result.substring(0, questionMark);
            }

            // some webviews have a problem where the camera APIs always return the same filename,
            // and when applied to img.src, the image will not update because the url is the same.
            // even clearing out the image src prior to applying it again will not work. so here
            // we guarantee that the filename is always unique
            const lastSlash = photoPath.lastIndexOf('/');
            const imageDirectory = photoPath.substring(0, lastSlash);
            const originalFile = photoPath.substring(lastSlash + 1);
            const newFile = `${Utils.generateUniqueId()}.jpg`;

            FileIo.renameFile(imageDirectory, originalFile, newFile)
              .then(() => {
                resolve(Action.createSuccessOutcome({ filePath: `${imageDirectory}/${newFile}` }));
              })
              .catch((msg) => {
                resolve(Action.createFailureOutcome('Failed to take photo', new Error(msg)));
              });
          },
          // failure
          (msg) => {
            resolve(Action.createFailureOutcome('Failed to take photo', new Error(msg)));
          },
          cameraOptions);
      });
    }

    /**
     * Internal utility method to use cordova-plugin-camera for Android. Executes cordova-camera to take picture and
     * converts it into a Blob to resolve it as file outcome.
     *
     * @private
     * @param accept
     * @param params
     * @returns {Promise}
     */
    static androidUseCameraWithCordovaPlugin(accept, params) {
      return TakePhotoAction.performLegacy(params)
        .then(outcome => Utils.readBlob(outcome.result.filePath)
          .then(fileBlob => Action.createSuccessOutcome({ file: fileBlob })));
    }

    /**
     * Android needs special handling because Html file element does not offer Camera.
     * If mediaType is image,
     * This method shows oj-menu with three options Camera, Browse and Cancel.
     * If Camera is selected, cordova-plugin-camera is used to take picture from Camera.
     * If Browse option is selected Html file element is used to select a file.
     *
     * If mediaType is not image, then file selection screen appears directly to chose video files.
     *
     * @private
     * @param accept calculated values based on mediaType parameter. This is set as accept attribute of file element.
     * @param params
     * @returns {*}
     */
    static androidPerform(accept, params) {
      return new Promise((resolve) => {
        if (accept === 'image/*') {
          const menu = document.getElementById(ANDROID_MENU_ID);
          // save reference to resolve method so that this Promise will be resolved after
          // a selection is made from this menu.
          menu.setProperty('resolver', resolve);
          menu.open();
        } else {
          // for non-image, let it go to default file element click.
          resolve(ANDROID_MENU_OPTION_BROWSE);
        }
      }).then((option) => {
        switch (option) {
          case ANDROID_MENU_OPTION_CAMERA:
            return TakePhotoAction.androidUseCameraWithCordovaPlugin(accept, params);

          case ANDROID_MENU_OPTION_BROWSE:
            return TakePhotoAction.webPerform(accept);

          default:
            return Action.createFailureOutcome('Take Photo Action cancelled');
        }
      });
    }

    /**
     * Internal method to do image capture using HtmlMediaCapture. This creates an html input file element and triggers
     * the action by click() method. Once the File comes back from the open dialog, it is wrapped into a Blob and
     * resolved as &quot;file&quot; outcome.
     *
     * @private
     * @param accept - calculated values based on mediaType parameter. This is set as accept attribute of file element.
     * @returns {Promise}
     */
    static webPerform(accept) {
      return new Promise((resolve) => {
        // just invoke input element
        const fileElement = document.createElement('input');
        fileElement.type = 'file';
        fileElement.accept = accept;

        // For the file input element, onchange is not triggered if user cancels the file dialog. But the value is set
        // to null. The following onfocus() is a workaround to identify the cancel on file dialog.
        // In document.onfocus check if the file element value is null, if so the dialog was cancelled.
        // But there is some delay on setting the file element value by browsers. This 1000ms delay solves it.
        const bfocus = document.body.onfocus;
        document.body.onfocus = () => {
          setTimeout(() => {
            document.body.onfocus = bfocus; // restore document.body focus handler
            if (!fileElement.value) { // cancel clicked
              resolve(Action.createFailureOutcome('Take Photo Action cancelled'));
            }
          }, 1000);
        };

        fileElement.onchange = () => {
          document.body.onfocus = bfocus; // restore document.body focus handler
          // ServiceWorker thread cannot access File of Main thread. It needs to be converted into a Blob.
          const fileBlob = Utils.fileToBlob(fileElement.files[0]);
          resolve(Action.createSuccessOutcome({ file: fileBlob }));
        };

        fileElement.click();
      });
    }

    /**
     *
     * @private
     * @param params
     * @returns {Promise}
     */
    static performHtmlMediaCapture(params) {
      let mediaType = params.mediaType;
      if (!mediaType) {
        // WebApps or PWA did not have TakePhoto Action.
        // Either a MobileApp is getting executed in PWA mode or WebApp did not set mediaType.
        mediaType = 'image'; // Use default mediaType
      }
      mediaType = mediaType.toLowerCase();

      const accept = `${MEDIA_TYPES.includes(mediaType) ? mediaType : 'image'}/*`;

      // On Android HtmlMediaCapture does not support camera.
      // An oj-menu is displayed with Camera & Browse options
      if (Utils.isAndroid()) {
        return TakePhotoAction.androidPerform(accept, params);
      }

      return TakePhotoAction.webPerform(accept);
    }

    /**
     * Removes intermediate image files that are kept in temporary storage after calling camera.getPicture.
     * Applies only when the value of Camera.sourceType equals Camera.PictureSourceType.CAMERA and the
     * Camera.destinationType equals Camera.DestinationType.FILE_URI.  Only applicable to iOS.
     *
     * @private
     */
    static cleanupCallback() {
      if (Utils.isIos()) {
        navigator.camera.cleanup(
          function onSuccess() {
            logger.log('Camera cleanup succeeded.');
          },
          function onFail(message) {
            logger.error('Failed to perform camera cleanup: ', message);
          }
        );
      }
    }
  }

  return TakePhotoAction;
});

