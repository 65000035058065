'use strict';

define('vb/private/helpers/eventHelper',[
  'vb/private/constants',
  'vb/private/utils',
  'vb/private/stateManagement/router',
  'vb/action/eventAction',
  'vb/action/builtin/fireNotificationEventAction',
  'vb/action/builtin/fireCustomEventAction',
], (Constants, Utils, Router, EventAction, FireNotificationEventAction, FireCustomEventAction) => {
  /**
   * A set of helper routines that require a context and are used by built-in actions.
   * The context is hidden using a closure.
   *
   */
  class EventHelper {
    /**
     * @param context passed from Container.
     */
    constructor(context) {
      this.target = null; // default behavior for no target is 'current'

      // context is private via closure
      Object.assign(this, {
        /**
         * Fire a custom event, with the given payload
         * @param name
         * @param payload
         * @returns {Promise<any>} resolves immediately, does not wait for event propagation. or rejects on error
         */
        fireCustomEvent: (name, payload) => Promise.resolve()
          .then(() => {
            if (!context || !context.container) {
              throw new Error('unable to fire event; no active container context');
            }

            if (!FireCustomEventAction.isValid(name)) {
              throw new Error(`attempt to raise invalid event: ${name}`);
            }

            const promiseOrNull = EventAction.fireEvent(context.container, name, payload, { target: this.target });
            return promiseOrNull || Promise.reject(new Error(`Unable to find target: ${this.target}`));
          }),

        /**
         * @param options { summary, message, displayMode, type, key }
         * these are the same as the parameters to the FireEventNotificationAction
         * - summary: {string} event title/summary
         *  - message: {string}event text
         *  - displayMode: {string} 'persist', 'transient'
         *  - type: {string} 'error', 'warning', 'confirmation', 'info'
         *  - key: {string} optional, if not provided, a 9 character alphanumeric UID is generated randomly.
         * @returns {*|Promise.<any>}
         */
        fireNotificationEvent: (options) => {
          const payload = FireNotificationEventAction.createPayload(options);
          return this.fireCustomEvent(Constants.NOTIFICATION_EVENT, payload);
        },

        /**
         * set the 'target' container, for the start of event propagation (events bubble 'up' the containment).
         * @param target current/leaf, specifies which container the event should 'start' bubbling from
         * @returns {EventHelper} for chaining
         */
        target: (target) => {
          this.target = target;
          return this;
        },
      });
    }
  }

  return EventHelper;
});

