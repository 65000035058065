'use strict';

define('vb/private/stateManagement/containerRjsInitMixin',[
  'vb/private/configLoader',
], (ConfigLoader) => {
  /**
   * Mixin that should be used by all containers that allow requirejs segment in their definition.
   *
   * It loads requirejs configuration after the container is loaded.
   */
  const ContainerRjsInitMixin = (superclass) => class extends superclass {
    constructor(...args) {
      super(...args);
      this.containerRjsConfig = {};
    }

    /**
     * Property indicating if the container paths inside resource folder should
     * implicitly be exposed to the rest of the container modules.
     * @returns {boolean}
     */
    // eslint-disable-next-line class-methods-use-this
    get implicitResourceFolder() {
      return false;
    }

    /**
     * Override, loading of container dependencies to support "requirejs" declaration.
     *
     * @returns {Promise}
     */
    loadContainerDependencies() {
      this.initializeRequirejsMappings();
      return super.loadContainerDependencies();
    }

    /**
     * For "requirejs" declaration, allow adding new "paths" to requireJS
     * as well as default mapping of 'resources' folder.
     */
    initializeRequirejsMappings() {
      const requirejsDecl = this.definition && this.definition.requirejs;
      if (requirejsDecl || this.implicitResourceFolder) {
        // save the RequireJS configuration so we can use it to resolve paths
        // when importing modules in the container
        this.containerRjsConfig = ConfigLoader.addRequirejsConfigFromContainer(this, this.implicitResourceFolder);
      }
    }
  };

  return ContainerRjsInitMixin;
});

