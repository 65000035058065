'use strict';

define('vb/private/stateManagement/context/extensionContext',[
  'vb/private/stateManagement/context/containerContext',
  'vb/private/constants',
  'vb/private/translations/translationContext',
], (ContainerContext, Constants, TranslationContext) => {
  /** @type Object */
  const symbol = Symbol('extension-accessor');

  const builtinProperties = [
    Constants.APPLICATION_USER_VARIABLE,
    'builtinUtils',
    Constants.RESPONSIVE_CONTEXT,
    Constants.PROFILE_CONSTANT,
    Constants.DEPLOYMENT_CONSTANT,
    Constants.INIT_PARAM_CONTEXT,
    Constants.PATH_VARIABLE,
    Constants.CURRENT_PAGE_VARIABLE,
    Constants.INFO_CONTEXT,
  ];

  /**
   * Set of properties common for pageExtension and flowExtension
   */
  class ExtensionContext extends ContainerContext {
    /**
     * @param {Object} extension the extension container
     */
    constructor(extension) {
      super(extension);

      let appExpressionContext;
      let appUiExpressionContext;

      Object.defineProperty(this, symbol,
        {
          value: {
            base: extension.base,
            // When the expressionContext is created, absoluteUrl can be resolve so just get the value immediately
            absoluteUrl: extension.absoluteUrl,
            // accessor for the base application context
            get application() {
              if (!appExpressionContext) {
                const extensionApplication = extension.base.application.extensions[extension.extensionId];
                if (extensionApplication) {
                  // For every extension container, the application object is a combination of the properties
                  // in the application extension and the properties exposed in the base application interface.
                  // Note that true is passed into createExpressionContextFromExtension to merged in the properties
                  // from the base application.
                  const extensionExpressionContext = extensionApplication.expressionContext;
                  const baseExpressionContext = extensionExpressionContext.base;
                  appExpressionContext = {};

                  // variables, constants and events are the only properties in interface
                  ['variables', 'constants', 'enums', 'events'].forEach((scope) => {
                    let mergedScope;

                    // merge in the properties from the base context
                    Object.defineProperty(appExpressionContext, scope, {
                      get() {
                        // only create the mergedScope when it's first accessed to avoid accessing
                        // property descriptors too early
                        if (!mergedScope) {
                          mergedScope = {};
                          let ctx = extensionExpressionContext[scope] || [];
                          Object.keys(ctx).forEach((name) => {
                            const def = Object.getOwnPropertyDescriptor(ctx, name);
                            Object.defineProperty(mergedScope, name, def);
                          });

                          ctx = baseExpressionContext[scope] || [];
                          Object.keys(ctx).forEach((name) => {
                            const def = Object.getOwnPropertyDescriptor(ctx, name);
                            Object.defineProperty(mergedScope, name, def);
                          });
                        }
                        return mergedScope;
                      },
                    });
                  });

                  // look up variables from extension first and then base extension
                  appExpressionContext.getVariable = function (...args) {
                    return extensionExpressionContext.getVariable(...args)
                      || baseExpressionContext.getVariable(...args);
                  };

                  // expose functions defined in app ui extension
                  // RESOLVE: Functions should not be exposed on $global. However, it breaks V1 extension
                  // if we don't expose it here.
                  Object.defineProperty(appExpressionContext, 'functions', {
                    get() {
                      return extensionExpressionContext.functions;
                    },
                  });

                  // Propagate all builtins properties from the application base context
                  builtinProperties.forEach((name) => {
                    Object.defineProperty(appExpressionContext, name, {
                      get() {
                        return baseExpressionContext[name];
                      },
                    });
                  });
                } else {
                  appExpressionContext = {};
                }
              }
              return appExpressionContext;
            },
            // accessor for the app ui context which is only available if accessed from within an app ui
            get appUi() {
              if (appUiExpressionContext === undefined) {
                if (extension.base.package) {
                  // need to initialize the context even if there is no app-x so it doesn't switch over to
                  // the global context
                  appUiExpressionContext = {};

                  const extensionAppUi = extension.base.package.extensions[extension.extensionId];
                  if (extensionAppUi) {
                    const extensionExpressionContext = extensionAppUi.expressionContext;

                    // expose the following properties
                    ['variables', 'constants', 'enums', 'events', 'functions'].forEach((scope) => {
                      Object.defineProperty(appUiExpressionContext, scope, {
                        get() {
                          return extensionExpressionContext[scope];
                        },
                      });
                    });

                    appUiExpressionContext.getVariable = (...args) => extensionExpressionContext.getVariable(...args);

                    // Propagate all builtins properties from the application context
                    const appContext = this.application;
                    builtinProperties.forEach((name) => {
                      Object.defineProperty(appUiExpressionContext, name, {
                        get() {
                          return appContext[name];
                        },
                      });
                    });
                  }
                } else {
                  appUiExpressionContext = null;
                }
              }
              return appUiExpressionContext;
            },
            get bundles() {
              return extension.bundles;
            },
          },
        });
    }

    /**
     * BaseContextType property: The type of the baseContext object used for $base in expression.
     * This is used by super class ContainerContext to construct the baseContext object.
     *
     * Implemented by subclass pageExtensionContext and flowExtensionContext
     */
    static get BaseContextType() {
      throw new Error('Base context constructor undefined');
    }

    /**
     * base property
     * The object returned when an expression has $base (See $base property in getAvailableContexts)
     * @type {Object}
     */
    get base() {
      return this[symbol].base.expressionContext.baseContext;
    }

    /**
     * extension property
     * The object returned when an expression has $extension (See $extension property in getAvailableContexts)
     * @type {Object}
     */
    get extension() {
      const extension = {
        base: this.base,
        [Constants.PATH_VARIABLE]: this[symbol].absoluteUrl,
      };

      // $extension.<extId>.translations
      if (this[symbol].bundles) {
        TranslationContext.addExtensionsContexts(this[symbol].bundles, extension);
      }

      return extension;
    }

    /**
     * Accessor for the application
     * @type {Object}
     */
    get application() {
      return this[symbol].application;
    }

    /**
     * Accessor for the the app ui which is only available if accessed from within an app ui.
     * @type {Object}
     */
    get appUi() {
      return this[symbol].appUi;
    }

    /**
     * see ContainerContext
     *
     * @param extension
     * @returns {Object}
     */
    static getAvailableContexts(extension) {
      // This part populate $variables, $constants, etc...
      const availableContexts = super.getAvailableContexts(extension);

      Object.defineProperties(availableContexts, {
        $application: {
          enumerable: true,
          configurable: true,
          get() {
            // appUi is only available if accessed from within an app ui
            return extension.expressionContext.appUi || extension.expressionContext.application;
          },
        },
        $extension: {
          enumerable: true,
          configurable: true,
          get() {
            return extension.expressionContext.extension;
          },
        },
        $base: {
          enumerable: true,
          configurable: true,
          get() {
            return extension.expressionContext.base;
          },
        },
      });

      return availableContexts;
    }
  }

  return ExtensionContext;
});

