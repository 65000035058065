'use strict';

define(
  'vb/private/mobile/security/security',['vb/private/mobile/security/strategyOpenidOAuthIdm',
    'vb/private/mobile/security/strategyBasicAuthIdm',
    'vb/private/mobile/security/strategyOAuthIdm'],
  (StrategyOpenidOAuthIdm, StrategyBasicAuthIdm, StrategyOAuthIdm) => {
    /**
     * Routines for security protocols and for securely storing sensitive information.
     */
    const Security = {};

    Security.AUTHENTICATION_TYPE = {
      ORACLE_CLOUD: 'oraclecloud',
      BASIC: 'basic',
      OAUTH: 'oauth',
    };

    // the particular security strategy for this app (see Security.initialize())
    let securityStrategy = null;

    /**
     * Initializes the security.
     *
     * @param securityMetadata holds all security related metadata needed to perform a login
     * @return promise resolves if successfully initialized, rejects otherwise
     */
    Security.initialize = (securityMetadata) => {
      if (!securityMetadata || !securityMetadata.authentication) {
        return Promise.reject(new Error('No security configuration has been provided, switching to anonymous mode'));
      }

      const authType = securityMetadata.authentication.type;

      switch (authType) {
        case Security.AUTHENTICATION_TYPE.ORACLE_CLOUD:
          securityStrategy = new StrategyOpenidOAuthIdm(securityMetadata.idcsInfo);
          break;

        case Security.AUTHENTICATION_TYPE.BASIC:
          securityStrategy = new StrategyBasicAuthIdm(securityMetadata.authentication.options);
          break;

        case Security.AUTHENTICATION_TYPE.OAUTH:
          securityStrategy = new StrategyOAuthIdm(securityMetadata.idcsInfo);
          break;

        default:
          return Promise.reject(new Error('No security configuration has been provided, switching to anonymous mode'));
      }

      return securityStrategy.init();
    };

    /**
     * Retrieves the current security strategy.
     * @return Promise resolves with the currently defined security strategy or rejects otherwise
     */
    Security.getSecurityStrategy = () => {
      if (!securityStrategy) {
        return Promise.reject(new Error('Security has not been initialized.'));
      }

      return Promise.resolve(securityStrategy);
    };

    /**
     * Shows the login screen for the given application. After a successful login, the user will
     * be taken to the app.
     *
     * If the user is logged in successfully, this will resolve, otherwise this will reject.
     *
     * If the user is already logged in, this will just resolve immediately.
     *
     * @return Promise
     */
    Security.login = () => Security.getSecurityStrategy().then(strategy =>
      // first see if the user is already logged in
      strategy.isUserLoggedIn()
        // user not logged in
        .catch(() => securityStrategy.loginToApplication()));

    /**
     * Logs the user out of the app.
     * @return promise resolves if the logout was successful, rejects otherwise
     */
    Security.logout = () => Security.getSecurityStrategy().then(strategy => strategy.logout());

    /**
     * Returns headers required to make a secure call with the current strategy.
     *
     * @return promise a map of required headers
     */
    Security.getHeaders = () => Security.getSecurityStrategy().then(strategy => strategy.getHeaders());

    return Security;
  });

