// eslint-disable-next-line max-classes-per-file
/* eslint max-classes-per-file: ["error", 4] */

'use strict';

define('vb/private/types/capabilities/fetchByKeysUtils',[
  'vb/private/log',
  'vb/private/constants',
  'vb/private/utils',
  'vb/private/types/dataProviderConstants',
  'vb/private/types/utils/dataProviderUtils',
  'vb/private/types/capabilities/fetchContext'],
(Log, Constants, Utils, DPConstants, DataProviderUtils, FetchContext) => {
  const FETCH_BY_KEYS_PARAMS = [
    /**
     * Optional attributes (aka RT filtered fields) to include in the result. If specified,
     * then 'at least' these set of attributes will be included in each row in the data array
     * in the FetchListResult. If not specified then the default attributes will be included.
     * If the value is a primitive then this is ignored.
     * Expressions like "!" and "@default" are also supported. e.g. ['!lastName', '@default'] for
     * everything except 'lastName'. For only 'firstName' and 'lastName' we'd have ['firstName',
     * 'lastName']. Order does not matter when @default is used with field exclusions "!".
     * This can be nested. e.g. ['!lastName', '@default', {name: 'location', attributes:
     * ['address line 1', 'address line 2']}]
     *
     * Examples:
     * For a employee object with a department (1:1 relationship with employee):
     * 1. array of primitives
     * attributes: ['id', 'firstName', 'lastName'] // id, firstName, lastName
     * attributes: ['id', 'firstName', '!lastName', 'email'] // id, firstName, email only
     * attributes: ['id', 'firstName', '!lastName', '@default', 'email'] // all fields except
     *    lastName, which is more than the requested attributes
     * attributes: ['id', 'firstName', '!lastName', 'email', '@default',
     *   { name: 'dept', attributes: [ 'id', 'deptName' ] } ]
     *
     * @type {Array<string|FetchAttribute>|null}
     * @see http://jet.us.oracle.com/6.2.0/tsdocs/oj.FetchListParameters.html
     * @see http://jet.us.oracle.com/6.2.0/tsdocs/oj.FetchAttribute.html
     * @since JET 6.2
     */
    'attributes',

    /**
     * Set of keys for rows to fetch
     * @type Set<any>
     */
    'keys',
  ];
  const FetchByKeysParamsMap = {
    KEYS: 'keys',
  };

  /**
   * Duck types ItemMetadata.
   */
  class FetchByKeysResultItemMetadata {
    constructor(key) {
      this.key = key;
    }
  }

  /**
   * A class that duck types oj.Item that is set on the FetchByKeysResult which
   * essentially is of the form
   * {
   *   fetchParameters: oj.FetchByParameters,
   *   results: {
   *     key: oj.Item
   *   }
   * }
   */
  class FetchByKeysItemResult {
    constructor(data, metadata) {
      this.data = data;
      this.metadata = metadata;
    }
  }

  class FetchByKeysResult {
    constructor(params, resultsMap) {
      this.fetchParameters = params;
      this.results = resultsMap;
    }
  }

  const isImplementationLookup = function (cap) {
    const impl = cap[DPConstants.CapabilityKeys.FETCH_BY_KEYS_IMPLEMENTATION];
    return (impl === DPConstants.CapabilityValues.FETCH_BY_KEYS_IMPLEMENTATION_LOOKUP);
  };
  const isImplementationIteration = function (cap) {
    const impl = cap[DPConstants.CapabilityKeys.FETCH_BY_KEYS_IMPLEMENTATION];
    return (impl === DPConstants.CapabilityValues.FETCH_BY_KEYS_IMPLEMENTATION_ITERATION);
  };

  const isMultiKeyLookup = function (cap) {
    const multiKeyLookup = cap[DPConstants.CapabilityKeys.FETCH_BY_KEYS_MULTI_KEY_LOOKUP];
    return multiKeyLookup === true;
  };

  /**
   * Object that implements the FetchByKeys contract, both making a fetch call and building
   * results expected by callers of fetchByKeys. See JET oj.DataProvider for details.
   */
  class FetchByKeysUtils {
    /**
     *  prune options to just what supported on oj.FetchListParameters; also when callers
     *  provides more than one key in via 'keys' parameter use just the first.
     *
     * @param {ServiceDataProvider} sdp
     * @param {object=} options to control fetch
     * @property {number} options.keys Set of keys to fetch
     * @return {Object?} a compound object which contains an array of row data objects, an array of ids, and the
     * startIndex triggering done when complete.<p>
     *
     * @static
     */
    static whiteListFetchOptions(sdp, options) {
      let o;

      if (options) {
        o = {};
        FETCH_BY_KEYS_PARAMS.forEach((param) => {
          const paramValue = options[param];
          if (paramValue !== undefined) {
            const cap = sdp.getCapability(DPConstants.CapabilityType.FETCH_BY_KEYS);
            if (param === FetchByKeysParamsMap.KEYS) {
              // log error when no keys are provided for any implementation type
              if (cap && (isImplementationLookup(cap) || isImplementationIteration(cap))
                && (!paramValue
                || (paramValue && Array.isArray(paramValue) && paramValue.length === 0))) {
                sdp.log.error('FetchByKeys called on ServiceDataProvider', sdp.id,
                  'with no keys when the SDP variable is configured with a fetchByKeys capability',
                  'and a lookup based implementation');
              }

              // log warning if multi key lookup is not supported and multiple keys are
              // provided. we allow multi keys but a single fetchByKeys call for every key.
              if (cap && isImplementationLookup(cap) && !isMultiKeyLookup(cap)
                && paramValue && Array.isArray(paramValue) && paramValue.length > 1) {
                sdp.log.warn('FetchByKeys called on ServiceDataProvider', sdp.id,
                  'that only allows single key lookup');
              }
            }
            o[param] = paramValue;
          }
        });
      }
      return o;
    }

    /**
     * Build the final result expected - oj.FetchByKeysResult
     * @param {FetchContext} fetchContext has value and defaultValue properties
     * @param {Object} result of the fetch
     * @returns {*}
     */
    static buildFetchResult(fetchContext, result) {
      const jsonData = result.body;
      const { itemsPath } = fetchContext.sdpState.value;
      const options = fetchContext.fetchOptions;
      const resultsMap = new Map();

      const buildResultsMap = function (itemData) {
        if (itemData) {
          const itemMetadata = FetchByKeysUtils.getItemMetadata(fetchContext, itemData);
          if (itemMetadata) {
            const itemResult = new FetchByKeysItemResult(itemData, itemMetadata);
            resultsMap.set(itemMetadata.key, itemResult);
          }
        }
      };

      const itemsData = DataProviderUtils.getObjectByPath(jsonData, itemsPath);
      if (itemsData && Array.isArray(itemsData)) {
        itemsData.forEach((itemData) => {
          buildResultsMap(itemData);
        });
      } else {
        buildResultsMap(itemsData);
      }
      return new FetchByKeysResult(options, resultsMap);
    }

    static getItemMetadata(fetchContext, item) {
      const { sdpState } = fetchContext;
      const { sdp } = fetchContext;
      const idAttr = sdpState.value[sdp.getIdAttributeProperty()];
      const options = fetchContext.fetchOptions;

      // this will return a helper that returns object attributes OR indices as the key
      const idHelper = DataProviderUtils.getIdAttributeHelper(idAttr);
      const key = idHelper.getKey(item);
      if (key) {
        // compare the key in the response with the keys provided in the fetch, to locate the right key instance to
        // use in the itemMetadata
        let keyFoundInResult = false;
        const fokIter = options.keys.values();
        let foKey;
        do {
          foKey = fokIter.next().value;
          keyFoundInResult = idHelper.compare(foKey, key);
        } while (!keyFoundInResult && foKey);

        if (foKey) {
          return new FetchByKeysResultItemMetadata(foKey);
        }
      }
      // if we are here it means the item does not have a key value or no keyAttributes was set, or we are dealing with
      // indices.
      if (idHelper.idAttr !== DPConstants.DataProviderIdAttribute.AT_INDEX) {
        fetchContext.log.warn('Either keyAttributes was not set or item does not have key values that can be used to',
          ' construct the key value using the keyAttributes!');
      } else {
        fetchContext.log.warn('keyAttributes is set to @index which cannot be used as a key to fetch data!');
      }
      return null;
    }

    /**
     * If paging options are configured on the SDP or provided by caller use it. For paginate
     * options that might be coming from an externalized RestAction see reconcileTransformOptions
     * @return {{offset: *, size: *}}
     */
    static getPaginateOptions(fetchContext) {
      const { sdpState } = fetchContext;
      const fetchOpts = fetchContext.fetchOptions || {};
      const sdpValue = sdpState.value;

      let variablePCOffset;
      let variablePCSize;
      if (FetchContext.usePropValue('pagingCriteria', sdpValue)) {
        const variablePC = sdpValue.pagingCriteria || {};
        variablePCOffset = variablePC.offset;
        variablePCSize = variablePC.size;
      }
      let size = fetchOpts.size >= -1 ? fetchOpts.size : variablePCSize;
      let offset = fetchOpts.offset >= 0 ? fetchOpts.offset : variablePCOffset;

      if ((size === 0 || size) || (offset === 0 || offset)) {
        // if either size or offset are set to valid values, which includes 0, then set defaults
        // for the other if it's undefined. we want both size and offset to have valid values
        size = size >= -1 ? size : FetchContext.DEFAULT_SIZE; // size -1 can be set by caller
        offset = offset >= 0 ? offset : FetchContext.DEFAULT_OFFSET;
      }

      const pagingCriteria = { offset, size };
      return pagingCriteria;
    }

    /**
     * Reconcile transform options for pagination with options from other sources such as
     * RestAction.
     *
     * @param transformOptions transform options as determined by this FetchContext from fetch call
     * and SDP defaults.
     * @param restTransformOptions transform options from rest
     * @param superRO reconciled transform options as determined by superClass of fetchContext
     * instance. See caller of this method for its super
     *
     * @return {*} reconciled options
     */
    static reconcileTransformOptions(transformOptions, restTransformOptions, superRO) {
      /**
       * @type {Object|{ size: number, offset: number }}
       */
      let recpo;
      let size;
      let offset;
      const reconciledOptions = superRO;

      // (1) fix up pagination options. If we are dealing with externalized fetch chain then
      // it's quite possible for the the RestAction to have options set
      // (requestTransformOptions).
      const tpo = transformOptions.paginate; // transform options we have so far. getPaginateOptions
      const rtpo = restTransformOptions.paginate;

      if (!tpo.size && tpo.size !== 0) {
        size = rtpo && rtpo.size;
      }
      if (!tpo.offset && tpo.offset !== 0) {
        offset = rtpo && rtpo.offset;
      }

      if ((size === 0 || size) || (offset === 0 || offset)) {
        // if either size or offset are set to valid values, which includes 0, then set defaults
        // for the other if it's undefined. we want both size and offset to have valid values

        recpo = recpo || {};
        recpo.size = size >= -1 ? size : FetchContext.DEFAULT_SIZE; // size -1 can be set by caller
        recpo.offset = offset >= 0 ? offset : FetchContext.DEFAULT_OFFSET;
      }

      if (recpo) {
        reconciledOptions.paginate = recpo;
      }

      return reconciledOptions;
    }
  }

  return FetchByKeysUtils;
});

