'use strict';

define('vb/private/stateManagement/context/fragmentContext',[
  'vb/private/constants',
  'vb/private/stateManagement/context/containerContext',
  'vb/private/stateManagement/context/fragmentBaseContext',
  'vb/helpers/componentFinder'],
(Constants, ContainerContext, FragmentBaseContext, ComponentFinder) => {
  /** @type Object */
  const symbol = Symbol('fragment-accessor');
  /**
  * set of properties to expose in $fragment
  */
  class FragmentContext extends ContainerContext {
    /**
    * @param {Fragment} frag fragment instance
    */
    constructor(frag) {
      super(frag);

      const accessors = {
        [Constants.COMPONENTS_CONTEXT]: () => ComponentFinder, // VBS-25776, cannot remove this because FA uses it
      };

      Object.keys(accessors).forEach((accessorName) => {
        Object.defineProperty(this, accessorName, {
          enumerable: true,
          configurable: true,
          get: accessors[accessorName],
        });
      });

      Object.defineProperty(this, symbol,
        {
          value: {
            /**
             * Shortcut to retrieve the builtins variable map
             */
            get builtins() {
              return frag.scope.variableNamespaces[Constants.VariableNamespace.BUILTIN];
            },
            get fragment() {
              return frag;
            },
          },
        });
    }

    static get BaseContextType() {
      return FragmentBaseContext;
    }

    /**
     * $fragment.info
     */
    get [Constants.INFO_CONTEXT]() {
      return this[symbol].builtins[Constants.INFO_CONTEXT];
    }

    /**
     * see ContainerContext. Returns all available contexts within a fragment. At this point it's just what the
     * containerContext provides in addition to $fragment
     *
     * @param fragment
     * @returns {{$application: *, $variables, $metadata, $fragment}}
     */
    static getAvailableContexts(fragment) {
      const availableContexts = super.getAvailableContexts(fragment);

      Object.defineProperties(availableContexts, {
        // everything from here down used to only be created when the Page's Scope was created
        // now, we create the object once, up front, and rely on getters to allow deferred assignment
        // of the expressionContext.  But, because Expression reads all the values, regardless of what is used
        // in the current expression, the properties may be read before expressionContext is created,
        // so we guard against expressionContext being null.  Should not be a problem,
        // since the null pre-Scope values cannot be meaningfully referenced anyway.
        $fragment: {
          enumerable: true,
          configurable: true,
          get: () => fragment.expressionContext,
        },
      });

      // we need this because parent is going through package
      if (fragment.extension) {
        // Remove properties added by container context that should not be part of the fragment context.
        // $application in package refers to appUI that fragment cannot access
        if (fragment.extension.id !== Constants.ExtensionFolders.BASE) {
          delete availableContexts.$application;
        }

        Object.defineProperties(availableContexts, {
          // $extension.path
          $extension: {
            enumerable: true,
            configurable: true,
            value: {
              get [Constants.PATH_VARIABLE]() {
                // eslint-disable-next-line max-len
                return fragment.absoluteUrl;
              },
            },
          },
        });
      }

      // this is needed to allow fragment event to bubble up to oj-vb-fragment CCA. '$dispatchEvent' is a mechanism
      // setup by dynamic components to be notified of events fired from layout. The same mechanism is used with
      // fragment. When this method is called we are in the context of the fragment, and the event is fired on
      // element which is rendered on parent context
      availableContexts[Constants.ContextNameInternal.DISPATCH_EVENT] = (event) => {
        // for some reason dispatching event on element from the inner context is required.
        const elem = document.getElementById(fragment.id);
        elem.dispatchEvent(event);
      };

      return availableContexts;
    }
  }

  return FragmentContext;
});

