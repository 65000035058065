'use strict';

/**
 * Base class for security protocols routines and securely storing sensitive information.
 */
define('vb/private/mobile/security/strategy',['vbsw/private/constants', 'vb/private/log', 'vb/private/utils'], (SwConstants, Log, Utils) => {
  const VB_CONFIG = window.vbInitConfig || {};

  class Strategy {
    constructor(securityMetadata) {
      this.log = Log.getLogger('vb/private/mobile/security/strategy');
      this.idmAuthFlows = window.cordova.plugins.IdmAuthFlows;

      // making sure the app name is unique:
      this.appName = VB_CONFIG.APP_ID ? VB_CONFIG.APP_ID : '';
      this.appName += VB_CONFIG.APP_VERSION ? VB_CONFIG.APP_VERSION : '';
      this.appName += VB_CONFIG.APP_PATH ? VB_CONFIG.APP_PATH : '';

      // in some cases the app name above could be too long for the IDM plugin
      // to handle correctly for storing data in the credential store on Android
      // in order to not break existing apps, the auth key can be used instead
      // to store secure data in the credential store by the IDM plugin:
      this.authKey = Utils.generateUniqueId();

      this.idcsHostName = securityMetadata.hostName;
      this.authenticationFlow = null;
      this.allowedScopes = [];
    }

    /**
     * Initializes this security strategy.
     *
     * @param authProps An object containing configuration for authentication constructed by one of the sub classes
     * @return promise Resolves when complete
     */
    init(authProps = {}) {
      return this.idmAuthFlows.init(authProps).then((authenticationFlow) => {
        this.authenticationFlow = authenticationFlow;
      });
    }

    /**
     * Shows the login screen for the given application. After a successful login, the user will
     * be taken to the app.  Should be overriden by the sub classes.
     *
     * @return promise resolves if the login was successful, rejects otherwise
     */
    // eslint-disable-next-line class-methods-use-this
    loginToApplication() {
      return Promise.reject(new Error('loginToApplication needs to be implemented by each subclass'));
    }

    /**
     * Returns a promise that resolves if the user is already logged into the app, or false
     * if they are not.
     *
     * @return promise resolves if the user is logged in, rejects otherwise
     */
    isUserLoggedIn() {
      if (!this.authenticationFlow) {
        return Promise.reject(new Error('init must be invoked prior to calling isUserLoggedIn'));
      }

      return this.authenticationFlow.isAuthenticated().then((authenticated) => {
        if (!authenticated) {
          throw new Error('User is not authenticated');
        }

        return true;
      });
    }

    /**
     * Logs the user out of the app.
     *
     * @return promise resolves if the logout was successful, rejects otherwise
     */
    logout() {
      if (!this.authenticationFlow) {
        return Promise.reject(new Error('init must be invoked prior to calling logout'));
      }

      return this.authenticationFlow.logout().then(() => {
        this.authenticationFlow = null;
      });
    }

    /**
     * Helper to retrieve the headers based on the provided scopes.
     * @private
     * @return promise a map of required headers
     */
    retrieveHeaders() {
      // cache the promise so that it's reused by all calls to get the headers until
      // the user is no longer authenticated
      if (!this.getHeadersPromise) {
        const options = { fedAuthSecuredUrl: null, oauthScopes: this.allowedScopes };
        this.getHeadersPromise = this.authenticationFlow.getHeaders(options)
          .then((headers) => headers || {});
      }

      return this.getHeadersPromise;
    }

    /**
     * Returns headers required to make a secure call with this protocol.
     *
     * @return promise a map of required headers
     */
    getHeaders() {
      if (!this.authenticationFlow) {
        return Promise.reject(new Error('init must be invoked prior to calling getHeaders'));
      }

      return this.authenticationFlow.isAuthenticated()
        .then((isAuthenticated) => {
          if (isAuthenticated) {
            // user is authenticated, so return headers:
            return this.retrieveHeaders();
          }
          // user is not authenticated, so re-direct user to login and re-set the headers promise:
          this.getHeadersPromise = null;
          return this.loginToApplication()
          // retrieve headers after login
            .then(() => this.retrieveHeaders());
        });
    }
  }

  return Strategy;
});

