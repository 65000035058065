'use strict';

define('vb/action/actions',['vb/private/action/actionChainUtils', 'vb/private/utils'], (ActionChainUtils, Utils) => {
  /**
   * API for invoking various actions in a code-based action chain.
   */
  class Actions {
    /**
     * This action method assigns a value to a VB variable. The following is an example of a parameters
     * object:
     *
     * {
     *   variable: '$page.variables.pageVar',
     *   value: 'some value',
     *   auto: 'ifNoMapping',
     *   reset: 'toDefault',
     *   mapping: {...}
     * }
     *
     * @param {Object} context the context object for the action chain
     * @param {Object} parameters
     * @param {String} parameters.variable the fully qualified variable name, e.g., $page.variables.pageVar
     * @param {String} parameters.value the value to assign
     * @param {String} parameters.auto controls whether to auto-assign all properties from the given value to
     * the corresponding properties on the variable. If auto is set to 'ifNoMapping', auto-assignment will
     * only be performed if no mapping is provided. If auto is set to 'always', the default, auto-assignment
     * will always be performed first before any mapping is applied.
     * @param {String} parameters.reset can be either be 'none' (the default), 'toDefault', or 'empty'. The
     * toDefault' option means that the action will first reset the variable to its default value before
     * assignment. The 'none' option means to override the existing value. The 'empty' option means empty
     * out the variable before assignment.
     * @param {Object} parameters.mapping metadata used to provide fine-grained control over what gets assigned
     * from the source to the target. (optional)
     * @param {Object} options
     * @param {String} options.id an optional identifier for the action
     * @returns {Promise<*>} returns undefined if successful and throws an error, otherwise
     */
    static async assignVariable(context, parameters, options) {
      const clonedParams = Utils.cloneObject(parameters);

      // default auto to always
      clonedParams.auto = clonedParams.auto || 'always';

      /// default reset to none
      clonedParams.reset = clonedParams.reset || 'none';

      const opts = Object.assign({}, options, { alias: 'Actions.assignVariable' });
      return ActionChainUtils.runAction('vb/action/builtin/assignVariablesAction',
        context, clonedParams, opts);
    }

    /**
     * BarcodeAction enables web applications to decode information from QR codes and barcodes, such as urls,
     * wi-fi connections and contact information. The following is an example of a parameters object:
     *
     * {
     *   image: document.querySelector('#qrcode'),
     *   formats: [ 'qr_code' ],
     *   convertBlob: true,
     * }
     *
     * @param {Object} context the context object for the action chain
     * @param {Object} parameters
     * @param {Object} parameters.image an image object to decode, e.g., CanvasImageSource, Blob, ImageData oran
     * a <img> element
     * @param {Array} parameters.formats an optional series of barcode formats to search for, for example, one
     * or more of the following: 'aztec', 'code_128', 'code_39', 'code_93', 'codabar', 'data_matrix', 'ean_13',
     * 'ean_8', itf', 'pdf417', 'qr_code', 'upc_a', 'upc_e'. Not all formats may be supported on all platforms.
     * If formats is not specified, the browser will search all supported formats, so limiting the search to
     * a particular subset of supported formats is likely to provide better performance.
     * @param {Boolean} parameters.convertBlob an optional boolean parameter indicating whether an automatic
     * conversion from Blob input to ImageBitmap should be performed. This is a workaround for Chrome's bug:
     * {@link https://bugs.chromium.org/p/chromium/issues/detail?id=1048379}
     * If conversion fails, an error will be thrown. This will happen, for example, when Blob does not contain
     * a valid image source.
     * @see [BarcodeDetectorOptions]{@link https://wicg.github.io/shape-detection-api/#dictdef-barcodedetectoroptions}
     * @param {Object} options
     * @param {String} options.id an optional identifier for the action
     * @returns {Promise<*>} on success, returns a DetectedBarcode object as a result. DetectedBarcode has a
     * rawValue property that corresponds to the decoded string. In the case where a barcode is not detected,
     * an empty object is returned.
     * @see [DetectedBarcode]{@link https://wicg.github.io/shape-detection-api/#detectedbarcode}
     * This method will throw an error when the browser does not support Shape Detection API, or a specified
     * format is not supported.
     */
    static async barcode(context, parameters, options) {
      const opts = Object.assign({}, options, { alias: 'Actions.barcode' });
      return ActionChainUtils.runAction('vb/action/builtin/barcodeAction',
        context, parameters, opts);
    }

    /**
     * This action method invokes another action chain. The following is an example of a parameters object:
     *
     * {
     *   chain: 'myActionChain',
     *   params: {
     *     p1: 'some value'
     *   }
     * }
     *
     * On success, the result of the called action chain will be returned. Any error thrown from the called
     * action chain will be propagated to the calling chain.
     *
     * @param {Object} context the context object for the action chain
     * @param {Object} parameters
     * @param {String} parameters.chain the chain id to invoke
     * @param {Object} parameters.params parameters to the chain (optional)
     * @param {Object} options
     * @param {String} options.id an optional identifier for the action
     * @returns {Promise<*>} returns the result of the called action chain if successful. Any error
     * thrown from the called action chain will be propagated to the calling chain.
     */
    static async callChain(context, parameters, options) {
      const opts = Object.assign({}, options, { alias: 'Actions.callChain' });
      return ActionChainUtils.runAction('vb/action/builtin/callChainAction',
        context, parameters, opts);
    }

    /**
     * This action method invokes a method on a component identified by a CSS selector. The following is an
     * example of a parameters object:
     *
     * {
     *   selector: '#vbDefaultNotifier',
     *   method: 'show',
     *   params: [p1, p2],
     * }
     *
     * @param {Object} context the context object for the action chain
     * @param {Object} parameters
     * @param {String} parameters.selector a CSS selector used to locate the component
     * @param {String} parameters.method the method to invoke on the component
     * @param {Array} parameters.params a list of parameters to the method (optional)
     * @param {Object} options
     * @param {String} options.id an optional identifier for the action
     * @returns {Promise<*>} returns the result of the called component method if successful and throws
     * an error, otherwise
     */
    static async callComponentMethod(context, parameters, options) {
      const opts = Object.assign({}, options, { alias: 'Actions.callComponentMethod' });
      return ActionChainUtils.runAction('vb/action/builtin/callComponentMethodAction',
        context, parameters, opts);
    }

    /**
     * This action method fires a custom event. The following is an example of a parameters object:
     *
     * {
     *   name: "myCustomEvent",
     *   payload: {...},
     * }
     *
     * On success, the method will return after all the relevant event listeners are invoked depending on the
     * propagation behaviors. It will throw an error, otherwise.
     *
     * @param {Object} context the context object for the action chain
     * @param {Object} parameters
     * @param {String} parameters.event the name of the event
     * @param {Object} parameters.payload payload for the event
     * @param {Object} options
     * @param {String} options.id an optional identifier for the action
     * @returns {Promise<*>} throws an error if the event is not successfully delivered
     */
    static async fireEvent(context, parameters, options) {
      const opts = Object.assign({}, options, { alias: 'Actions.fireEvent' });
      return ActionChainUtils.runAction('vb/action/builtin/fireCustomEventAction',
        context, parameters, opts);
    }

    /**
     * This action method fires an event to either mutate or refresh a data provider. A mutation event can
     * include multiple mutation operations as long as the id...values between operations do not intersect.
     * For example, you cannot add a record and remove it in the same event.
     *
     * The following is an example of a parameters object for mutating a data provider:
     *
     * {
     *   dataProvider: context.$page.variables.messagesADP,
     *   add: {...}
     *   remove: {...}
     *   update: {...}
     * }
     *
     * The following is an example of a parameters object for refreshing a data provider:
     *
     * {
     *   dataProvider: context.$page.variables.messagesADP,
     *   refresh: null
     * }
     *
     * @param {Object} context the context object for the action chain
     * @param {Object} parameters
     * @param {DataProvider} parameters.dataProvider the target data provider for the event
     * @param {Object} parameters.add mutation event for adding data to a data provider
     * @param {Object} parameters.remove mutation event for removing data from a data provider
     * @param {Object} parameters.updating mutation event for updating a data provider
     * @param {Object} parameters.refresh refresh event for refreshing a data provider
     * @param {Object} options
     * @param {String} options.id an optional identifier for the action
     * @returns {Promise<*>} throws an error if the event is not successfully delivered
     */
    static async fireDataProviderEvent(context, parameters, options) {
      const opts = Object.assign({}, options, { alias: 'Actions.fireDataProviderEvent' });
      return ActionChainUtils.runAction('vb/action/builtin/fireDataProviderEventAction',
        context, parameters, opts);
    }

    /**
     * This action methods fires a notification events. The following is an example of a parameters object:
     *
     * {
     *   summary: "some message",
     *   type: 'error',
     *   displayMode: 'persist"
     * }
     *
     * @param {Object} context the context object for the action chain
     * @param {Object} parameters
     * @param {String} parameters.summary event title/summary
     * @param {String} parameters.type 'error', 'warning', 'confirmation' or 'info'. Defaults to 'error'.
     * @param {String} parameters.displayMode 'persist' or 'transient'. Defaults to 'persist'
     * @param {String} parameters.key if not provided, a 9 character alphanumeric UID is generated randomly
     * @param {Object} options
     * @param {String} options.id an optional identifier for the action
     * @returns {Promise<*>} throws an error if the event is not successfully delivered
     */
    static async fireNotificationEvent(context, parameters, options) {
      const clonedParams = Utils.cloneObject(parameters);

      // default target to leaf
      clonedParams.target = clonedParams.target || 'leaf';

      const opts = Object.assign({}, options, { alias: 'Actions.fireNotificationEvent' });
      return ActionChainUtils.runAction('vb/action/builtin/fireNotificationEventAction',
        context, clonedParams, opts);
    }

    /**
     * The action method provides access to geographical location information associated with the hosting
     * device. This action requires user's consent and, as a best practice, should only be fired on user
     * gesture. Doing so will allow users to more easily associate the system permission prompt for access
     * with the action they just initiated.
     *
     * The parameters object corresponds to
     * [PositionOptions]{@link https://developer.mozilla.org/en-US/docs/Web/API/PositionOptions}:
     *  - [maximumAge]{@link https://developer.mozilla.org/en-US/docs/Web/API/PositionOptions/maximumAge}, default: 0
     *  - [timeout]{@link https://developer.mozilla.org/en-US/docs/Web/API/PositionOptions/timeout}, default: Infinity
     *  - [enableHighAccuracy]{@link https://developer.mozilla.org/en-US/docs/Web/API/PositionOptions/enableHighAccuracy},
     *  default: false
     *
     * On mobile devices, enableHighAccuracy should be set to true in order to use GPS sensors.
     *
     * If geolocation API is supported in the browser, a
     * [Position]{@link https://developer.mozilla.org/en-US/docs/Web/API/Position} that represents the position
     * of the device at a given time is returned. Latitude and longitude can be accessed from position's
     * [Coordinates]{@link https://developer.mozilla.org/en-US/docs/Web/API/Coordinates}.
     *
     * For every failure, a descriptive error message will be thrown.
     *
     * @param {Object} context the context object for the action chain
     * @param {Object} parameters parameters for the action
     * @param {Object} options
     * @param {String} options.id an optional identifier for the action
     * @returns {Promise<*>}
     */
    static async geolocation(context, parameters, options) {
      const opts = Object.assign({}, options, { alias: 'Actions.geolocation' });
      return ActionChainUtils.runAction('vb/action/builtin/geolocationAction',
        context, parameters, opts);
    }

    /**
     * This action method launches the login process for the application. The following a an example of a
     * parameters object:
     *
     * {
     *   returnPath: 'https://myapp.com'
     * }
     *
     * @param {Object} context the context object for the action chain
     * @param {Object} parameters
     * @param {String} parameters.returnPath used by the login page to return to the application after a
     * successful login
     * @param {Object} options
     * @param {String} options.id an optional identifier for the action
     * @returns {Promise<*>} throws an error if the login process is unsuccessful
     */
    static async login(context, parameters, options) {
      const opts = Object.assign({}, options, { alias: 'Actions.login' });
      return ActionChainUtils.runAction('vb/action/builtin/loginAction',
        context, parameters, opts);
    }

    /**
     * This action method determines whether there are any dirty data variables in the
     * scope/container from which this action method was invoked. It will check the
     * current container (i.e., Application, Flow, Page, Layout, Fragment, etc.), all of
     * its extensions (if present) and any contained containers and their extensions. As
     * long as at least one dirty data variable has been found, this action method will
     * return the dirty state.
     *
     * It will always return a success outcome with { status: 'dirty' } if there is at
     * least one dirty variable or { status: 'notDirty' } if there aren't any.
     *
     * @param {Object} context the context object for the action chain
     * @param {Object} parameters parameters for the action
     * @param {Object} options
     * @param {String} options.id an optional identifier for the action
     * @returns {Promise<*>}
     */
    static async getDirtyDataStatus(context, parameters, options) {
      const opts = Object.assign({}, options, { alias: 'Actions.getDirtyDataStatus' });
      return ActionChainUtils.runAction('vb/action/builtin/getDirtyDataStatusAction', context, parameters, opts);
    }

    /**
     * This action method re-sets the dirty data state of all tracked variables
     * in the current container's scope and all of the contained containers
     * and their extensions.
     *
     * It will always return a success outcome.
     *
     * @param {Object} context the context object for the action chain
     * @param {Object} parameters parameters for the action
     * @param {Object} options
     * @param {String} options.id an optional identifier for the action
     * @returns {Promise<*>}
     */
    static async resetDirtyDataStatus(context, parameters, options) {
      const opts = Object.assign({}, options, { alias: 'Actions.resetDirtyDataStatus' });
      return ActionChainUtils.runAction('vb/action/builtin/resetDirtyDataStatusAction', context, parameters, opts);
    }

    /**
     * The action method launches the logout process for the application. The following is an example of a
     * parameters object:
     *
     * {
     *   logoutUrl: 'someUrl'
     * }
     *
     * @param {Object} context the context object for the action chain
     * @param {Object} parameters
     * @param {String} parameters.logoutUrl the URL to navigate to in order to log out
     * @param {Object} options
     * @param {String} options.id an optional identifier for the action
     * @returns {Promise<*>} throws an error if the logout process is unsuccessful
     */
    static async logout(context, parameters, options) {
      const opts = Object.assign({}, options, { alias: 'Actions.logout' });
      return ActionChainUtils.runAction('vb/action/builtin/logoutAction',
        context, parameters, opts);
    }

    /**
     * This action method navigates to a page. The following is an example of a parameters object:
     *
     * {
     *   page: 'nextPage',
     *   params: {...}
     * }
     *
     * @param {Object} context the context object for the action chain
     * @param {Object} parameters
     * @param {String} parameters.page the target page
     * @param {Object} parameters.params a map of URL parameters (optional)
     * @param {Object} parameters.history effect on the browser history. Allowed value are 'replace',
     * 'skip' or 'push'. If the value is 'replace', the current browser history entry is replaced,
     * meaning that back button will not go back to it. If the value is 'skip', the URL is left untouched.
     * (optional and default is push)
     * @param {Object} options
     * @param {String} options.id an optional identifier for the action
     * @returns {Promise<*>} throws an error if navigation is unsuccessful
     */
    static async navigateToPage(context, parameters, options) {
      const opts = Object.assign({}, options, { alias: 'Actions.navigateToPage' });
      return ActionChainUtils.runAction('vb/action/builtin/navigateAction',
        context, parameters, opts);
    }

    /**
     * This action method navigates to a flow. The following is an example of a parameters object:
     *
     * {
     *   flow: 'nextFlow',
     *   page: 'nextPage',
     *   params: {...}
     * }
     *
     * @param {Object} context the context object for the action chain
     * @param {Object} parameters
     * @param {String} parameters.flow the target flow
     * @param {String} parameters.page the target page. If not specified, navigate to the default page
     * of the flow (optional)
     * @param {Object} parameters.params a map of URL parameters (optional)
     * @param {Object} parameters.history effect on the browser history. Allowed value are 'replace',
     * 'skip' or 'push'. If the value is 'replace', the current browser history entry is replaced,
     * meaning that back button will not go back to it. If the value is 'skip', the URL is left untouched.
     * (optional and default is push)
     * @param {Object} options
     * @param {String} options.id an optional identifier for the action
     * @returns {Promise<*>} throws an error if navigation is unsuccessful
     */
    static async navigateToFlow(context, parameters, options) {
      const opts = Object.assign({}, options, { alias: 'Actions.navigateToFlow' });
      return ActionChainUtils.runAction('vb/action/builtin/navigateAction',
        context, parameters, opts);
    }

    /**
     * This action method navigates to an app UI. Here's an example of a parameters object:
     *
     * {
     *   application: 'nextAppUI',
     *   flow: 'nextFlow',
     *   page: 'nextPage',
     *   params: {...}
     * }
     *
     * @param {Object} context the context object for the action chain
     * @param {Object} parameters
     * @param {String} parameters.application the target app UI
     * @param {String} parameters.flow the target flow. If not specified, navigate to the default flow of
     * the app UI (optional)
     * @param {String} parameters.page the target page. If not specified, navigate to the default page
     * of the flow (optional)
     * @param {Object} parameters.params a map of URL parameters (optional)
     * @param {Object} parameters.history effect on the browser history. Allowed value are 'replace',
     * 'skip' or 'push'. If the value is 'replace', the current browser history entry is replaced,
     * meaning that back button will not go back to it. If the value is 'skip', the URL is left untouched.
     * (optional and default is push)
     * @param {Object} options
     * @param {String} options.id an optional identifier for the action
     * @returns {Promise<*>} throws an error if navigation is unsuccessful
     */
    static async navigateToApplication(context, parameters, options) {
      const opts = Object.assign({}, options, { alias: 'Actions.navigateToApplication' });
      return ActionChainUtils.runAction('vb/action/builtin/navigateAction',
        context, parameters, opts);
    }

    /**
     * The action methods opens the specified resource in the current window or in a new window using the
     * window.open() API as defined at
     * {@link https://developer.mozilla.org/en-US/docs/Web/API/Window/open|Window.open()}.
     * Here is an example of a parameters object:
     *
     * {
     *   url: 'http://mysite/index.html',
     *   params: {...}
     *   hash: "details",
     *   windowName: "myOtherWindow",
     * }
     *
     * @param {Object} context the context object for the action chain
     * @param {Object} parameters
     * @param {String} parameters.url the url to navigate to (required)
     * @param {Object} parameters.params an object where each property is a URL parameters (optional)
     * @param {String} parameters.hash the hash entry (optional)
     * @param {String} parameters.name A name identifying the window as defined in window.open API (optional)
     * If not defined, the URL will be open in the current window.
     * @param {String} parameters.history effect on the browser history (optional)
     * This is only used when opening the resource in the same window. Allowed values are 'replace' or
     * 'push'. Default is 'push'. If the value is 'replace', the current browser history entry is
     * replaced instead of pushed, meaning that the browser back button will not go back to it.
     * @param {Object} options
     * @param {String} options.id an optional identifier for the action
     * @returns {Promise<*>} throws an error if the url fails to open
     */
    static async openUrl(context, parameters, options) {
      const opts = Object.assign({}, options, { alias: 'Actions.openUrl' });
      return ActionChainUtils.runAction('vb/action/builtin/openUrlAction',
        context, parameters, opts);
    }

    /**
     * This action method is used to reset a list of variables to their default values. The following is
     * and example of a parameters object:
     * {
     *   variables: ['$page.variables.var1', '$page.variables.var2']
     * }
     *
     * @param {Object} context the context object for the action chain
     * @param {Object} parameters
     * @param {Array} parameters.variables a list of fully qualified variable names to reset
     * @param {Object} options
     * @param {String} options.id an optional identifier for the action
     * @returns {Promise<*>} returns undefined if successful and throws an error, otherwise
     */
    static async resetVariables(context, parameters, options) {
      const opts = Object.assign({}, options, { alias: 'Actions.resetVariables' });
      return ActionChainUtils.runAction('vb/action/builtin/resetVariablesAction',
        context, parameters, opts);
    }

    /**
     * The action method makes a call against a REST endpoint.
     *
     * The following is an example of a parameters object for a GET REST request:
     *
     * {
     *   endpoint: "employees_70/getEmployeeById",
     *   responseFields: context.$page.variables.fetchedFields,
     *   uriParams: {...}
     *   responseBodyFormat: 'json',
     *   hookHandler: context.$page.variables.configuration.hookHandler,,
     *   contentType': 'application/json',
     *   responseType: 'customersResponseType',
     * }
     *
     * The following is an example of a parameters object for a POST REST request:
     *
     * {
     *   endpoint: "fixitfast/postIncident",
     *   uriParams: {...}
     *   body: {...}
     * }
     *
     * @param {Object} context the context object for the action chain
     * @param {Object} parameters
     * @param {String} parameters.endpoint the endpoint ID as defined in the service configuration
     * @param {Object} parameters.uriParams A key/value pair map that will be used to override path and query
     * parameters as defined in the service endpoint
     * @param {String} parameters.body a structured object that will be sent as the body
     * @param {Object} parameters.headers each property name is a header name and value that will be sent
     * with the request
     * @param {String} parameters.contentType optional string value with an actual (MIME) type, which will
     * be used for the "content-type" header. When used with "fileContentType", this is also used as the
     * type for the File blob.
     * @param {String} parameters.responseType if a responseType is set, coerce the REST response body to
     * this type
     * @param {String} parameters.filePath optional path to a file to send with the request
     * @param {String} parameters.filePartName optional, used with filePath; allow override of the default name
     * ("file") for the FormData part
     * @param {String} parameters.fileContentType optional string, used in combination with "contentType":
     * "multipart/form-data" and "filePath"
     * @param {Function} parameters.hookHandler used primarily by vb/ServiceDataProvider when externalizing
     * data fetches
     * @param {Object} parameters.requestTransformOptions a map of keys (transform function names) and values
     * to pass to the corresponding transform, as the "options" parameter
     * @param {Object} parameters.requestTransformFunctions A map of named transform functions, called before
     * making the request, where the function is: fn(configuration, options)
     * @param {Object} parameters.responseTransformFunctions A map of named transform functions, called before
     * making the response, where the function is: fn(configuration, options)
     * @param {String} parameters.responseBodyFormat 'json' or 'formData'
     * @param {Array} parameters.responseFields advanced field for use specifically with JET Dynamic Forms.
     * The value would typically be a variable that is bound to the <oj-dynamic-form> "rendered-fields"
     * attribute. This is how a calculated layout can the Rest Action call which fields to fetch.
     * @param {Object} options
     * @param {String} options.id an optional identifier for the action
     * @returns {Promise<*>} returns the response object from the browser fetch and let the JS chain handle
     * the response status. Only errors from the browser fetch will be thrown from this method.
     */
    static async callRest(context, parameters, options) {
      const opts = Object.assign({}, options, { alias: 'Actions.callRest' });
      return ActionChainUtils.runAction('vb/action/builtin/restAction',
        context, parameters, opts);
    }

    /**
     * This action methods navigate the application back to the previous page in browser history with input
     * parameters. Here is an example of a parameters object:
     *
     * {
     *   params: {...}
     * }
     *
     * @param {Object} context the context object for the action chain
     * @param {Object} parameters
     * @param {Object} parameters.params URL parameters to the previous page (optional)
     * @param {Object} options
     * @param {String} options.id an optional identifier for the action
     * @returns {Promise<*>}
     */
    static async navigateBack(context, parameters, options) {
      const opts = Object.assign({}, options, { alias: 'Actions.navigateBack' });
      return ActionChainUtils.runAction('vb/action/builtin/navigateBackAction',
        context, parameters, opts);
    }

    /**
     * This action method allows applications to invoke the native sharing capabilities of the host
     * platform and share content with other applications, such as Facebook, Twitter, Slack, SMS, etc.
     * This action should only be invoked following a user gesture (such as a button click).
     * It is a good idea to only enable share UI based of feature detection:
     * <pre>
     *   <oj-button disabled="[[!navigator.share]]">Share</oj-button>
     * </pre>
     *
     * The parameters object correspond to
     * [Web Share API]{@link https://developer.mozilla.org/en-US/docs/Web/API/Navigator/share}
     * All parameters are individually optional, but at least one parameter has to be specified. Any url
     * can be shared, not just urls under website's current scope. Text can be shared with or without a url.
     *
     * The following is an example of a parameters object:
     *
     * {
     *   title: document.querySelector('h1').textContent,
     *   url: document.querySelector('link[rel=canonical]') &&
     *        document.querySelector('link[rel=canonical]').href || window.location.href,
     *   files: context.$page.variables.files
     * }
     *
     * A success outcome is returned once user has completed a share action. A failure outcome is returned
     * when browser does not support Web Share API or a parameter error is detected.
     * @param {Object} context the context object for the action chain
     * @param {Object} parameters parameters for the action
     * @param {String} parameters.title the title of the document being shared. May be ignored by the
     * handler/target.
     * @param {String} parameters.url a URL string referring to a resource being shared.
     * @param {String} parameters.text an arbitrary text that forms the body of the message being shared
     * @param {String} parameters.files a list of files to the shared, such as image, video, audio, and
     * text files
     * @param {Object} options
     * @param {String} options.id an optional identifier for the action
     * @returns {Promise<*>} throws an error if the does not support Web Share API or a parameter error
     * is detected.
     */
    static async webShare(context, parameters, options) {
      const opts = Object.assign({}, options, { alias: 'Actions.webShare' });
      return ActionChainUtils.runAction('vb/action/builtin/webShareAction',
        context, parameters, opts);
    }

    /**
     * This action method can be used to invoke any custom action.
     *
     * @param {Object} context the context object for the action chain
     * @param {Object} parameters
     * @param {String} parameters.module module id for the custom action
     * @param {Object} parameters.parameters parameters to the custom action
     * @param {Object} options
     * @param {String} options.id an optional identifier for the action
     * @returns {Promise<*>}
     */
    static async runAction(context, parameters, options) {
      return ActionChainUtils.runAction(parameters.module,
        context, parameters.parameters, options);
    }
  }

  return Actions;
});

