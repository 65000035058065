'use strict';

define('vb/private/serviceWorker/serviceWorkerManagerFactory',['vb/private/utils'], (Utils) => {
  /**
   * A factory that creates a ServiceWorkerManager based on vbInitConfig.
   * When vbInitConfig.PWA_CONFIG is set, an instance of PwaServiceWorkerManagerClass is created,
   + unless either vbInitConfig.IS_DT_MODE or vbInitConfig.PWA_CONFIG.disabled is set to true.
   * In all other cases, an instance of ServiceWorkerManagerClass is created.
   * An instance of service worker manager class is then set on ServiceWorkerManager.
   */
  class ServiceWorkerManagerFactory {
    static createServiceWorkerManager(vbInitConfig) {
      let swmInstance;
      return ServiceWorkerManagerFactory.getServiceWorkerManagerClass(vbInitConfig)
        .then((SWManagerClass) => {
          swmInstance = new SWManagerClass();
          return Utils.getResource('vbsw/private/serviceWorkerManager');
        })
        .then((swm) => {
          swm.setInstance(swmInstance);
          return swm;
        });
    }

    static getServiceWorkerManagerClass(vbInitConfig = {}) {
      let swManagerClassName = 'vbsw/private/serviceWorkerManagerClass';
      return Utils.getResource('vbc/private/pwa/pwaUtils')
        .then((pwaUtils) => {
          // Even if this is a PWA, Service Worker is not enabled in DT preview
          if (!vbInitConfig.IS_DT_MODE && pwaUtils.isWebPwaConfig(vbInitConfig)) {
            swManagerClassName = 'vb/private/pwa/pwaServiceWorkerManagerClass';
          }
          return Utils.getResource(swManagerClassName);
        });
    }
  }
  return ServiceWorkerManagerFactory;
});

