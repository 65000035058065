'use strict';

define('vbsw/private/plugins/mobileAuthHandlerPlugin',[
  'vbsw/api/fetchHandlerPlugin',
  'vbsw/private/plugins/authHeaderHandlerPlugin',
  'urijs/URI',
  'vbsw/private/constants',
  'vbc/private/constants',
  'vbsw/private/utils'],
(FetchHandlerPlugin, AuthHeaderHandlerPlugin, URI, Constants, CommonConstants, Utils) => {
  /**
   * Handler plugin for appending Authorization header for Mobile.
   */
  class MobileAuthHandlerPlugin extends FetchHandlerPlugin {
    /**
     * Constructor.
     *
     * @param context
     * @param params an object containing all the headers to be appended to the request and allowed scopes
     */
    constructor(context, params) {
      super(context);

      // make :443 port optional in matching
      // case-insensitive match
      this.currentUserUrl = params.currentUserUrl || '';
      this.currentUserUrlRegEx = new RegExp(this.currentUserUrl.replace(':443', '(:443)?'), 'i');

      const allowedScopes = params.allowedScopes || [];

      this.allowedScopes = allowedScopes.map((scope) => {
        // make :443 port optional in matching
        const scopeWithOptionalPort = scope.replace(':443', '(:443)?');

        return {
          scope,
          regEx: new RegExp(scopeWithOptionalPort, 'i'), // case-insensitive match
        };
      });

      // the application scope is used for determining whether the
      // authoriziation token needs to be added for direct rest calls
      this.appScopeRegEx = new RegExp(Utils.getRegexForAppScope(this.fetchHandler.scope));

      // in mobile case, the following plugin is only needed for the anonymous case
      this.authHeaderHandlerPlugin = new AuthHeaderHandlerPlugin(context, { isAnonymous: true });
    }

    /**
     * Match the given url to an allowed scope and return the matched scope.
     *
     * @param url the url to match
     * @returns {string}
     */
    matchScope(url) {
      const result = this.allowedScopes.find((scope) => url.match(scope.regEx));
      return result ? result.scope : null;
    }

    /**
     * Append the Authorization header.
     *
     * @param request the request to which to append the header
     * @param client the client for the originator of the request
     */
    handleRequestHook(request, client) {
      const isAnonymousAccess = request.headers.get(CommonConstants.Headers.ALLOW_ANONYMOUS_ACCESS_HEADER) === 'true';

      if (isAnonymousAccess) {
        return this.authHeaderHandlerPlugin.handleRequestHook(request);
      }

      const authenticationType = request.headers.get(Constants.AUTHENTICATION_TYPE_HEADER);

      if (authenticationType === Constants.AuthenticationType.BASIC
          || authenticationType === Constants.AuthenticationType.ORACLE_CLOUD
          || (authenticationType === Constants.AuthenticationType.DIRECT && request.url.match(this.appScopeRegEx))
          || (authenticationType === Constants.AuthenticationType.OAUTH && this.matchScope(request.url))
          || request.url.match(this.currentUserUrlRegEx)) {
        // since this is not an anonymous access, post a message to the main application to get the security headers
        const msg = {
          method: 'vbGetAuthHeader',
        };

        return Utils.postMessage(client, msg).then((headers) => {
          // add the authorization header to the request which could be either basic or bearer depending
          // on whether the application is configured for basic auth or oracle cloud security
          Object.keys(headers).forEach((key) => {
            request.headers.set(key, headers[key]);
          });
        });
      }

      return Promise.resolve();
    }
  }

  return MobileAuthHandlerPlugin;
});

