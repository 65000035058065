'use strict';

define('vb/components/mobile-navigation-item/loader',[
  'ojs/ojcomposite',
  'text!./component.json',
], (Composite, metadata) => {
  Composite.register('oj-ext-mobile-navigation-item', {
    view: '',
    metadata: JSON.parse(metadata),
  });
});

