/* eslint no-underscore-dangle: 0 */
/* global cordova:false */
/* global FileError:false */

'use strict';

define('vb/private/mobile/fileIo',[], () => {
  class FileIo {
    /**
     * Saving a file within an application library.
     * @param dir directory where to save
     * @param fileName name of the file
     * @param stringContents contents of the file
     */
    static saveFileToLib(dir, fileName, stringContents) {
      return new Promise((resolve, reject) => {
        window.resolveLocalFileSystemURL(cordova.file.dataDirectory, function (rootEntry) {
          rootEntry.getDirectory(dir, { create: true }, function (directoryEntry) {
            directoryEntry.getFile(fileName, { create: true }, function (fileEntry) {
              fileEntry.createWriter(function (writer) {
                const fileWriter = writer;

                fileWriter.onwriteend = function (e) { // eslint-disable-line no-unused-vars
                  // ignore the error
                  resolve();
                };

                fileWriter.onerror = reject;

                const blob = new Blob([stringContents], { type: 'text/plain' });
                fileWriter.write(blob);
              }, function (e) {
                reject(FileIo._decodeError(e));
              });
            }, function (e) {
              reject(FileIo._decodeError(e));
            });
          }, function (e) {
            reject(FileIo._decodeError(e));
          });
        }, function (e) {
          reject(FileIo._decodeError(e));
        });
      });
    }

    /**
     * Reading a file from within an application library.
     * @param dir directory to read it from
     * @param fileName name of the file to read
     */
    static readFileFromLib(dir, fileName) {
      return new Promise((resolve, reject) => {
        window.resolveLocalFileSystemURL(`${cordova.file.dataDirectory}${dir}`, function (directoryEntry) {
          directoryEntry.getFile(fileName, {}, function (fileEntry) {
            fileEntry.file(function (file) {
              const fileReader = new FileReader();

              fileReader.onloadend = function (e) { // eslint-disable-line no-unused-vars
                // ignore the error
                resolve(this.result);
              };

              fileReader.onerror = reject;

              fileReader.readAsText(file);
            }, function (e) {
              reject(FileIo._decodeError(e));
            });
          }, function (e) {
            reject(FileIo._decodeError(e));
          });
        }, function (e) {
          reject(FileIo._decodeError(e));
        });
      });
    }

    /**
     * Renames the given file.
     * @param dir parent directory of the file to rename
     * @param originalFileName original file name
     * @param newFileName new file name
     */
    static renameFile(dir, originalFileName, newFileName) {
      return new Promise((resolve, reject) => {
        window.resolveLocalFileSystemURL(dir, function (directoryEntry) {
          directoryEntry.getFile(originalFileName, { create: false }, function (fileEntry) {
            fileEntry.moveTo(directoryEntry, newFileName, function () {
              resolve();
            }, function (e) {
              reject(FileIo._decodeError(e));
            });
          }, function (e) {
            reject(FileIo._decodeError(e));
          });
        }, function (e) {
          reject(FileIo._decodeError(e));
        });
      });
    }

    /**
     * Utility function to create a dir inside a specific parent directory
     * @param parentDir {FileSystemDirectoryEntry} parent directory
     * @param dirName {String} name of the subdirectory to create
     * @return {FileSystemDirectoryEntry} newly created directory entry
     */
    static createDir(parentDir, dirName) {
      return new Promise((resolve, reject) => {
        parentDir.getDirectory(dirName, { create: true }, (newDir) => resolve(newDir), (e) => {
          reject(FileIo._decodeError(e));
        });
      });
    }

    /**
     * creates a file onto the device data directory
     * @param extDir   {FileSystemDirectoryEntry} the extension directory under which to create the file path
     * @param filePath {String} file name with directories to create on the device
     * @param content {Blob|String|Object} content to be written for the given file
     */
    static createFileContentOnDevice(extDir, filePath, content) {
      return new Promise((resolve, reject) => {
        const paths = filePath.split('/');
        // serialize promises to create the subdirectories
        let dirPromise = Promise.resolve(extDir);
        if (paths.length > 1) {
          const subPaths = paths.splice(0, paths.length - 1);
          subPaths.forEach((subPath) => {
            dirPromise = dirPromise.then((dirEntry) => FileIo.createDir(dirEntry, subPath))
              .catch((e) => reject(FileIo._decodeError(e)));
          });
        }

        // finally create the file
        dirPromise.then((dirEntry) => dirEntry
          .getFile(paths[paths.length - 1], { create: true, exclusive: false }, (fileEntry) => {
            fileEntry.createWriter((fileWriter) => {
              // eslint-disable-next-line no-param-reassign
              fileWriter.onwriteend = () => resolve(filePath);

              if ((typeof content) === 'string' || (content instanceof Blob)) {
                fileWriter.write(content);
              } else {
                // assume json file
                fileWriter.write(JSON.stringify(content));
              }
            }, (e) => {
              reject(FileIo._decodeError(e));
            });
          }))
          .catch((e) => reject(FileIo._decodeError(e)));
      });
    }

    /**
     * Decodes errors into user friendly strings.
     */
    static _decodeError(e) {
      let msg = '';

      switch (e.code) {
        case FileError.QUOTA_EXCEEDED_ERR:
          msg = 'Storage quota exceeded';
          break;
        case FileError.NOT_FOUND_ERR:
          msg = 'File not found';
          break;
        case FileError.SECURITY_ERR:
          msg = 'Security error';
          break;
        case FileError.INVALID_MODIFICATION_ERR:
          msg = 'Invalid modification';
          break;
        case FileError.INVALID_STATE_ERR:
          msg = 'Invalid state';
          break;
        default:
          msg = 'Unknown error';
          break;
      }

      return msg;
    }
  }

  return FileIo;
});

