'use strict';

define('vb/extensions/dynamic/private/models/emptyModel',[
  'vb/private/utils',
], (Utils) => {
  // convert a file name prefix, which may contain dashes, to a camelCase name
  // ex: data-description-overlay => dataDescriptionOverlay

  class EmptyModel {
    /**
     * @param definition {object} the "translations" declaration
     * @param folder {string}
     * @param ownerName {string} file name (may have extension, will be stripped for namespacing)
     */
    constructor(definition, folder, ownerName) {
      this.folder = folder || '';
      this.namespace = Utils.hyphenatedToCamelCase(ownerName || '');
      this.models = [];
    }

    /**
     * @returns {Promise<Model>}
     */
    init() {
      return Promise.resolve(this);
    }


    /**
     * Add a 'child' model (submodel), with an additional namespace.
     *
     * for example, if we have a model whose own namespace is 'foo',
     * and we add it here with a 'base', we will have:
     *
     * $base.foo: <base model>
     * $<this>.base.foo: <base model>
     *
     * @param namespace
     * @param model
     */
    addChildModel(namespace, model) {
      this.models.push({ namespace, model });
    }

    /**
     * return an object with everything that should be exposed through the expression language.
     * these should be undecorated property names (no $ prefix).
     * These are only the properties; there is no top-level namespace.
     *
     * Subclasses should override
     * @returns {{}}
     */
    // eslint-disable-next-line class-methods-use-this
    getContext() {
      if (!this.context) {
        this.context = {};
      }
      return this.context;
    }

    /**
     * called by subclasses; does not actually load anything...
     */
    // eslint-disable-next-line class-methods-use-this
    load() {
      return Promise.resolve();
    }


    /**
     * subclasses override createContext, to flesh this structure out.
     *
     * this method creates the various expressions.
     *
     * examples:
     * if our namespace is 'dataDescription', and our context has 'translations':
     *    $translations
     *    $dataDescription.translations - same as $translations
     *
     * if our namespace is 'extension', our context has 'foo', and and we have a child model of 'base',
     * which has the structure from the first example, we will have the following expressions:
     *     $foo
     *     $extension.foo
     *     $extension.base.translations
     *     $base.translations
     *
     * @returns { { $namespace: {} } }
     */
    getViewModel() {
      if (!this.expressionContext) {
        this.expressionContext = {};

        this.getContext();

        // create a ${name} for each property in the context
        Object.keys(this.context).forEach((propertyName) => {
          Object.defineProperty(this.expressionContext, `$${propertyName}`, {
            get: () => this.context[propertyName],
            enumerable: true,
          });
        });

        // $<namespace>
        // we clone the context, in case we have 'chilc' contextx, and need to add an accessor property
        // for example, if we are $extension, we will want to add the child 'base' model
        const subcontext = Object.assign({}, this.context);
        if (this.namespace) {
          Object.defineProperty(this.expressionContext, `$${this.namespace}`, {
            get: () => subcontext,
            enumerable: true,
          });
        }

        // if someone added 'base', create $base, and $<namespace>.base
        if (this.models.length) {
          this.models.forEach((modelInfo) => {
            // use the child model's own namespace for the inner namespace (ex: 'dataDescriptor')
            const childContext = {
              [modelInfo.model.namespace]: modelInfo.model.getContext(),
            };

            // now add the long & shorthand for the child's outer namespace (ex. '<this>.base' and '$base')
            Object.defineProperty(this.expressionContext, `$${modelInfo.namespace}`, {
              get: () => childContext,
              enumerable: true,
            });

            if (this.namespace) {
              Object.defineProperty(subcontext, modelInfo.namespace, {
                get: () => childContext,
                enumerable: true,
              });
            }
          });
        }
      }

      return this.expressionContext;
    }
  }

  return EmptyModel;
});

