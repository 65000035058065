'use strict';

define('vb/private/services/endpointMetadata',[
  'vb/private/utils',
], (Utils) => {
  class EndpointMetadata {
    /**
     *
     * @param endpoint
     *
     * @property {*|null} headers
     * @property {*} method
     * @property {*} requestContentTypes
     * @property {*} responseContentTypes
     * @property {*} proxyUrl
     * @property {*|null} staticQueryParameters
     * @property {*} name
     * @property {*} description
     * @property {*} serviceId
     * @property {*|null} parameters
     * @property {*} url
     */
    constructor(endpoint, operationObject) {
      this.url = endpoint.url;

      const requestContentTypes = operationObject.getRequestContentTypes();
      const responseContentTypes = operationObject.getResponseContentTypes();

      this.name = endpoint.name;
      this.serviceId = endpoint.service.name;
      this.method = endpoint.method;
      this.description = endpoint.description;
      this.proxyUrl = endpoint.proxyUrl;
      this.parameters = endpoint.parameters ? Utils.cloneObject(endpoint.parameters) : null;
      this.headers = endpoint.headers ? Utils.cloneObject(endpoint.headers) : null;
      this.staticQueryParameters = endpoint.staticQueryParams ? Utils.cloneObject(endpoint.staticQueryParams) : null;
      this.requestContentTypes = requestContentTypes ? requestContentTypes.slice() : null;
      this.responseContentTypes = responseContentTypes ? responseContentTypes.slice() : null;
      this.componentParameters = operationObject.openApi && operationObject.openApi.definition
        && operationObject.openApi.definition.components && operationObject.openApi.definition.components.parameters;

      const server = endpoint.service.server;
      this.server = server
        ? {
          getUrl: (...args) => server.getUrl(...args),
        }
        : {
          getUrl: () => '',
        };

      // create getters for lazily-expanded properties
      Object.defineProperties(this, {
        responses: {
          enumerable: true,
          configurable: false,
          get: () => operationObject.getExpandedResponses(),
        },
      });
    }

    /**
     * this must be called by Endpoint.load(), after the URL is resolved.
     * the original endpoint may use a special protocol (ex: vb-catalog://) to indicate an indirection which
     * needs to be calculated on-demand by Endpoint.load().
     * @param url
     */
    setUrl(url) {
      this.url = url;
    }

    /**
     * this should be used to access the object, to validate that the url has been set
     * @returns {EndpointMetadata}
     * @throws {Error}
     */
    get() {
      if (!this.url) {
        // this should never happen, this error is to make sure its not even possible
        throw new Error(`getMetadata called for endpoint ${this.name} before loading`);
      }
      return this;
    }
  }

  return EndpointMetadata;
});

