'use strict';

define('vb/action/builtin/restartApplicationAction',[
  'vb/action/action',
  'vb/helpers/navigate',
  'vb/private/constants',
  'urijs/URI',
], (Action, Navigate, Constants, URI) => {
  /**
   * Restarts the application. Provide optional path for alternative navigation into the app after restart.
   * If no path provided, the default root page is restarted
   *
   * @param {Object} parameters Parameters passed to the action
   * @param {String} parameters.restartPath the path of the page or flow to go to when login is successful
   *
   *
   * "parameters": {
   *   "restartPath": "app/customextension"
   * }
   *
   * In the above example since the restartPath is provided, the application will reload and will take user to
   * 'customextension' page under root 'app' page
   *
   * NOTE: This action is only supported for mobile applications
   */
  class RestartApplicationAction extends Action {
    // eslint-disable-next-line class-methods-use-this
    perform(parameters) {
      // support this only for mobile applications
      if (this.context.application.isQueryStrategy()) {
        const restartPath = parameters.restartPath || '/';
        // Let the router calculate the correct URL from the page path
        const url = (new URI(window.location.href)).search(Navigate.getUrlFromPath(restartPath));
        Navigate.toUrl({
          url,
          // Use replace mode since we are restarting the app
          history: Constants.HistoryMode.REPLACE,
        });

        return Action.createSuccessOutcome(true);
      }

      return Action.createOutcome(this.Outcome.FAILURE, false);
    }

    setContext(context) {
      this.context = context;
    }
  }

  return RestartApplicationAction;
});

