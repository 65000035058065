'use strict';

define('vb/private/stateManagement/context/layoutContext',[
  'vb/private/constants',
  'vb/private/stateManagement/context/containerContext',
  'vb/private/stateManagement/context/layoutBaseContext',
  'vb/private/utils',
],
(Constants, ContainerContext, LayoutBaseContext, Utils) => {
  /** @type Object */
  const symbol = Symbol('layout-accessor');

  /**
   * set of properties to expose in $layout
   */
  class LayoutContext extends ContainerContext {
    constructor(layout) {
      super(layout);
      Object.defineProperty(this, symbol,
        {
          value: {
            /**
             * Shortcut to retrieve the builtins variable map
             */
            get builtins() {
              return layout.scope.variableNamespaces[Constants.VariableNamespace.BUILTIN];
            },
          },
        });
    }

    static get BaseContextType() {
      return LayoutBaseContext;
    }

    /**
     * @param layout
     *
     * this method is static because we need to be able to create the available contexts BEFORE
     * the individual XXXContext objects are created, because we base the viewModel on the availableContexts object.
     *
     * these static methods still use class hierarchy for inheriting property accessors.
     *
     * @returns {{ $variables, $constants }}
     */
    static getAvailableContexts(layout) {
      const availableContexts = super.getAvailableContexts(layout);

      // Remove properties added by container context that should not be part of the
      // layout context.
      if (!Utils.isHostApplication()) {
        // only delete $global for non-hosted or v1 app
        delete availableContexts.$global;
      }
      delete availableContexts.$application;
      delete availableContexts.$metadata;

      Object.defineProperties(availableContexts, {
        // $layout.variables is an alias of $variables
        $layout: {
          enumerable: true,
          configurable: true,
          get: () => layout.expressionContext,
        },
        $extension: {
          enumerable: true,
          configurable: true,
          value: {
            get [Constants.PATH_VARIABLE]() {
              return layout.extension.getAbsoluteUrl();
            },
          },
        },
      });

      return availableContexts;
    }

    /*
     * $layout.info
     */
    get [Constants.INFO_CONTEXT]() {
      return this[symbol].builtins[Constants.INFO_CONTEXT];
    }
  }

  return LayoutContext;
});

