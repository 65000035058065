/* eslint-disable no-unused-vars */
/* eslint-disable class-methods-use-this */

'use strict';

define('vbsw/private/serviceWorkerWrapper',['vbsw/private/utils'], (Utils) => {
  /**
   * Base wrapper class for a service worker instance.
   */
  class ServiceWorkerWrapper {
    constructor(scope) {
      this.scope = scope;
      this.messageHandlers = [];
    }

    /**
     * Called by unit tests to unregister a service worker.
     *
     * @returns {Promise<T>}
     */
    unregister() {
      return Promise.resolve().then(() => {
        if (this.registration) {
          return this.registration.unregister();
        }
        return undefined;
      });
    }

    /**
     * Called by unit tests to completely clean up a service worker.
     *
     * @returns {Promise}
     */
    uninstall() {
      return this.uninstallPlugins()
        .then(() => this.uninstallOfflineHandler())
        .then(() => {
          // clean up the message handler
          if (this.messageEventListener) {
            this.removeMessageEventListener(this.messageEventListener);
            this.messageEventListener = null;
            this.messageHandlers = [];
          }
        })
        .then(() => this.unregister());
    }

    /**
     * Load and install the given plugins. The plugins should be an array of urls.
     *
     * @param plugins an array of urls for the plugins to install
     * @param reload if true, force reload all the plugins, otherwise, only reload plugins whose metadata has changed
     * @returns {Promise}
     */
    installPlugins(plugins, reload = true) {
      return Promise.resolve();
    }

    /**
     * Uninstall currently installed plugins. This is used for testing purpose.
     *
     * @returns {*}
     */
    uninstallPlugins() {
      return Promise.resolve();
    }

    /**
     * Install the given message handler with the service worker.
     *
     * @param messageHandler the message handler to install
     */
    installMessageHandler(messageHandler) {
      if (!this.messageEventListener) {
        this.messageEventListener = (event) => {
          const data = event.data;
          if (typeof data === 'object' && data.method) {
            const method = data.method;
            const args = data.args || [];
            const client = event.ports[0];

            // invoke the method on the last handler that can handle the message to simulate overriding behavior
            const invoked = this.messageHandlers.reverse().some((handler) => {
              if (typeof handler[method] === 'function') {
                Utils.postResponse(client, handler[method](...args), `${method}`);
                return true;
              }
              return false;
            });

            // reject the message if no message handler is found
            if (!invoked) {
              // eslint-disable-next-line prefer-promise-reject-errors
              Utils.postResponse(client, Promise.reject('No message handler found.'), `${method}`);
            }
          }
        };
        this.addMessageEventListener(this.messageEventListener);
      }

      this.messageHandlers.push(messageHandler);
    }

    /**
     * Uninstall the given message handler.
     *
     * @param messageHandler message handler to uninstall
     */
    uninstallMessageHandler(messageHandler) {
      const index = this.messageHandlers.indexOf(messageHandler);
      if (index > -1) {
        this.messageHandlers.splice(index, 1);
      }

      // remove the message event listener if there's no more message handlers left
      if (this.messageHandlers.length === 0) {
        this.removeMessageEventListener(this.messageEventListener);
        this.messageEventListener = null;
      }
    }

    /**
     * Register a message event listener.
     *
     * @param listener the listener to register
     */
    addMessageEventListener(listener) {
      // install the message listener on window
      window.addEventListener('message', listener, false);
    }

    /**
     * Unregister the given message event listener.
     *
     * @param listener the listener to unregister
     */
    removeMessageEventListener(listener) {
      window.removeEventListener('message', listener);
    }

    /**
     * Load and install the offline handler. If the application wants to use the offline toolkit to handle requests,
     * it needs to implement a createOfflineHandler method on app-flow.js that returns an object with a handleRequest
     * method. For example:
     *
     * {
     *   handleRequest: function (request) {
     *     var responseProxy = DefaultResponseProxy.getResponseProxy(options);
     *     return responseProxy.processRequest(request);
     *   }
     * }
     *
     * @param handlerUrl the url from which to load the offline handler
     * @returns {Promise}
     */
    installOfflineHandler(handlerUrl) {
      return Promise.resolve();
    }

    /**
     * Uninstall offline handler.
     *
     * @returns {Promise}
     */
    uninstallOfflineHandler() {
      return Promise.resolve();
    }

    /**
     * Inform the service worker to perform a sync of cached offline data.
     *
     * @param options any options to be passed to the persistence sync manager
     * @returns {Promise}
     */
    syncOfflineData(options) {
      return Promise.resolve();
    }

    /**
     * Set options on the offline handler.
     *
     * @param options options to be set on the offline handler
     * @returns {Promise}
     */
    setOfflineHandlerOptions(options) {
      return Promise.resolve();
    }

    /**
     * Register the requests to be skipped by the service worker.
     *
     * @param requests an array of objects containing the url and method, e.g., [{ url: 'xxxx', method: 'GET' }]
     * @returns {Promise}
     */
    addRequestsToSkip(requests) {
      return Promise.resolve();
    }

    /**
     * Deregister the requests to be skipped by the service worker.
     *
     * @param requests an array of objects containing the url and method, e.g., [{ url: 'xxxx', method: 'GET' }]
     * @returns {Promise}
     */
    removeRequestsToSkip(requests) {
      return Promise.resolve();
    }
  }
  return ServiceWorkerWrapper;
});

