'use strict';

define('vb/private/stateManagement/context/applicationBaseContext',[
  'vb/private/stateManagement/context/flowBaseContext',
  'vb/private/constants'],
(FlowBaseContext, Constants) => {
  /** @type Object */
  const symbol = Symbol('application-base-accessor');
  /**
   * set of properties to expose in $base for flow extension
   */
  class ApplicationBaseContext extends FlowBaseContext {
    constructor(application) {
      super(application);

      Object.defineProperty(this, symbol,
        {
          value: {
            get applicationContext() {
              return application.expressionContext;
            },
          },
        });
    }

    get [Constants.APPLICATION_USER_VARIABLE]() {
      return this[symbol].applicationContext[Constants.APPLICATION_USER_VARIABLE];
    }

    get [Constants.GLOBAL_CURRENT_APPUI_VARIABLE]() {
      return this[symbol].applicationContext[Constants.GLOBAL_CURRENT_APPUI_VARIABLE];
    }

    get builtinUtils() {
      return this[symbol].applicationContext.builtinUtils;
    }

    get [Constants.RESPONSIVE_CONTEXT]() {
      return this[symbol].applicationContext[Constants.RESPONSIVE_CONTEXT];
    }

    get [Constants.PROFILE_CONSTANT]() {
      return this[symbol].applicationContext[Constants.PROFILE_CONSTANT];
    }

    get [Constants.DEPLOYMENT_CONSTANT]() {
      return this[symbol].applicationContext[Constants.DEPLOYMENT_CONSTANT];
    }

    get [Constants.INIT_PARAM_CONTEXT]() {
      return this[symbol].applicationContext.initParams;
    }

    get [Constants.PATH_VARIABLE]() {
      return this[symbol].applicationContext[Constants.PATH_VARIABLE];
    }

    get [Constants.CURRENT_PAGE_VARIABLE]() {
      return this[symbol].applicationContext[Constants.CURRENT_PAGE_VARIABLE];
    }

    get [Constants.INFO_CONTEXT]() {
      return this[symbol].applicationContext[Constants.INFO_CONTEXT];
    }
  }

  return ApplicationBaseContext;
});

