'use strict';

define('vb/action/builtin/fireCustomEventAction',['vb/action/eventAction', 'vb/action/action', 'vb/private/log', 'vb/private/constants',
  'vb/private/events/eventRegistry',
  'vb/private/action/assignmentHelper',
  'vb/private/utils',
], (EventAction, Action, Log, Constants, EventRegistry, AssignmentHelper, Utils) => {
  const logger = Log.getLogger('/vb/private/stateManagement/fireCustomEventAction');

  const CAMELCASE_REG = /^[a-z][A-Za-z]*$/;

  class FireCustomEventAction extends EventAction {
    perform(parameters) {
      const name = parameters.name || parameters.event;
      const { payload } = parameters;

      // to prevent event handlers from making unpredictable changes, pass by value instead of by reference. Event
      // payloads are meant to be serializable any way and must not hold references to state that may not survive
      // the event processing lifecycle
      let clonePayload = payload;
      if (payload) {
        clonePayload = Utils.cloneObject(payload);
      }

      logger.info('FireCustomEventAction', this.logLabel, 'with event name', name, 'and payload', clonePayload);

      if (!EventAction.isValid(name)) {
        return EventAction.createFailureOutcome(`Unable to fire invalid event: ${name}`);
      }

      // look for a declaration; we need to determine if this is a 'normal' VB event,
      // or the dynamic-UI 'dynamicComponent' behavior event.
      const dcEventModel = EventRegistry
        .get(this.context.container, this.context.container, name, Constants.EventBehaviors.DYNAMIC_COMP);

      if (dcEventModel) {
        if (!dcEventModel.isInterface) {
          return EventAction
            .createFailureOutcome('Events with "template" behavior must be declared in the "interface" section: '
              + `${parameters.target}`);
        }

        return this.performDynamicComponentBehavior(name, clonePayload, dcEventModel);
      }

      if (parameters && !EventAction.isValidTarget(parameters.target)) {
        return EventAction.createFailureOutcome(`invalid "target" for event action: ${parameters.target}`);
      }

      if (this.eventContainer) {
        // ignore returned Promise; we don't wait for event processing
        // must be declared but need not be part of an interface; interface events are more relevant for extensions
        const eventModel = EventRegistry.get(this.eventContainer, this.eventContainer, name);
        if (eventModel && !this.eventContainer.canFireEvent(eventModel)) {
          return EventAction.createFailureOutcome(`Unable to find event declaration for: ${name}`);
        }

        const result = EventAction.fireEvent(this.eventContainer, name, clonePayload, parameters);
        if (!result) {
          return EventAction.createFailureOutcome(`Unable to find target: ${parameters.target}`);
        }

        // action waits for promises to resolve
        const promise = Promise.resolve(result);
        return promise
          .then((resolved) => {
            // after current container event listeners -> chains are called, rather than immediately resolve with
            // the result (resolved) perform the propagation behavior before returning resolved. This is a
            // synchronous call when successful
            this.performPropagationBehavior(name, clonePayload, eventModel);
            return EventAction.createSuccessOutcome(resolved);
          });
      }

      return EventAction
        .createFailureOutcome(`FireCustomEventAction ${this.logLabel}: unable to fire event ${name}, no page context`);
    }

    /**
     * @param name {string} the event name; must be declared in "events", and accessible to this container
     * @param payload {object}
     * @param eventModel {EventModel} the one for 'behavior:dynamicComponent'
     */
    performDynamicComponentBehavior(name, payload, eventModel) {
      if (!name || !EventAction.isValid(name)) {
        return EventAction.createFailureOutcome(`Unable to fire invalid event: ${name}`);
      }

      if (!this.eventContainer || !this.eventContainer.allowsDynamicComponentEventBehavior(eventModel.behavior)) {
        const msg = `Unable to fire event: ${name}. Events with 'dynamicComponent' `
          + 'behavior must be fired from a container that supports the behavior, such as Layout';
        logger.error(msg);

        return EventAction.createFailureOutcome(msg);
      }

      // warn users to switch to the 'propagationBehavior:self/container' behavior
      logger.warn('The dynamicComponent behavior is being deprecated in favor of propagationBehavior:container.',
        `Please update event '${name}' to use the propagationBehavior:container behavior.`);

      // event name must be lowercase or camelCase
      // DT has an audit for this in place
      if (!CAMELCASE_REG.test(name)) {
        logger.info('About to fire an event', name, 'that might be invalid. The name must be all lowercase or',
          'camelCased!');
      }

      // there is no good reason this should ever happen
      if (!this.context) {
        return EventAction
          .createFailureOutcome(`vb/fireCustomEventAction does not have a valid context for event: ${name}.`);
      }

      if (!eventModel || !eventModel.isDeclared) {
        return EventAction.createFailureOutcome(`Unable to find event declaration for: ${name}`);
      }

      const coercedPayload = eventModel.payloadType
        ? AssignmentHelper.coerceType(payload, eventModel.payloadType) : payload;

      return this.raiseEvent(name, coercedPayload);
    }

    /**
     * Performs a custom event propagation behavior based on the setting for 'propagationBehavior' property on
     * eventModel
     * @param name {string} the event name; must be declared in "events", and accessible to this container
     * @param payload {object}
     * @param eventModel {EventModel} the one for 'propagationBehavior:self/container'
     */
    performPropagationBehavior(name, payload, eventModel) {
      if (!name || !EventAction.isValid(name)) {
        return EventAction.createFailureOutcome(`Unable to fire invalid event: ${name}`);
      }

      // 'container' propagation event behavior assumes the consumer of the event is a cca. so event is dispatched via
      // special dispatchEvent
      if (this.eventContainer
        && this.eventContainer.allowsEventPropagation(Constants.EventPropagationBehaviors.CONTAINER, eventModel)) {
        // there is no good reason this should ever happen
        if (!this.context) {
          return EventAction
            .createFailureOutcome(`vb/fireCustomEventAction does not have a valid context for event: ${name}.`);
        }

        // must be declared but need not be part of an interface; interface events are only needed for extensions
        if (!eventModel || !eventModel.isDeclared) {
          return EventAction.createFailureOutcome(`Unable to find event declaration for: ${name}`);
        }

        // event name for event propagated to consuming container must be lowercase or camelCase; as cca receives it. DT
        // must audit this so info
        if (!CAMELCASE_REG.test(name)) {
          logger.info('About to fire an event', name, 'that might be invalid. The name must be all lowercase or',
            'camelCased!');
        }

        const coercedPayload = eventModel.payloadType
          ? AssignmentHelper.coerceType(payload, eventModel.payloadType) : payload;

        // at this point the event model has been found and validated,
        // and if the event name is not using camelCase or lowercase a warning has been logged
        // so it's safe to use a short event name, for cases when an event is referenced like
        // fragment:name, layout:name, etc.
        return this.raiseEvent(eventModel.name, coercedPayload, false);
      }

      return true;
    }

    /**
     * uses the JET-provided abstraction (defined to the VB layout model) to raise the event
     * @param name
     * @param payload
     * @param isDynamicComponent indicates whether its a dynamicComponent event or not
     * @returns {{result: *, name: string}}
     */
    raiseEvent(name, payload, isDynamicComponent = true) {
      const dispatch = this.getDispatcher();

      if (!dispatch) {
        const msg = `Unable to fire event '${name}', as no component event dispatcher provided.`;
        return EventAction.createFailureOutcome(msg);
      }

      try {
        // the JET-provided function will raise the event on the dynamic UI component

        const isIE = console.table === undefined;
        let event;
        if (!isIE) {
          event = new Event(name, { bubbles: false });
        } else {
          event = document.createEvent('Event');
          event.initEvent(name, false, true);
        }

        event.detail = payload;

        dispatch(event);
        const cont = isDynamicComponent ? 'Dynamic' : '';
        logger.info('Dispatched', cont, 'Container event: ', name, 'result');
      } catch (e) {
        return EventAction.createFailureOutcome(`Exception firing DOM event: ${name}`, e);
      }

      return EventAction.createSuccessOutcome();
    }

    /**
     * return the JET-provided function
     * @returns {*|mockScopes.$bindingContext|{$data}|{$dispatchEvent}}
     */
    getDispatcher() {
      // get the JET interface / abstraction for raising the event on the dynamic container
      const $bindingContext = this.internalContext && this.internalContext[Constants.ContextName.BINDING_CONTEXT];

      // @todo: the name & shape of this property will be defined by JET
      let dispatcher = $bindingContext && $bindingContext.$data
        && $bindingContext.$data[Constants.ContextNameInternal.DISPATCH_EVENT];

      // when this action is called from a nested chain (callChainAction containing a fireCustomEventAction) the
      // internalContext may not be transmitted down. See actionChain.EXCLUDED_CHAIN_SCOPE_VARS and
      // setInternalContext. In such cases attempt to retrieve the dispatcher from the event container, such as a
      // fragment
      if (!dispatcher && this.eventContainer) {
        dispatcher = typeof this.eventContainer.getEventDispatcher === 'function'
          && this.eventContainer.getEventDispatcher();
      }

      return dispatcher;
    }

    /**
     *
     * @param context
     * @param internalContext
     */
    setInternalContext(context, internalContext) {
      super.setContext(context);
      this.internalContext = internalContext;
    }
  }

  return FireCustomEventAction;
});

