'use strict';

define('vb/private/vx/v2/extension',[
  'vb/private/vx/baseExtension',
  'vb/private/configLoader',
  'vb/private/constants',
  'vb/private/utils',
], (BaseExtension, ConfigLoader, Constants, Utils) => {
  /**
   * a regex to find string starting with ui/base or dynamicLayouts/base or translations/base
   * @type {RegExp}
   */
  const baseRegex = new RegExp(`^${Constants.DefaultPaths.UI}base/|^${Constants.DefaultPaths.LAYOUTS}base/|^${Constants.DefaultPaths.TRANSLATIONS}base/`);

  class Extension extends BaseExtension {
    constructor(def, appUiInfo, bundleIds = [], bundledResources = [], componentsRequirejs = {}, registry) {
      super(def, def.baseUrl, registry);

      this.appUiInfo = {};
      const appUis = (appUiInfo && appUiInfo.applications) || [];
      appUis.forEach((appUi) => {
        this.appUiInfo[appUi.id] = appUi;
      });

      /**
       * This is where is stored the components info from the digest.
       * It will not be used until the extension is initialized
       * @type {Object}
       */
      this.componentsRequirejs = componentsRequirejs;

      // When the bundle is declared in the digest, we only need to register
      // the requirejs mapping for it
      if (bundleIds.length > 0) {
        const mapping = {};
        this.log.info('Extension', this.id, 'version:', this.version, 'is using', bundleIds.length, 'bundle');
        bundleIds.forEach((bundleId) => {
          const baseUrl = Utils.removeTrailingSlash(this.baseUrl);
          const url = bundleId.replace(baseUrl, this.baseUrlDef);
          mapping[bundleId] = url;
        });
        this.registry.addRequireMapping(mapping);
        // Remember that this extension is using requirejs bundles so that forcing
        // loading the bundle during init() is not necessary
        this.useBundles = true;
      } else {
        this.useBundles = false;
      }

      // For cleaned bundle, merge the bundledResources array with the files array
      if (bundledResources.length > 0) {
        if (this.files.length > 0) {
          // Use a Set to remove potential duplicate
          this.files = [...new Set(this.files.concat(bundledResources))];
        } else {
          this.files = bundledResources;
        }
      }

      this.dependencies = def.dependencies || {};
    }

    getAbsoluteUrl() {
      // in v2, 'ui/' is in the basePath
      // <baseUrl>/ui/self
      return `${this.baseUrlDef}/${Constants.DefaultPaths.UI}${Constants.ExtensionFolders.SELF}/`;
    }

    /**
     * Given a set, recursively append to this set all the extensions that
     * either depends or are dependent on this extension.
     *
     * @param {Set}  allDependencies  All dependencies
     */
    getAllDependencies(allDependencies) {
      if (allDependencies.has(this)) {
        return;
      }

      allDependencies.add(this);

      const dependencies = [
        // Recurse into extensions that extends this extension
        ...this.getDependentExtensions(),
        // Recurse into extension that it depends on
        ...this.getRequiredExtensions(),
      ];

      dependencies.forEach((ext) => {
        ext.getAllDependencies(allDependencies);
      });
    }

    /**
     * Initialize this extension and all its dependent extensions
     *
     * @return {Promise} a promise that resolve when all related exetension are initialized
     */
    init() {
      const allDependencies = new Set();
      this.getAllDependencies(allDependencies);

      const exts = [...allDependencies];

      return Promise.all(exts.map((ext) => ext.internalInit()));
    }

    /**
     * Perform the internal tasks needed to initialize this extension
     *
     * @return {Promise} a promise that resolve when all tasks are performed
     */
    internalInit() {
      if (!this._initPromise) {
        this._initPromise = Promise.all([this._initBundles(), this._initRequireConfig()])
          .then(() => {
            // At this point the object cannot be mutated
            Object.freeze(this);
          });
      }

      return this._initPromise;
    }

    /**
     * Initialize the extension bundle given the bundle information in the manifest coming from
     * the requirejs-info digest and recurse in dependent extensions.
     * If no bundle information is given, call super to use the v1 method of forcing the bundle loading.
     *
     * @return {Promise} a promise that resolve when the bundle and its dependent is initalized
     */
    _initBundles() {
      // If no bundle info is given, force the loading using the v1 mechanism defined in super
      if (!this.useBundles) {
        return super._initBundles();
      }

      return Promise.resolve();
    }

    /**
     * Initializes the require configuration from the requirejs-info digest.
     */
    _initRequireConfig() {
      ConfigLoader.addRequirejsPaths(this.componentsRequirejs, this.baseUrl);
      // Since the registration is done, remove the declaration from the componentsRequirejs
      this.componentsRequirejs = {};
    }

    /**
     * Retrieve all the extensions that this extension depends on.
     * @return {Array<Extension>}
     */
    getRequiredExtensions() {
      const result = [];
      Object.keys(this.dependencies).forEach((extId) => {
        const ext = this.registry.extensions[extId];
        if (ext) {
          result.push(ext);
        } else {
          this.log.warn('Extension', this.id, 'depends on extension', extId, 'that does not exist.');
        }
      });

      return result;
    }

    /**
     * Return all the extensions extending this extension
     * @return {Array<Extension>}
     * @private
     */
    getDependentExtensions() {
      const result = [];
      // eslint-disable-next-line no-unused-vars
      Object.entries(this.registry.extensions).forEach(([extId, ext]) => {
        const version = ext.dependencies[this.id];
        // Also need to check version
        if (version) {
          result.push(ext);
        }
      });

      return result;
    }

    /**
     * return true when at least one of the file extends a base object
     * @return {Boolean}
     */
    extendsBaseArtifact() {
      return this.files.some((file) => baseRegex.test(file));
    }

    /**
     * Given an App UI id, return the info for it
     * @param  {String} id an App UI id
     * @return {Object} the appUiInfo
     */
    getAppUiInfo(id) {
      return this.appUiInfo[id] || { id };
    }
  }

  return Extension;
});

